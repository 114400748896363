export class MailTemplate {

    public key: String = "";
    public template: String = "";
    public variables: String = "";

    public static create = (key: String , template: String, variables: String) => {
        let model = new MailTemplate();
        model.key = key;
        model.template = template;
        model.variables = variables;
        return model;
    }
}