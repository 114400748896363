<template>
  <!-- div class="grid">
    <div class="col-1">
      <div class="label-number">&nbsp;</div>
    </div>
    <div class="col-11">
      <div class="grid">
        <div class="col-12">
      <div class="paragraph title-margin" v-html="$tc('questionnaireForm.titleTechnicalITInfrastructure')"/>
        </div>
      </div>
    </div>
  </div -->
  <div class="m-0 h-full" style="justify-content: space-between">
    <div class="flex w-full h-full" style="position: relative">

      <div class="questionCount">
        <span>Question</span>
        <div style='font-weight: 700;'>
          <span id='currentQuestion'>{{ activeQuestion - 63 }}</span>
          <span style='font-weight: 400;'>/</span>
          <span id='allQuestions'>20</span>
        </div>
      </div>

      <Stepper linear unstyled: true v-model:active-step="activeSub">

        <StepperPanel :header="$tc('questionnaireForm.titleConnectivity')">
          <QuestionRating v-if="activeQuestion == 64" question="64" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 65" question="65" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 66" question="66" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 67" question="67" :change="autoSave"></QuestionRating>
        </StepperPanel>

        <StepperPanel :header="$tc('questionnaireForm.titleCompatibility')">
          <QuestionRating v-if="activeQuestion == 68" question="68" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 69" question="69" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 70" question="70" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 71" question="71" :change="autoSave"></QuestionRating>
        </StepperPanel>

        <StepperPanel :header="$tc('questionnaireForm.titleModularity')">
          <QuestionRating v-if="activeQuestion == 72" question="72" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 73" question="73" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 74" question="74" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 75" question="75" :change="autoSave"></QuestionRating>
        </StepperPanel>

        <StepperPanel :header="$tc('questionnaireForm.titleReusability')">
          <QuestionRating v-if="activeQuestion == 76" question="76" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 77" question="77" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 78" question="78" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 79" question="79" :change="autoSave"></QuestionRating>
        </StepperPanel>

        <StepperPanel :header="$tc('questionnaireForm.titleDataSharing')">
          <QuestionRating v-if="activeQuestion == 80" question="80" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 81" question="81" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 82" question="82" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 83" question="83" :change="autoSave"></QuestionRating>
        </StepperPanel>

      </Stepper>

    </div>
  </div>

</template>


<script lang="ts">
// @ is an alias to /src

import { Options, Vue } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import axios from 'axios';
import { User } from "@/data/User";
import { Application } from "@/data/Application";
import { AuthService } from "@/service/AuthService";
import { ref } from "vue";

import { defineComponent, PropType } from 'vue';
import QuestionRating from "@/components/questionnaire/QuestionRating.vue";

export default defineComponent({
  components: { QuestionRating },
  computed: {
    User() {
      return User
    },
    Application() {
      return Application
    }
  },
  props: {
    activeQuestion: {
      type: Number,
      required: true
    },
    activeSub: {
      type: Number,
      required: true
    }
  },
  methods: {
    autoSave: () => {
      User.get().invitation.response.save();
    }
  },
  setup() {
  },
})
</script>
<style>
.title-margin {
  margin-left: -30px;
}

.p-stepper .p-stepper-header.p-highlight .p-stepper-number {
  background: #0d3d52;
  color: #ffffff;
}

.stepper-offset-horizontal {
  margin-left: 78px;
}

.p-stepper {
  width: 100%;
}

.p-stepper-content {
  margin-left: -75px;
}

.p-slider-range {
  background-color: #5EBCB8;
}

.p-slider .p-slider-handle {
  background-color: #0d3d52;
  border-style: none;
}
</style>
