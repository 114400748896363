<template>
  <div style="font-family: 'Public Sans'; text-align: left; margin-bottom: 32px">
    Demographics information is taken from your profile, together with the information provided by your company.
  </div>

  <div style="">

    <div class="grid" v-if="activeQuestion < 10">
      <div class="col-6" style="text-align: left; font-weight: bold;" v-html="$tc('questionnaireForm.q1')"></div>
      <div class="col-6" style="text-align: left; font-weight: bold;" v-html="$tc('questionnaireForm.q7')"></div>
    </div>

    <div class="grid" v-if="activeQuestion < 10">
      <div class="col-6" style="text-align: left;">
        <InputText type="text" @change="autoSave()" v-model="User.get().invitation.response.q1JobTitle"
          class="demographics-field" />
      </div>
      <div class="col-6" style="text-align: left;">
        <Dropdown v-model="q7NumberOfEmployees" :options="Application.instance.nummberOfEmployeesItems"
          @change="autoSave(); event => { this.$forceUpdate() }" option-value="id" optionLabel="name"
          placeholder="Select..." class="md:w-14rem level-ddl demographics-field" />
      </div>
    </div>

    <div class="grid" v-if="activeQuestion < 10">
      <div class="col-6" style="text-align: left; font-weight: bold;" v-html="$tc('questionnaireForm.q2')"></div>
      <div class="col-6" style="text-align: left; font-weight: bold;" v-html="$tc('questionnaireForm.q8')"></div>
    </div>

    <div class="grid" v-if="activeQuestion < 10">
      <div class="col-6" style="text-align: left;">
        <InputText type="text" @change="autoSave()" v-model="User.get().invitation.response.q2Company"
          class="demographics-field" />
      </div>
      <div class="col-6" style="text-align: left;">
        <Dropdown v-model="q8GrossRevenue" :options="Application.instance.grossRevenueItems"
          @change="autoSave(); event => { this.$forceUpdate() }" option-value="id" optionLabel="name"
          placeholder="Select..." class="md:w-14rem level-ddl demographics-field" />
      </div>
    </div>

    <div class="grid" v-if="activeQuestion < 10">
      <div class="col-6" style="text-align: left; font-weight: bold;" v-html="$tc('questionnaireForm.q3')"></div>
      <div class="col-6" style="text-align: left; font-weight: bold;" v-html="$tc('questionnaireForm.q9')"></div>
    </div>

    <div class="grid" v-if="activeQuestion < 10">
      <div class="col-6" style="text-align: left;">
        <Dropdown v-model="q3Level" :options="Application.instance.levelItems"
          @change="autoSave(); event => { this.$forceUpdate() }" option-value="id" optionLabel="name"
          placeholder="Select..." class="md:w-14rem level-ddl demographics-field" />
      </div>
      <div class="col-6" style="text-align: left;">
        <Dropdown v-model="q9ExecutiveReport" :options="Application.instance.grossExecutiveReportItems"
          @change="autoSave(); event => { this.$forceUpdate() }" option-value="id" optionLabel="name"
          placeholder="Select..." class="md:w-14rem level-ddl demographics-field" />
      </div>
    </div>

    <div class="grid" v-if="activeQuestion < 10">
      <div class="col-6" style="text-align: left; font-weight: bold;" v-html="$tc('questionnaireForm.q4')">
      </div>
      <div class="col-6" style="text-align: left; font-weight: bold;" v-html="$tc('questionnaireForm.q5')">
      </div>
    </div>

    <div class="grid" v-if="activeQuestion < 10">
      <div class="col-6" style="text-align: left;">
        <InputText type="text" @change="autoSave()" v-model="User.get().invitation.response.q4YearsExperienceJob"
          class="demographics-field" />
      </div>
      <div class="col-6" style="text-align: left;">
        <InputText type="text" @change="autoSave()" v-model="User.get().invitation.response.q5YearsInCompany"
          class="demographics-field" />
      </div>
    </div>

    <div class="grid" v-if="activeQuestion < 10">
      <div class="col-6" style="text-align: left; font-weight: bold;" v-html="$tc('questionnaireForm.q6')"></div>
    </div>

    <div class="grid" v-if="activeQuestion < 10">
      <div class="col-6" style="text-align: left;">
        <InputText type="text" @change="autoSave()" v-model="User.get().invitation.response.q6YearsExperienceField"
          class="demographics-field" />
      </div>
    </div>

  </div>


</template>

<script lang="ts">
// @ is an alias to /src

import { Options, Vue } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import axios from 'axios';
import { User } from "@/data/User";
import { Application } from "@/data/Application";
import { AuthService } from "@/service/AuthService";
import { ref } from "vue";

import { defineComponent, PropType } from 'vue';
import { createI18n } from "vue-i18n";

export default defineComponent({
  computed: {
    User() {
      return User
    },
    Application() {
      return Application
    }
  },
  props: {
    activeQuestion: {
      type: Number,
      required: true
    }
  },
  methods: {
    autoSave() {
      User.get().invitation.response.q3Level = this.q3Level;
      User.get().invitation.response.q7NumberOfEmployees = this.q7NumberOfEmployees;
      User.get().invitation.response.q8GrossRevenue = this.q8GrossRevenue;
      User.get().invitation.response.q9ExecutiveReport = this.q9ExecutiveReport;
      User.get().invitation.response.save();
    }
  },
  setup(props) {
    let q3Level = ref(User.get().invitation.response.q3Level);
    let q7NumberOfEmployees = ref(User.get().invitation.response.q7NumberOfEmployees);
    let q8GrossRevenue = ref(User.get().invitation.response.q8GrossRevenue);
    let q9ExecutiveReport = ref(User.get().invitation.response.q9ExecutiveReport);
    return {
      q3Level, q7NumberOfEmployees, q8GrossRevenue, q9ExecutiveReport
    }
  },
});
</script>
<style>
.level-ddl {
  width: 100%;
  height: 20px;
  line-height: 20px;
}

.demographics-field {
  width: 95% !important;
  height: 40px;
}

.label-number-1 {
  font-family: "Public Sans";
  height: 20px;
  line-height: 20px;
  text-align: right;
}

.question-field-1 {
  font-family: "Public Sans";
  text-align: left;
  height: 20px;
  line-height: 20px;
}

.number-field {
  font-family: "Public Sans";
  width: 100px;
}

.paragraph {
  font-family: "Public Sans";
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  margin-left: -30px;
  height: 50px;
}
</style>
