import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "content",
  style: {"display":"flex"}
}
const _hoisted_2 = {
  class: "flex",
  style: {"display":"flex","flex-direction":"column","gap":"8vh","width":"100%"}
}
const _hoisted_3 = { class: "welcome-title-consultant-dashboard" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  class: "flex",
  style: {"gap":"36px","width":"100%","margin-top":"-30px"}
}
const _hoisted_7 = {
  class: "flex bg-white",
  style: {"flex-direction":"column","padding":"16px","border-radius":"16px","width":"100%","gap":"16px"}
}
const _hoisted_8 = { style: {"height":"50px","background-color":"#F3F4F8","border-radius":"10px"} }
const _hoisted_9 = { style: {"width":"100%","height":"100%"} }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { style: {"position":"relative","width":"100%","height":"70px","background-color":"#FFFFFF","border-bottom-left-radius":"10px","border-bottom-right-radius":"10px","display":"flex","align-items":"center"} }
const _hoisted_14 = { style: {"float":"left","width":"40%","font-family":"'Public Sans'","font-size":"16px","line-height":"50px","text-align":"left","padding-left":"20px","display":"flex","align-items":"center"} }
const _hoisted_15 = { style: {"float":"left","width":"30%","font-family":"'Public Sans'","font-size":"16px","line-height":"70px","text-align":"left"} }
const _hoisted_16 = { style: {"float":"left","width":"30%","font-family":"'Public Sans'","font-size":"16px","line-height":"70px","text-align":"left"} }
const _hoisted_17 = { style: {"background-color":"#FFFFFF","border-radius":"16px","padding":"40px","width":"fit-content","height":"fit-content"} }
const _hoisted_18 = {
  key: 2,
  class: "content"
}
const _hoisted_19 = { class: "welcome-title-consultant" }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = { class: "back-to-all-clients" }
const _hoisted_22 = { class: "client-details-block-details" }
const _hoisted_23 = { style: {"height":"100%","width":"100%"} }
const _hoisted_24 = { class: "fields-block" }
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = { class: "input-client-name" }
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = { class: "input-business-activity" }
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = { class: "input-number-of-employees" }
const _hoisted_31 = { class: "project-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IciButton = _resolveComponent("IciButton")!
  const _component_IciStatus = _resolveComponent("IciStatus")!
  const _component_ClientDetails = _resolveComponent("ClientDetails")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.selectedClient == null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", {
                innerHTML: _ctx.$t('homeForm.welcome'),
                style: {"padding-right":"12px"}
              }, null, 8, _hoisted_4),
              _createElementVNode("span", {
                innerHTML: _ctx.User.get().profile.firstName
              }, null, 8, _hoisted_5)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", {
                      style: {"float":"left","width":"40%","font-family":"'Public Sans'","font-weight":"bold","font-size":"16px","line-height":"50px","text-align":"left","padding-left":"30px"},
                      innerHTML: _ctx.$t('companyForm.tableHeaderClientName')
                    }, null, 8, _hoisted_10),
                    _createElementVNode("div", {
                      style: {"float":"left","width":"30%","font-family":"'Public Sans'","font-weight":"bold","font-size":"16px","line-height":"50px","text-align":"left"},
                      innerHTML: _ctx.$t('companyForm.tableHeaderQuestionnaireDeadline')
                    }, null, 8, _hoisted_11),
                    _createElementVNode("div", {
                      style: {"float":"left","width":"30%","font-family":"'Public Sans'","font-weight":"bold","font-size":"16px","line-height":"50px","text-align":"left"},
                      innerHTML: _ctx.$t('companyForm.tableHeaderProjectStatus')
                    }, null, 8, _hoisted_12)
                  ])
                ]),
                _createElementVNode("div", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.User.get().consultantCompanies, (client) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_IciButton, {
                          id: 'buttonClick@' + client.id,
                          content: client.name,
                          onButtonClick: ($event: any) => (_ctx.clientDetails(client.id)),
                          btnStyle: "width: 200px; text-align: center;"
                        }, null, 8, ["id", "content", "onButtonClick"])
                      ]),
                      _createElementVNode("div", _hoisted_15, _toDisplayString(client.deadlineFormatted), 1),
                      _createElementVNode("div", _hoisted_16, [
                        _createVNode(_component_IciStatus, {
                          state: client.status
                        }, null, 8, ["state"])
                      ])
                    ]))
                  }), 256))
                ])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_IciButton, {
                  id: "newClient",
                  content: _ctx.constructLabel(_ctx.buttonAddNewClient, _ctx.$t('companyForm.buttonNewClient')),
                  onButtonClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.buttonClick('newClient'))),
                  btnStyle: "padding: 16px 24px!important; width: fit-content; height: auto;"
                }, null, 8, ["content"])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.selectedClient != null && _ctx.selectedQuestionnaire == null)
      ? (_openBlock(), _createBlock(_component_ClientDetails, {
          key: 1,
          "selected-client": _ctx.selectedClient,
          click: _ctx.buttonClick
        }, null, 8, ["selected-client", "click"]))
      : _createCommentVNode("", true),
    (_ctx.selectedQuestionnaire != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("span", {
              innerHTML: _ctx.selectedClient.name
            }, null, 8, _hoisted_20)
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_IciButton, {
              id: "backToAllClients",
              content: _ctx.constructLabel(_ctx.buttonBackToAllClients, _ctx.$t('companyForm.buttonBackToAllClients')),
              click: _ctx.buttonClick,
              btnStyle: "width: 240px;"
            }, null, 8, ["content", "click"])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", {
                  class: "label-client-name",
                  innerHTML: _ctx.$tc('companyForm.fieldLabelYear')
                }, null, 8, _hoisted_25),
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(_component_InputText, {
                    type: "text",
                    modelValue: _ctx.selectedQuestionnaire.year,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedQuestionnaire.year) = $event)),
                    class: "field-questionnaire-year"
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", {
                  class: "label-business-activity",
                  innerHTML: _ctx.$tc('companyForm.fieldLabelDeadline')
                }, null, 8, _hoisted_27),
                _createElementVNode("div", _hoisted_28, [
                  _createVNode(_component_InputText, {
                    type: "text",
                    modelValue: _ctx.selectedQuestionnaire.deadlineFormatted,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedQuestionnaire.deadlineFormatted) = $event)),
                    class: "field-questionnaire-deadline"
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", {
                  class: "label-number-of-employees",
                  innerHTML: _ctx.$tc('companyForm.fieldLabelStatus')
                }, null, 8, _hoisted_29),
                _createElementVNode("div", _hoisted_30, [
                  _createVNode(_component_Dropdown, {
                    modelValue: _ctx.selectedQuestionnaire.active,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedQuestionnaire.active) = $event)),
                    options: _ctx.statusOptions,
                    onChange: _cache[4] || (_cache[4] = event => { this.$forceUpdate() }),
                    "option-value": "id",
                    optionLabel: "name",
                    placeholder: _ctx.$t('ddlSelectPlaceHolder'),
                    class: "md:w-14rem level-ddl field-number-of-employees"
                  }, null, 8, ["modelValue", "options", "placeholder"])
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _createVNode(_component_DataTable, { tableStyle: "min-width: 50rem" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        field: "email",
                        header: _ctx.$t('companyForm.tableHeaderEmail')
                      }, null, 8, ["header"]),
                      _createVNode(_component_Column, {
                        field: "status",
                        header: _ctx.$t('companyForm.tableHeaderStatus')
                      }, null, 8, ["header"]),
                      _createVNode(_component_Column, {
                        field: "progress",
                        header: _ctx.$t('companyForm.tableHeaderProgress')
                      }, null, 8, ["header"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}