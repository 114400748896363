import axios from "axios";
import {Application} from "@/data/Application";

export class Profile {
    public iciId: number|null = null;
    public jobTitle: string|null = null;
    public level: number|null = null;
    public company: number|null = null;
    public userId: number|null = null;
    public firstName: string|null = null;
    public lastName: string|null = null;
    public phone: string|null = null;
    public defaultRole: number|null = null;
    public language: string = "en";

    public submit = () => {
        axios.post(Application.instance.apiEndPoint + '/profile/', this)
            .then(response => {

            })
            .catch(err => {
                //console.log(err);
            });
    }
}
