<template>
  <div class="panel" style="gap: 8px">
    <div class="label">Mail ID</div>
    <div class="field">
      <InputText type="text" v-model="mailId" class="input"/>
    </div>
    <div class="label">Client ID</div>
    <div class="field">
      <InputText type="text" v-model="clientId" class="input"/>
    </div>
    <div class="label">Client Secret</div>
    <div class="field">
      <InputText type="text" v-model="clientSecret" class="input"/>
    </div>
    <div class="label">Tenant ID</div>
    <div class="field">
      <InputText type="text" v-model="tenantId" class="input"/>
    </div>
    <div class="label">Scope</div>
    <div class="field">
      <InputText type="text" v-model="scope" class="input"/>
    </div>
    <div class="label">Log Level</div>
    <div class="field">
      <InputText type="text" v-model="logLevel" class="input"/>
    </div>
    <div class="label">API Base-URL</div>
    <div class="field">
      <InputText type="text" v-model="apiBaseUrl" class="input"/>
    </div>
    <div class="save-button">
      <IciButton id="submit"
                 content="Save"
                 @buttonClick="this.save"
                 btnStyle="width: 140px;"
                 :dark="true"></IciButton>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import IciButton from "@/components/IciButton.vue";
import axios from "axios";
import {Application} from "@/data/Application";
import {MailConfig} from "@/data/MailConfig";
@Options({
  components: {IciButton}
})
export default class AdminMailSettingsView extends Vue {

  private mailId: String = "";
  private clientId: String = "";
  private clientSecret: String = "";
  private tenantId:String = "";
  private scope:String = "";
  private logLevel:String = "";
  private apiBaseUrl:String = "";

  mounted() {
    axios.get(Application.instance.apiEndPoint + '/admin/mail/config')
        .then(response => {
            console.log(response.data);

          this.clientId =  response.data.clientId;
          this.clientSecret = response.data.clientSecret;
          this.mailId = response.data.mailId;
          this.scope = response.data.scope;
          this.tenantId = response.data.tenantId;
          this.logLevel = response.data.logLevel;
          this.apiBaseUrl = response.data.apiBaseUrl;
        })
        .catch(err => {
          console.log(err);
        });
  }

  private save() {
    let mailConfig = new MailConfig();
    mailConfig.clientId = this.clientId;
    mailConfig.clientSecret = this.clientSecret;
    mailConfig.mailId = this.mailId;
    mailConfig.scope = this.scope;
    mailConfig.tenantId = this.tenantId;
    mailConfig.logLevel= this.logLevel;
    mailConfig.apiBaseUrl = this.apiBaseUrl;

    axios.post(Application.instance.apiEndPoint + '/admin/mail/config', mailConfig)
        .then(response => {
          console.log(response.data);

          this.clientId =  response.data.clientId;
          this.clientSecret = response.data.clientSecret;
          this.mailId = response.data.mailId;
          this.scope = response.data.scope;
          this.tenantId = response.data.tenantId;
          this.logLevel = response.data.logLevel;
          this.apiBaseUrl = response.data.apiBaseUrl;
        })
        .catch(err => {
          console.log(err);
        });
  }
}
</script>
<style scoped>
  .panel {
    text-align: left;
    padding-left: 70px;
  }
  .label {
    font-family: "Public Sans";
    font-weight: bold;
  }
  .field {
    font-family: "Public Sans";
    font-weight: normal;
  }
  .input {
    font-family: "Public Sans";
    width: 750px;
  }
  .save-button  {
    padding-left: 610px;
  }
</style>
