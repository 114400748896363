<template>
  <!-- div class="grid">
    <div class="col-1">
      <div class="label-number">&nbsp;</div>
    </div>
    <div class="col-11">
      <div class="grid">
        <div class="col-12">
          <div class="paragraph title-margin" v-html="$tc('questionnaireForm.titleNetworkCharacteristics')"/>
        </div>
      </div>
    </div>
  </div -->

  <div class="m-0 h-full" style="justify-content: space-between">
    <div class="flex w-full h-full" style="position: relative">

      <div class="questionCount">
        <span>Question</span>
        <div style='font-weight: 700;'>
          <span id='currentQuestion'>{{ activeQuestion - 21 }}</span>
          <span style='font-weight: 400;'>/</span>
          <span id='allQuestions'>18</span>
        </div>
      </div>

      <Stepper linear unstyled: true v-model:active-step="activeSub">

        <StepperPanel :header="$tc('questionnaireForm.titleRelationshipsBasedOnTrust')">
          <QuestionRating v-if="activeQuestion == 22" question="22" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 23" question="23" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 24" question="24" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 25" question="25" :change="autoSave"></QuestionRating>
        </StepperPanel>

        <StepperPanel :header="$tc('questionnaireForm.titlePerformanceMetricsmeasurementAndBenchmarking')">
          <QuestionRating v-if="activeQuestion == 26" question="26" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 27" question="27" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 28" question="28" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 29" question="29" :change="autoSave"></QuestionRating>
        </StepperPanel>

        <StepperPanel :header="$tc('questionnaireForm.titleProcessIntegrationAndCollaborativeWork')">
          <QuestionRating v-if="activeQuestion == 30" question="30" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 31" question="31" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 32" question="32" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 33" question="33" :change="autoSave"></QuestionRating>
        </StepperPanel>

        <StepperPanel :header="$tc('questionnaireForm.titleKnowledgeSharing')">
          <QuestionRating v-if="activeQuestion == 34" question="34" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 35" question="35" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 36" question="36" :change="autoSave"></QuestionRating>
        </StepperPanel>

        <StepperPanel :header="$tc('questionnaireForm.titleQuickConnectAndDisconnect')">
          <QuestionRating v-if="activeQuestion == 37" question="37" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 38" question="38" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 39" question="39" :change="autoSave"></QuestionRating>
        </StepperPanel>

      </Stepper>

    </div>
  </div>

</template>

<script lang="ts">
// @ is an alias to /src

import { Options, Vue } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import axios from 'axios';
import { User } from "@/data/User";
import { Application } from "@/data/Application";
import { AuthService } from "@/service/AuthService";
import { ref } from "vue";

import { defineComponent, PropType } from 'vue';
import QuestionRating from "@/components/questionnaire/QuestionRating.vue";

export default defineComponent({
  components: { QuestionRating },
  computed: {
    User() {
      return User
    },
    Application() {
      return Application
    }
  },
  props: {
    activeQuestion: {
      type: Number,
      required: true
    },
    activeSub: {
      type: Number,
      required: true
    }
  },
  methods: {
    autoSave: () => {
      User.get().invitation.response.save();
    }
  },
  setup() {
  },
})
</script>

<style>
.title-margin {
  margin-left: -30px;
}

.p-stepper .p-stepper-header.p-highlight .p-stepper-number {
  background: #0d3d52;
  color: #ffffff;
}

.stepper-offset-horizontal {
  margin-left: 78px;
}

.p-stepper-content {
  margin-left: -95px;
}

.p-stepper {
  width: 99% !important;
}

.p-slider-range {
  background-color: #5EBCB8;
}

.p-slider .p-slider-handle {
  background-color: #0d3d52;
  border-style: none;
}
</style>