<template>
  <div class="panel" style="gap: 8px">
    <div class="label">Consultant email</div>
    <div class="field">
      <InputText type="email" v-model="consultant.email" class="input"/>
    </div>
    <div class="label">Temp password</div>
    <div class="field">
      <InputText type="password" v-model="consultant.password" class="input"/>
    </div>
    <div class="label">Language</div>
    <div class="field">
      <InputText type="text" v-model="consultant.language" class="input"/>
    </div>
    <div class="save-button">
      <IciButton class="save-button" id="submit"
                 content="Save"
                 @buttonClick="save"
                 :disabled="!isFormValid"
                 btnStyle="width: 140px;"
                 :dark="true"></IciButton>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import IciButton from "@/components/IciButton.vue";
import axios from "axios";
import {Application} from "@/data/Application";

type Consultant = {
  email: string;
  password: string;
  questionnaire:number;
  language: string;
};

@Options({
  components: {IciButton}
})
export default class AdminAddConsultant extends Vue {
  private consultant: Consultant = {
    email: '',
    password: '',
    questionnaire: 0,
    language: ''
  };

  get isFormValid(): boolean {
    return this.consultant.email !== '' && this.consultant.password !== '' && this.consultant.language !== '';
  }

  private save() {
    axios.post(Application.instance.apiEndPoint + '/auth/user', this.consultant)
        .then(response => {
          console.log(response.data);
          this.resetForm();
        })
        .catch(err => {
          console.log(err);
        });
  }

  private resetForm() {
    this.consultant.email = '';
    this.consultant.password = '';
    this.consultant.language = '';
  }
}
</script>

<style scoped>
.panel {
  text-align: left;
  padding-left: 70px;
}
.label {
  font-family: "Public Sans";
  font-weight: bold;
}
.field {
  font-family: "Public Sans";
  font-weight: normal;
}
.input {
  font-family: "Public Sans";
  width: 750px;
}
.save-button {
  float: inline-end;
  padding-left: 610px;
}
</style>
