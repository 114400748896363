import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../assets/buttons/svg/graph.svg'
import _imports_1 from '../../assets/buttons/svg/graph-select.svg'
import _imports_2 from '../../assets/buttons/svg/graph-hover.svg'


const _withScopeId = n => (_pushScopeId("data-v-0ee6ec4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  height: "42",
  width: "42"
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  height: "42",
  width: "42"
}
const _hoisted_3 = {
  key: 2,
  src: _imports_2,
  height: "42",
  width: "42"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hover = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hover = false)),
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClick())),
    class: "button-default header-button"
  }, {
    default: _withCtx(() => [
      (!_ctx.hover && !_ctx.selected)
        ? (_openBlock(), _createElementBlock("img", _hoisted_1))
        : (!_ctx.hover && _ctx.selected)
          ? (_openBlock(), _createElementBlock("img", _hoisted_2))
          : (_openBlock(), _createElementBlock("img", _hoisted_3))
    ]),
    _: 1
  }))
}