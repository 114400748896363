<template>

  <div class="content">

    <div class="flex" style="justify-content: space-between; align-items: center;">

      <div class="welcome-title">
        <span v-html="$t('homeForm.welcome')" />&nbsp;
        <span v-html="User.get().profile.firstName" />
      </div>

      <div class="questionnaire-state-block">

        <div class="flex" style="flex-direction: column; justify-content: space-between; gap: 24px;">

          <div class="status-title progress" v-html="$t('homeForm.titleProgress')" />
          <div class="flex" style="flex-direction: column">
            <div
              v-if="User.get().invitation.active && User.get().invitation.state < QuestionnaireState.QUESTIONNAIRE_SUBMITTED"
              class="status-value progress" v-html="User.get().invitation.response.progressAsString()" />
            <div
              v-if="!User.get().invitation.active || User.get().invitation.state == QuestionnaireState.QUESTIONNAIRE_SUBMITTED"
              class="status-value progress" v-html="'100%'" />
          </div>

        </div>


        <div class="flex" style="flex-direction: column; justify-content: space-between; gap: 24px;">

          <div class="status-title deadline" v-html="$t('homeForm.titleDeadline')" />
          <div class="flex" style="flex-direction: column">
            <div
              v-if="User.get().invitation.active && User.get().invitation.state < QuestionnaireState.QUESTIONNAIRE_SUBMITTED"
              class="status-value deadline" v-html="User.get().invitation.response.deadline()" />
            <div
              v-if="!User.get().invitation.active || User.get().invitation.state == QuestionnaireState.QUESTIONNAIRE_SUBMITTED"
              class="status-value deadline" v-html="'-'" />
          </div>

        </div>


        <div class="flex" style="flex-direction: column; justify-content: space-between; gap: 24px;">

          <div class="status-title my-response" v-html="$t('homeForm.titleMyResponses')" />
          <div class="flex" style="flex-direction: column">
            <div
              v-if="User.get().invitation.active && User.get().invitation.state < QuestionnaireState.QUESTIONNAIRE_SUBMITTED"
              class="status-value my-response" v-html="User.get().invitation.response.state()" />
            <div
              v-if="!User.get().invitation.active || User.get().invitation.state == QuestionnaireState.QUESTIONNAIRE_SUBMITTED"
              class="status-value my-response" v-html="'Finished'" />
          </div>

        </div>


      </div>

    </div>

  </div>

  <div class="intro-block">

    <div class="app-title" v-html="$t('homeForm.appTitle')" />
    <div class="intro-block-content">

      <div
        v-if="!User.get().invitation.active || User.get().invitation.state == QuestionnaireState.QUESTIONNAIRE_SUBMITTED">
        <span v-html="$t('homeForm.contentNoOpenQuestionnaire')" />
      </div>

      <div
        v-if="User.get().invitation.active && User.get().invitation.state < QuestionnaireState.QUESTIONNAIRE_SUBMITTED"
        class="block-size">
        <span v-html="$t('homeForm.contentHeader')" />
        <span v-html="$t('homeForm.contentOpenQuestionnaire')" />
      </div>

    </div>

    <div v-if="User.get().invitation.active" class="button-block" style="display: flex; justify-content: flex-end;">
      <!-- StartQuestionnaireButton v-if="User.get().invitation.state < QuestionnaireState.REGISTERED" :click="startQuestionnaire"></StartQuestionnaireButton -->
      <!-- ReturnQuestionnaireButton  v-if="User.get().invitation.state == QuestionnaireState.QUESTIONNAIRE_STARTED" :click="proceedQuestionnaire"></ReturnQuestionnaireButton -->
      <IciButton v-if="User.get().invitation.state < QuestionnaireState.QUESTIONNAIRE_STARTED" id="start"
        :content="constructLabel(buttonStartContent, $t('homeForm.btnLabelStart'))" @buttonClick="startQuestionnaire()"
        btnStyle="width: fit-content; height: auto; padding: 18px 24px!important;"></IciButton>
      <IciButton v-if="User.get().invitation.state == QuestionnaireState.QUESTIONNAIRE_STARTED" id="continue"
        :content="constructLabel(buttonProceedContent, $t('homeForm.btnLabelProceed'))"
        @buttonClick="proceedQuestionnaire()" btnStyle="width: fit-content; height: auto; padding: 18px 24px!important;"
        :dark="false"></IciButton>
    </div>

  </div>

</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
// @ is an alias to /src
import router from "@/router";
import { Inject } from "vue-property-decorator";
import { User } from "@/data/User";
import { Application } from "@/data/Application";
import { QuestionnaireState } from "@/data/QuestionnaireState";
import { AuthService } from "../service/AuthService";
import { RoleType } from "@/data/RoleType";
import IciButton from "@/components/IciButton.vue";
import { useI18n } from "vue-i18n";
import { defineComponent } from "vue";

@Options({
  components: { IciButton, RespondentView },
  computed: {
    RoleType() {
      return RoleType
    },
    AuthService() {
      return AuthService
    },
    QuestionnaireState() {
      return QuestionnaireState
    },
    Application() {
      return Application
    },
    User() {
      return User
    }
  }
})

export default class RespondentView extends Vue {

  private buttonStartContent = "<label>$label</label><i class='pi pi-chevron-right' style='font-size: 1rem'></i>";
  private buttonProceedContent = "<label>$label</label><i class='pi pi-chevron-right' style='font-size: 1rem'></i>";

  mounted() {
    AuthService.validate();
    setTimeout(() => {
      this.$forceUpdate()
    }, 10);
  }

  private startQuestionnaire = () => {
    User.get().invitation.startQuestionnaire();
    router.push('/questionnaire')
  }
  private proceedQuestionnaire = () => {
    router.push('/questionnaire')
  }


  private constructLabel(content: String, label: string) {
    return content.replace("$label", label);
  }
}

</script>

<style>

.panel {
  position: static;
  display: flex;
  flex-direction: column;
  gap: 40px
}

.content {
  top: 0;
  left: 104px;
  bottom: 0;
  right: 0;
  font-family: "Public Sans";
}

.welcome-title {
  font-family: "Public Sans";
  font-size: 40px;
  font-weight: bold;
  text-align: left;
  padding-left: 80px;
}

.intro-block {
  position: static!important;
  border-radius: 10px!important;
  height: auto!important;
  border-left-style: solid;
  border-left-width: 10px!important;
  border-left-color: #0D3D52;
  box-shadow: 5px 10px 8px #EEEEEE;
  font-family: "Public Sans";
  background-color: #fff;
  padding: 40px 80px;
}

.intro-block-content {
  font-family: "Public Sans";
  font-size: 16px;
  font-weight: normal;
  text-align: left;
}

.app-title {
  font-family: "Public Sans";
  font-size: 32px;
  font-weight: bold;
  text-align: left;
  padding-top: 20px;
  padding-left: 50px;
}

.button-block {
  position: static!important;
  bottom: 20px;
  right: 50px;
}

.questionnaire-state-block {
  position: static!important;
  border-radius: 10px!important;
  box-shadow: 5px 10px 8px #EEEEEE;
  display: flex;
  padding: 40px 80px;
  justify-content: space-between;
  background-color: #fff;
}

.status-title {
  position: static!important;
  top: 30px;
  font-size: 16px;
  font-weight: bold;
}

.progress {
  font-family: "Public Sans";
  left: 100px;
}

.deadline {
  font-family: "Public Sans";
  left: 250px;
}

.my-response {
  font-family: "Public Sans";
  left: 360px;
}

.status-value {
  font-family: "Public Sans";
  position: static!important;
  top: 70px;
  font-size: 16px;
  font-weight: bold;
}
</style>