<template>
  <div class="sidebar" v-if="$route.path.includes('/client/') && $route.path !== '/client/0'">
    <div class="sidebar-content">
      <div  class="dashboard-container button-container">
        <DashboardButton @buttonClick="gotoClientDashboard" :selected="$route.path.includes(extractClientId() + '/dashboard')"></DashboardButton>
      </div>
      <div class="performance-container button-container" v-if="extractProjectId()">
        <PerformanceButton @buttonClick="gotoResults()" :selected="$route.path.includes('/results-dashboard')"></PerformanceButton>
      </div>
      <div class="settings-container button-container">
        <SettingsButton @buttonClick="gotoSetting()" :selected="$route.path == '/client/' + extractClientId()"></SettingsButton>
      </div>
    </div>
  </div>
  <div class="app-header" v-if="token != null">
    <img class="yb-logo" src="../assets/logo.png" alt="Logo">
    <div class="headerIcons">
      <HomeButton :click="onHome" :selected="$route.path === '/'"></HomeButton>
      <ProfileButton :click="onProfile" :selected="profileActive"></ProfileButton>
      <LogoutButton :click="onLogout"></LogoutButton>
    </div>
  </div>
  <div class="app-panel" v-if="token != null">
    <div>
    <router-view />
    </div>
  </div>
  <div v-else-if="token == null">
    <login-view></login-view>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {RouteLocationNormalized, useRoute} from 'vue-router';
import LoginView from "@/views/LoginView.vue";
import { User } from "@/data/User";
import { AuthService } from "@/service/AuthService";
import { Application } from "@/data/Application";
import LogoutButton from "@/components/button/LogoutButton.vue";
import ProfileButton from "@/components/button/ProfileButton.vue";
import router from "@/router";
import HomeButton from "@/components/button/HomeButton.vue";
import { Navigation } from "@/data/Navigation";
import { RoleType } from "@/data/RoleType";
import ProjectButton from "@/components/button/ProjectButton.vue";
import SettingsButton from "@/components/button/SettingsButton.vue";
import DashboardButton from "@/components/button/DashboardButton.vue";
import PerformanceButton from "@/components/button/PerformanceButton.vue";

@Options({
  methods:{
    extractClientId() {
      const regex = /\/client\/(\d+)(?:\/|$)/;
      const match = this.$route.fullPath.match(regex);
      return match ? match[1] : null;
    },
    extractProjectId() {
      const regex = /\/project\/(\d+)/;
      const match = this.$route.fullPath.match(regex);
      return match ? match[1] : null;
    },
    backToAllClients: () => {
      router.push("/");
    },
    gotoClientDashboard() {
    console.log('goToClientDashboard')
      const clientId = this.extractClientId();
    console.log('clientId', clientId)
      if (clientId) {
        router.push(`/client/${clientId}/dashboard`);
      }
    },
    gotoResults() {
      const clientId = this.extractClientId();
      const projectId = this.extractProjectId();
      if (clientId) {
        router.push(`/client/${clientId}/project/${projectId}/results-dashboard`);
      }
    },
    gotoSetting() {
      const clientId = this.extractClientId();
      if (clientId) {
        router.push(`/client/${clientId}`);
      }
    }
  },
  computed: {
    RoleType() {
      return RoleType
    },
    User() {
      return User
    },
    Application() {
      return Application
    },
    AuthService() {
      return AuthService
    }
  },
  components: {
    PerformanceButton,
    DashboardButton,
    ProjectButton,
    HomeButton,
    ProfileButton,
    LogoutButton,
    SettingsButton,
    LoginView
  },
})
export default class ApplicationView extends Vue {
  public static user = new User();
  private token: string | null = null;
  private profileActive: boolean = false;
  private homeActive: boolean = false;

  $route!: RouteLocationNormalized;

  mounted() {
    AuthService.create(this);
    AuthService.validate();
    this.updateButtonStates();
    this.$i18n.locale = User.get().profile.language;
    this.checkScreenWidth();
    window.addEventListener('resize', this.checkScreenWidth);
  }
  checkScreenWidth = () => {
    if (window.innerWidth < 1200) {
      router.push('/device-not-available');
    }
  }
  public update = () => {
    this.token = AuthService.getToken();
    this.profileActive = (Application.instance.activeView == Navigation.PROFILE_VIEW);
    this.homeActive = (Application.instance.activeView == Navigation.HOME_VIEW);

    if (AuthService.getToken() != null) {
      Application.instance.create(this);
      Application.instance.getRefData();
    }
  }

  public updateButtonStates = () => {
    if (this.$route) {
      this.homeActive = this.$route.path === '/';
      this.profileActive = this.$route.path === '/profile';
    }
  }

  public onLogout = () => {
    Application.instance.setActiveView(Navigation.EXIT)
    AuthService.logOut();
    router.push('/login')
  }

  public onProfile = () => {
    this.profileActive = true;
    this.homeActive = false;
    router.push('/profile')
  }

  public onHome = () => {
    this.profileActive = false;
    this.homeActive = true;
    router.push('/')
  }

  public onProject = () => {
    this.profileActive = false;
    this.homeActive = false;
    router.push('/results-dashboard')
  }
}
</script>

<style>
.app-panel {
  position: absolute;
  top: 120px;
  left: 104px;
  right: 0px;
  bottom: 0px;
}

.side-bar-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  padding-top: 0;
  top: 0;
  left: 0;
  bottom: 0;
  width: 104px;
}

.yb-logo {
  height: auto;
}

.headerIcons {
  display: flex;
  gap: 16px;
}
@media(max-width:1199.98px) {
.headerIcons{
display:none
}
}
.headerIcons img {
  width: 100%;
}

.app-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 200;
  height: 90px;
  background-color: #FFFFFF;
  box-shadow: 5px 10px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  padding: 24px 42px
}

.nav-btn {
  padding-top: 20px;
  cursor: pointer;
  aspect-ratio: 1 /1;
}

.button-default {
  width: 46px;
  height: 46px;
  padding: 0px !important;
  background-color: #ffffff !important;
  border-style: none;
}

.sidebar{
  position: fixed;
  top: 0;
  left: 0;
  width: 100px !important;
  height: 0 !important;
  transform: translateY(-50%);
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.05);
}
.button-container{
  margin-bottom:20px;
}
.sidebar-content{
  display:flex;height:100vh; justify-content:center;flex-direction:column;
  background: #FBFAFF;
  /* Soft */
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.05);
}
.sidebar .header-button{
  margin-left:25px;
}
.header-button {
  display: flex;
  justify-content: center;
  border-radius: 8px;
    background:transparent !important;
  padding: 10px !important;
  border: 1px solid #0D3D52 !important;
}
.header-button svg{
  max-width:16px !important;
}
.header-button:hover{
  background-color: #5EBCB8 !important;
  border: 1px solid #5EBCB8 !important;
}
.header-button.selected {
  background-color: #0D3D52 !important;
}
.p-checkbox-box{
  border: solid 1px #0D3D52;

}

.p-checkbox-box:checked{
  border: solid 1px #0D3D52;
  background-color: #0D3D52;
}


.p-checkbox-input:checked{
  background-color: #0D3D52;
}

.p-checkbox-icon{
   background-color: #0D3D52 !important;
 }
</style>