import axios from "axios";
import {User} from "@/data/User";
import router from "@/router";
import {Application} from "@/data/Application";

export class Response {
    public id: number|null = null;
    public userId: number|null = null;
    public companyActivity: string|null = null;
    public q1JobTitle: string |null = null;
    public q2Company: string |null =  null;
    public q3Level: number |null =  null;
    public q4YearsExperienceJob: number |null = null;
    public q5YearsInCompany: number |null = null;
    public q6YearsExperienceField: number |null = null;
    public q7NumberOfEmployees: number |null = null;
    public q8GrossRevenue: number |null = null;
    public q9ExecutiveReport: number |null = null;
    public q10: number|null = null;
    public q11: number|null = null;
    public q12: number|null = null;
    public q13: number|null = null;
    public q14: number|null = null;
    public q15: number|null = null;
    public q16: number|null = null;
    public q17: number|null = null;
    public q18: number|null = null;
    public q19: number|null = null;
    public q20: number|null = null;
    public q21: number|null = null;
    public q22: number|null = null;
    public q23: number|null = null;
    public q24: number|null = null;
    public q25: number|null = null;
    public q26: number|null = null;
    public q27: number|null = null;
    public q28: number|null = null;
    public q29: number|null = null;
    public q30: number|null = null;
    public q31: number|null = null;
    public q32: number|null = null;
    public q33: number|null = null;
    public q34: number|null = null;
    public q35: number|null = null;
    public q36: number|null = null;
    public q37: number|null = null;
    public q38: number|null = null;
    public q39: number|null = null;
    public q40: number|null = null;
    public q41: number|null = null;
    public q42: number|null = null;
    public q43: number|null = null;
    public q44: number|null = null;
    public q45: number|null = null;
    public q46: number|null = null;
    public q47: number|null = null;
    public q48: number|null = null;
    public q49: number|null = null;
    public q50: number|null = null;
    public q51: number|null = null;
    public q52: number|null = null;
    public q53: number|null = null;
    public q54: number|null = null;
    public q55: number|null = null;
    public q56: number|null = null;
    public q57: number|null = null;
    public q58: number|null = null;
    public q59: number|null = null;
    public q60: number|null = null;
    public q61: number|null = null;
    public q62: number|null = null;
    public q63: number|null = null;
    public q64: number|null = null;
    public q65: number|null = null;
    public q66: number|null = null;
    public q67: number|null = null;
    public q68: number|null = null;
    public q69: number|null = null;
    public q70: number|null = null;
    public q71: number|null = null;
    public q72: number|null = null;
    public q73: number|null = null;
    public q74: number|null = null;
    public q75: number|null = null;
    public q76: number|null = null;
    public q77: number|null = null;
    public q78: number|null = null;
    public q79: number|null = null;
    public q80: number|null = null;
    public q81: number|null = null;
    public q82: number|null = null;
    public q83: number|null = null;
    public q84: number|null = null;
    public q85: number|null = null;
    public q86: number|null = null;
    public q87: number|null = null;
    public q88: number|null = null;
    public q89: number|null = null;
    public q90: number|null = null;
    public q91: number|null = null;
    public q92: number|null = null;
    public q93: number|null = null;
    public q94: number|null = null;
    public q95: number|null = null;
    public q96: number|null = null;
    public q97: number|null = null;
    public q98: number|null = null;
    public q99: number|null = null;
    public q100: number|null = null;
    public q101: number|null = null;
    public q102: number|null = null;
    public q103: number|null = null;
    public q104: number|null = null;
    public q105: number|null = null;
    public q106: number|null = null;
    public q107: number|null = null;
    public q108: number|null = null;
    public q109: number|null = null;
    public q110: number|null = null;
    public q111: number|null = null;
    public q112: number|null = null;
    public q113: number|null = null;
    public q114: number|null = null;
    public q115: number|null = null;
    public q116: number|null = null;
    public q117: number|null = null;
    public q118: number|null = null;
    public q119: number|null = null;
    public q120: number|null = null;
    public q121: number|null = null;
    public q122: number|null = null;
    public q123: number|null = null;
    public q124: number|null = null;
    public q125: number|null = null;
    public q126: number|null = null;
    public q127: number|null = null;
    public q128: number|null = null;
    public q129: number|null = null;
    public q130: number|null = null;
    public q131: number|null = null;
    public q132: number|null = null;
    public q133: number|null = null;
    public q134: number|null = null;
    public q135: number|null = null;
    public q136: number|null = null;
    public q137: number|null = null;
    public q138: number|null = null;
    public q139: number|null = null;
    public q140: number|null = null;
    public q141: number|null = null;
    public q142: number|null = null;
    public q143: number|null = null;
    public q144: number|null = null;
    public q145: number|null = null;
    public q146: number|null = null;
    public q147: number|null = null;
    public q148: number|null = null;
    public q149: number|null = null;

    public state = () => {
        if (User.get().invitation.response.id != null) {
            switch (User.get().invitation.state) {
                case 6:
                    return "In progress";
                case 7:
                    return "Finished";
                default:
                    return "Not started";
            }
        } else {
            return User.get().invitation.state != 7 ? "Not started" : "n/a";
        }
    }

    public deadline = () => {
        if (User.get().invitation.response.id != null) {
            return "3 days";
        } else {
            return "n/a";
        }
    }

    public progress = () => {
        if (User.get().invitation.response.id != null) {
            let answered = 0;
            answered += (this.q1JobTitle == null ? 0 : 1);
            answered += (this.q2Company == null ? 0 : 1);
            answered += (this.q3Level == null ? 0 : 1);
            answered += (this.q4YearsExperienceJob == null ? 0 : 1);
            answered += (this.q5YearsInCompany == null ? 0 : 1);
            answered += (this.q6YearsExperienceField == null ? 0 : 1);
            answered += (this.q7NumberOfEmployees == null ? 0 : 1);
            answered += (this.q8GrossRevenue == null ? 0 : 1);
            answered += (this.q9ExecutiveReport == null ? 0 : 1);
            answered += (this.q10 == null ? 0 : 1);
            answered += (this.q11 == null ? 0 : 1);
            answered += (this.q12 == null ? 0 : 1);
            answered += (this.q13 == null ? 0 : 1);
            answered += (this.q14 == null ? 0 : 1);
            answered += (this.q15 == null ? 0 : 1);
            answered += (this.q16 == null ? 0 : 1);
            answered += (this.q17 == null ? 0 : 1);
            answered += (this.q18 == null ? 0 : 1);
            answered += (this.q19 == null ? 0 : 1);
            answered += (this.q20 == null ? 0 : 1);
            answered += (this.q21 == null ? 0 : 1);
            answered += (this.q22 == null ? 0 : 1);
            answered += (this.q23 == null ? 0 : 1);
            answered += (this.q24 == null ? 0 : 1);
            answered += (this.q25 == null ? 0 : 1);
            answered += (this.q26 == null ? 0 : 1);
            answered += (this.q27 == null ? 0 : 1);
            answered += (this.q28 == null ? 0 : 1);
            answered += (this.q29 == null ? 0 : 1);
            answered += (this.q30 == null ? 0 : 1);
            answered += (this.q31 == null ? 0 : 1);
            answered += (this.q32 == null ? 0 : 1);
            answered += (this.q33 == null ? 0 : 1);
            answered += (this.q34 == null ? 0 : 1);
            answered += (this.q35 == null ? 0 : 1);
            answered += (this.q36 == null ? 0 : 1);
            answered += (this.q37 == null ? 0 : 1);
            answered += (this.q38 == null ? 0 : 1);
            answered += (this.q39 == null ? 0 : 1);
            answered += (this.q40 == null ? 0 : 1);
            answered += (this.q41 == null ? 0 : 1);
            answered += (this.q42 == null ? 0 : 1);
            answered += (this.q43 == null ? 0 : 1);
            answered += (this.q44 == null ? 0 : 1);
            answered += (this.q45 == null ? 0 : 1);
            answered += (this.q46 == null ? 0 : 1);
            answered += (this.q47 == null ? 0 : 1);
            answered += (this.q48 == null ? 0 : 1);
            answered += (this.q49 == null ? 0 : 1);
            answered += (this.q50 == null ? 0 : 1);
            answered += (this.q51 == null ? 0 : 1);
            answered += (this.q52 == null ? 0 : 1);
            answered += (this.q53 == null ? 0 : 1);
            answered += (this.q54 == null ? 0 : 1);
            answered += (this.q55 == null ? 0 : 1);
            answered += (this.q56 == null ? 0 : 1);
            answered += (this.q57 == null ? 0 : 1);
            answered += (this.q58 == null ? 0 : 1);
            answered += (this.q59 == null ? 0 : 1);
            answered += (this.q60 == null ? 0 : 1);
            answered += (this.q61 == null ? 0 : 1);
            answered += (this.q62 == null ? 0 : 1);
            answered += (this.q63 == null ? 0 : 1);
            answered += (this.q64 == null ? 0 : 1);
            answered += (this.q65 == null ? 0 : 1);
            answered += (this.q66 == null ? 0 : 1);
            answered += (this.q67 == null ? 0 : 1);
            answered += (this.q68 == null ? 0 : 1);
            answered += (this.q69 == null ? 0 : 1);
            answered += (this.q70 == null ? 0 : 1);
            answered += (this.q71 == null ? 0 : 1);
            answered += (this.q72 == null ? 0 : 1);
            answered += (this.q73 == null ? 0 : 1);
            answered += (this.q74 == null ? 0 : 1);
            answered += (this.q75 == null ? 0 : 1);
            answered += (this.q76 == null ? 0 : 1);
            answered += (this.q77 == null ? 0 : 1);
            answered += (this.q78 == null ? 0 : 1);
            answered += (this.q79 == null ? 0 : 1);
            answered += (this.q80 == null ? 0 : 1);
            answered += (this.q81 == null ? 0 : 1);
            answered += (this.q82 == null ? 0 : 1);
            answered += (this.q83 == null ? 0 : 1);
            answered += (this.q84 == null ? 0 : 1);
            answered += (this.q85 == null ? 0 : 1);
            answered += (this.q86 == null ? 0 : 1);
            answered += (this.q87 == null ? 0 : 1);
            answered += (this.q88 == null ? 0 : 1);
            answered += (this.q89 == null ? 0 : 1);
            answered += (this.q90 == null ? 0 : 1);
            answered += (this.q91 == null ? 0 : 1);
            answered += (this.q92 == null ? 0 : 1);
            answered += (this.q93 == null ? 0 : 1);
            answered += (this.q94 == null ? 0 : 1);
            answered += (this.q95 == null ? 0 : 1);
            answered += (this.q96 == null ? 0 : 1);
            answered += (this.q97 == null ? 0 : 1);
            answered += (this.q98 == null ? 0 : 1);
            answered += (this.q99 == null ? 0 : 1);
            answered += (this.q100 == null ? 0 : 1);
            answered += (this.q101 == null ? 0 : 1);
            answered += (this.q102 == null ? 0 : 1);
            answered += (this.q103 == null ? 0 : 1);
            answered += (this.q104 == null ? 0 : 1);
            answered += (this.q105 == null ? 0 : 1);
            answered += (this.q106 == null ? 0 : 1);
            answered += (this.q107 == null ? 0 : 1);
            answered += (this.q108 == null ? 0 : 1);
            answered += (this.q109 == null ? 0 : 1);
            answered += (this.q110 == null ? 0 : 1);
            answered += (this.q111 == null ? 0 : 1);
            answered += (this.q112 == null ? 0 : 1);
            answered += (this.q113 == null ? 0 : 1);
            answered += (this.q114 == null ? 0 : 1);
            answered += (this.q115 == null ? 0 : 1);
            answered += (this.q116 == null ? 0 : 1);
            answered += (this.q117 == null ? 0 : 1);
            answered += (this.q118 == null ? 0 : 1);
            answered += (this.q119 == null ? 0 : 1);
            answered += (this.q120 == null ? 0 : 1);
            answered += (this.q121 == null ? 0 : 1);
            answered += (this.q122 == null ? 0 : 1);
            answered += (this.q123 == null ? 0 : 1);
            answered += (this.q124 == null ? 0 : 1);
            answered += (this.q125 == null ? 0 : 1);
            answered += (this.q126 == null ? 0 : 1);
            answered += (this.q127 == null ? 0 : 1);
            answered += (this.q128 == null ? 0 : 1);
            answered += (this.q129 == null ? 0 : 1);
            answered += (this.q130 == null ? 0 : 1);
            answered += (this.q131 == null ? 0 : 1);
            answered += (this.q132 == null ? 0 : 1);
            answered += (this.q133 == null ? 0 : 1);
            answered += (this.q134 == null ? 0 : 1);
            answered += (this.q135 == null ? 0 : 1);
            answered += (this.q136 == null ? 0 : 1);
            answered += (this.q137 == null ? 0 : 1);
            answered += (this.q138 == null ? 0 : 1);
            answered += (this.q139 == null ? 0 : 1);
            answered += (this.q140 == null ? 0 : 1);
            answered += (this.q141 == null ? 0 : 1);
            answered += (this.q142 == null ? 0 : 1);
            answered += (this.q143 == null ? 0 : 1);
            answered += (this.q144 == null ? 0 : 1);
            answered += (this.q145 == null ? 0 : 1);
            answered += (this.q146 == null ? 0 : 1);
            answered += (this.q147 == null ? 0 : 1);
            answered += (this.q148 == null ? 0 : 1);
            answered += (this.q149 == null ? 0 : 1);

            return Math.round(((100 / 149) * answered));
        } else {
            return 0;
        }
    }

    public progressAsString = () => {
        return User.get().invitation.state != 7 ? this.progress() +  '% complete' : "n/a";
    }

    public save = () => {
        axios.post(Application.instance.apiEndPoint + '/questionnaire/response', this)
            .then(response => {
                this.id = response.data.id;
            })
            .catch(err => {
                // console.log(err);
            });
    }

    public submit = () => {
        axios.post(Application.instance.apiEndPoint + '/questionnaire/response/submit', this)
            .then(response => {
                User.get().invitation.state = 7;
                router.push('/')
            })
            .catch(err => {
                // console.log(err);
            });
    }
}
