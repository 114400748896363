<template>
  <Button @mouseover.native="hover = true" @mouseleave.native="hover = false" @click="onClick()" class="button-default header-button">
    <img v-if="!hover && !selected" src="../../assets/buttons/svg/graph.svg" height="42" width="42"/>
    <img v-else-if="!hover && selected" src="../../assets/buttons/svg/graph-select.svg" height="42" width="42"/>
    <img v-else src="../../assets/buttons/svg/graph-hover.svg" height="42" width="42"/>
  </Button>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    click: {
      type: Function,
      required: true
    },
    selected: {
      type: Boolean
    }
  },
  data() {
    return {
      hover: false
    }
  },
  methods: {
    onClick: () => {
      click();
    }
  },
  setup(props) {
    click = props.click;
  },
});
let click: Function;
</script>
<style scoped>
:focus, :focus-visible, *[data-focus] {
  box-shadow: none !important;
  outline: none !important;
  border-color: inherit !important;
}
</style>
