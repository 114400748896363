import {Profile} from "@/data/Profile";
import {Invitation} from "@/data/Invitation";
import {Application} from "@/data/Application";
import {Company} from "@/data/Company";
import {Questionnaire} from "@/data/Questionnaire";
import {QuestionnaireState} from "@/data/QuestionnaireState";
import axios from "axios";
import {Respondent} from "@/data/Respondent";
import {AuthRoleModel} from "@/data/AuthRoleModel";
import {Response} from "@/data/Response";

export class User {

    public email: string|null = null;
    public profile: Profile = new Profile();
    public invitation: Invitation = new Invitation();
    public consultantCompanies: Company[] = [];

    private static instance : User|undefined = undefined;

    public static get = () => {
        if(User.instance == undefined) {
            User.instance = new User();
        }
        return User.instance;
    }

    public getCompany = (id: Number): Company => {
        for(let company of this.consultantCompanies) {
            if(company.id == id) {
                return company;
            }
        }
        return new Company();
    }

    public static fill = (response: any) => {
        User.get().profile.firstName = response.data.user.userProfile.firstName;
        User.get().profile.lastName = response.data.user.userProfile.lastName;
        User.get().profile.iciId = response.data.user.userProfile.iciId;
        User.get().profile.phone = response.data.user.userProfile.phone;
        User.get().profile.jobTitle = response.data.user.userProfile.jobTitle;
        User.get().profile.level = response.data.user.userProfile.level;
        User.get().profile.defaultRole = response.data.user.userProfile.defaultRole;
        User.get().profile.language = response.data.user.userProfile.language;
        User.get().email = response.data.user.email;
        User.get().consultantCompanies = [];

        if(response.data.user.consultantForCompanies.length > 0) {
            for(let companyCons of response.data.user.consultantForCompanies) {
                let company = Company.build(
                    companyCons.id,
                    companyCons.name,
                    companyCons.numberOfEmployeesId,
                    companyCons.businessActivityId,
                    companyCons.grossRevenueId,
                    companyCons.executiveReportId,
                    companyCons.executiveReportOtherId);

                if(companyCons.iciQuestionaireEntities.length > 0) {
                    for(let quest of companyCons.iciQuestionaireEntities) {
                        let dateParts: string[] = ['9999','12','31'];
                        if(quest.deadline != null) {
                            dateParts = quest.deadline.split('-');
                        }
                        let questionnaire = Questionnaire.build(
                            quest.id,
                            quest.year,
                            quest.name,
                            quest.description,
                            quest.createdDt,
                            quest.active,
                            new Date(Number(dateParts[0]), Number(dateParts[1]) -1, Number(dateParts[2]))
                        );
                        questionnaire.respondents = [];
                        if(quest.respondents != null) {
                            for(let respondent of quest.respondents) {
                                let resp = new Respondent();
                                resp.id = respondent.id;
                                resp.email = respondent.email;
                                if(respondent.state != null) {
                                    resp.state = new AuthRoleModel();
                                    resp.state.id = respondent.state.id;
                                    resp.state.name = respondent.state.name;
                                } else {
                                    resp.state = new AuthRoleModel();
                                    resp.state.id =12;
                                    resp.state.name = "n/a";
                                }
                                if(respondent.response != null && respondent.response.length > 0) {
                                    resp.response = new Response();
                                    resp.response.companyActivity = respondent.response[0].companyActivity;
                                    resp.response.q1JobTitle = respondent.response[0].q1JobTitle;
                                    resp.response.q2Company = respondent.response[0].q2Company;
                                    resp.response.q3Level = respondent.response[0].q3Level;
                                    resp.response.q4YearsExperienceJob = respondent.response[0].q4YearsExperienceJob;
                                    resp.response.q5YearsInCompany = respondent.response[0].q5YearsInCompany;
                                    resp.response.q6YearsExperienceField = respondent.response[0].q6YearsExperienceField;
                                    resp.response.q7NumberOfEmployees = respondent.response[0].q7NumberOfEmployees;
                                    resp.response.q8GrossRevenue = respondent.response[0].q8GrossRevenue;
                                    resp.response.q9ExecutiveReport = respondent.response[0].q9ExecutiveReport;
                                    resp.response.q10 = respondent.response[0].q10;
                                    resp.response.q11 = respondent.response[0].q11;
                                    resp.response.q12 = respondent.response[0].q12;
                                    resp.response.q13 = respondent.response[0].q13;
                                    resp.response.q14 = respondent.response[0].q14;
                                    resp.response.q15 = respondent.response[0].q15;
                                    resp.response.q16 = respondent.response[0].q16;
                                    resp.response.q17 = respondent.response[0].q17;
                                    resp.response.q18 = respondent.response[0].q18;
                                    resp.response.q19 = respondent.response[0].q19;
                                    resp.response.q20 = respondent.response[0].q20;
                                    resp.response.q21 = respondent.response[0].q21;
                                    resp.response.q22 = respondent.response[0].q22;
                                    resp.response.q23 = respondent.response[0].q23;
                                    resp.response.q24 = respondent.response[0].q24;
                                    resp.response.q25 = respondent.response[0].q25;
                                    resp.response.q26 = respondent.response[0].q26;
                                    resp.response.q27 = respondent.response[0].q27;
                                    resp.response.q28 = respondent.response[0].q28;
                                    resp.response.q29 = respondent.response[0].q29;
                                    resp.response.q30 = respondent.response[0].q30;
                                    resp.response.q31 = respondent.response[0].q31;
                                    resp.response.q32 = respondent.response[0].q32;
                                    resp.response.q33 = respondent.response[0].q33;
                                    resp.response.q34 = respondent.response[0].q34;
                                    resp.response.q35 = respondent.response[0].q35;
                                    resp.response.q36 = respondent.response[0].q36;
                                    resp.response.q37 = respondent.response[0].q37;
                                    resp.response.q38 = respondent.response[0].q38;
                                    resp.response.q39 = respondent.response[0].q39;
                                    resp.response.q40 = respondent.response[0].q40;
                                    resp.response.q41 = respondent.response[0].q41;
                                    resp.response.q42 = respondent.response[0].q42;
                                    resp.response.q43 = respondent.response[0].q43;
                                    resp.response.q44 = respondent.response[0].q44;
                                    resp.response.q45 = respondent.response[0].q45;
                                    resp.response.q46 = respondent.response[0].q46;
                                    resp.response.q47 = respondent.response[0].q47;
                                    resp.response.q48 = respondent.response[0].q48;
                                    resp.response.q49 = respondent.response[0].q49;
                                    resp.response.q50 = respondent.response[0].q50;
                                    resp.response.q51 = respondent.response[0].q51;
                                    resp.response.q52 = respondent.response[0].q52;
                                    resp.response.q53 = respondent.response[0].q53;
                                    resp.response.q54 = respondent.response[0].q54;
                                    resp.response.q55 = respondent.response[0].q55;
                                    resp.response.q56 = respondent.response[0].q56;
                                    resp.response.q57 = respondent.response[0].q57;
                                    resp.response.q58 = respondent.response[0].q58;
                                    resp.response.q59 = respondent.response[0].q59;
                                    resp.response.q60 = respondent.response[0].q60;
                                    resp.response.q61 = respondent.response[0].q61;
                                    resp.response.q62 = respondent.response[0].q62;
                                    resp.response.q63 = respondent.response[0].q63;
                                    resp.response.q64 = respondent.response[0].q64;
                                    resp.response.q65 = respondent.response[0].q65;
                                    resp.response.q66 = respondent.response[0].q66;
                                    resp.response.q67 = respondent.response[0].q67;
                                    resp.response.q68 = respondent.response[0].q68;
                                    resp.response.q69 = respondent.response[0].q69;
                                    resp.response.q70 = respondent.response[0].q70;
                                    resp.response.q71 = respondent.response[0].q71;
                                    resp.response.q72 = respondent.response[0].q72;
                                    resp.response.q73 = respondent.response[0].q73;
                                    resp.response.q74 = respondent.response[0].q74;
                                    resp.response.q75 = respondent.response[0].q75;
                                    resp.response.q76 = respondent.response[0].q76;
                                    resp.response.q77 = respondent.response[0].q77;
                                    resp.response.q78 = respondent.response[0].q78;
                                    resp.response.q79 = respondent.response[0].q79;
                                    resp.response.q80 = respondent.response[0].q80;
                                    resp.response.q81 = respondent.response[0].q81;
                                    resp.response.q82 = respondent.response[0].q82;
                                    resp.response.q83 = respondent.response[0].q83;
                                    resp.response.q84 = respondent.response[0].q84;
                                    resp.response.q85 = respondent.response[0].q85;
                                    resp.response.q86 = respondent.response[0].q86;
                                    resp.response.q87 = respondent.response[0].q87;
                                    resp.response.q88 = respondent.response[0].q88;
                                    resp.response.q89 = respondent.response[0].q89;
                                    resp.response.q90 = respondent.response[0].q90;
                                    resp.response.q91 = respondent.response[0].q91;
                                    resp.response.q92 = respondent.response[0].q92;
                                    resp.response.q93 = respondent.response[0].q93;
                                    resp.response.q94 = respondent.response[0].q94;
                                    resp.response.q95 = respondent.response[0].q95;
                                    resp.response.q96 = respondent.response[0].q96;
                                    resp.response.q97 = respondent.response[0].q97;
                                    resp.response.q98 = respondent.response[0].q98;
                                    resp.response.q99 = respondent.response[0].q99;
                                    resp.response.q100 = respondent.response[0].q100;
                                    resp.response.q101 = respondent.response[0].q101;
                                    resp.response.q102 = respondent.response[0].q102;
                                    resp.response.q103 = respondent.response[0].q103;
                                    resp.response.q104 = respondent.response[0].q104;
                                    resp.response.q105 = respondent.response[0].q105;
                                    resp.response.q106 = respondent.response[0].q106;
                                    resp.response.q107 = respondent.response[0].q107;
                                    resp.response.q108 = respondent.response[0].q108;
                                    resp.response.q109 = respondent.response[0].q109;
                                    resp.response.q110 = respondent.response[0].q110;
                                    resp.response.q111 = respondent.response[0].q111;
                                    resp.response.q112 = respondent.response[0].q112;
                                    resp.response.q113 = respondent.response[0].q113;
                                    resp.response.q114 = respondent.response[0].q114;
                                    resp.response.q115 = respondent.response[0].q115;
                                    resp.response.q116 = respondent.response[0].q116;
                                    resp.response.q117 = respondent.response[0].q117;
                                    resp.response.q118 = respondent.response[0].q118;
                                    resp.response.q119 = respondent.response[0].q119;
                                    resp.response.q120 = respondent.response[0].q120;
                                    resp.response.q121 = respondent.response[0].q121;
                                    resp.response.q122 = respondent.response[0].q122;
                                    resp.response.q123 = respondent.response[0].q123;
                                    resp.response.q124 = respondent.response[0].q124;
                                    resp.response.q125 = respondent.response[0].q125;
                                    resp.response.q126 = respondent.response[0].q126;
                                    resp.response.q127 = respondent.response[0].q127;
                                    resp.response.q128 = respondent.response[0].q128;
                                    resp.response.q129 = respondent.response[0].q129;
                                    resp.response.q130 = respondent.response[0].q130;
                                    resp.response.q131 = respondent.response[0].q131;
                                    resp.response.q132 = respondent.response[0].q132;
                                    resp.response.q133 = respondent.response[0].q133;
                                    resp.response.q134 = respondent.response[0].q134;
                                    resp.response.q135 = respondent.response[0].q135;
                                    resp.response.q136 = respondent.response[0].q136;
                                    resp.response.q137 = respondent.response[0].q137;
                                    resp.response.q138 = respondent.response[0].q138;
                                    resp.response.q139 = respondent.response[0].q139;
                                    resp.response.q140 = respondent.response[0].q140;
                                    resp.response.q141 = respondent.response[0].q141;
                                    resp.response.q142 = respondent.response[0].q142;
                                    resp.response.q143 = respondent.response[0].q143;
                                    resp.response.q144 = respondent.response[0].q144;
                                    resp.response.q145 = respondent.response[0].q145;
                                    resp.response.q146 = respondent.response[0].q146;
                                    resp.response.q147 = respondent.response[0].q147;
                                    resp.response.q148 = respondent.response[0].q148;
                                    resp.response.q149 = respondent.response[0].q149;
                                }
                                questionnaire.respondents.push(resp);
                            }
                        }

                        company.questionnaireList.push(questionnaire);
                        // @ts-ignore
                        questionnaire.status = questionnaire.active ? ((questionnaire.deadline - new Date() > 1) ? QuestionnaireState.ACTIVE : QuestionnaireState.EXPIRED) : ((questionnaire.deadline - new Date() > 1) ? QuestionnaireState.DRAFT : QuestionnaireState.CLOSED);
                        questionnaire.deadlineFormatted = questionnaire.deadline?.getDate() + '-' + questionnaire.deadline?.getMonth() + '-' + questionnaire.deadline?.getFullYear();
                    }
                    company.deadline = (company.questionnaireList[0].deadline == null) ? new Date(9999,12,31):company.questionnaireList[0].deadline;
                    company.deadlineFormatted =  company.deadline?.getDate() + '-' + company.deadline?.getMonth() + '-' + company.deadline?.getFullYear();
                    // @ts-ignore
                    company.status = company.questionnaireList[0].active ? ((company.deadline - new Date() > 1) ? QuestionnaireState.ACTIVE : QuestionnaireState.EXPIRED) : ((company.deadline - new Date() > 1) ? QuestionnaireState.DRAFT : QuestionnaireState.CLOSED);
                }
                User.get().consultantCompanies.push(company);
            }
        }

        if(response.data.user.iciUsers.length > 0) {

            User.get().invitation.active = true;
            User.get().invitation.id = response.data.user.iciUsers[0].id;
            User.get().invitation.email = response.data.user.iciUsers[0].email;
            User.get().invitation.active = response.data.user.iciUsers[0].active;
            User.get().invitation.state = response.data.user.iciUsers[0].state;

            User.get().invitation.questionnaire.active = response.data.user.iciUsers[0].questionaire.active;
            User.get().invitation.questionnaire.id = response.data.user.iciUsers[0].questionaire.id;
            User.get().invitation.questionnaire.name = response.data.user.iciUsers[0].questionaire.name;
            User.get().invitation.questionnaire.description = response.data.user.iciUsers[0].questionaire.description;
            User.get().invitation.questionnaire.createdDt = response.data.user.iciUsers[0].questionaire.createdDt;
            User.get().invitation.questionnaire.year = response.data.user.iciUsers[0].questionaire.year;
            User.get().invitation.questionnaire.deadline = response.data.user.iciUsers[0].questionaire.deadline;

            User.get().invitation.questionnaire.company.id = response.data.user.iciUsers[0].questionaire.company.id;
            User.get().invitation.questionnaire.company.name = response.data.user.iciUsers[0].questionaire.company.name;
            User.get().invitation.questionnaire.company.businessActivityId = response.data.user.iciUsers[0].questionaire.company.businessActivityId;
            User.get().invitation.questionnaire.company.grossRevenueId = response.data.user.iciUsers[0].questionaire.company.grossRevenueId;
            User.get().invitation.questionnaire.company.numberOfEmployeesId = response.data.user.iciUsers[0].questionaire.company.numberOfEmployeesId;
            User.get().invitation.questionnaire.company.executiveReportId = response.data.user.iciUsers[0].questionaire.company.executiveReportId;
            User.get().invitation.questionnaire.company.executiveReportOtherId = response.data.user.iciUsers[0].questionaire.company.executiveReportOtherId;

            User.get().invitation.response.userId = response.data.user.iciUsers[0].id;

            if(response.data.user.iciUsers[0].response.length > 0) {
                User.get().invitation.response.id = response.data.user.iciUsers[0].response[0].id;

                if(response.data.user.iciUsers[0].response[0].companyActivity == null) {
                    User.get().invitation.response.companyActivity = Application.instance.findBusinessActivity(User.get().invitation.questionnaire.company.businessActivityId);
                } else {
                    User.get().invitation.response.companyActivity = response.data.user.iciUsers[0].response[0].companyActivity;
                }

                if(response.data.user.iciUsers[0].response[0].q1JobTitle == null) {
                    User.get().invitation.response.q1JobTitle = User.get().profile.jobTitle;
                } else {
                    User.get().invitation.response.q1JobTitle = response.data.user.iciUsers[0].response[0].q1JobTitle;
                }

                if(response.data.user.iciUsers[0].response[0].q2Company == null) {
                    User.get().invitation.response.q2Company = User.get().invitation.questionnaire.company.name;
                } else {
                    User.get().invitation.response.q2Company = response.data.user.iciUsers[0].response[0].q2Company;
                }
                if(response.data.user.iciUsers[0].response[0].q3Level == null) {
                    User.get().invitation.response.q3Level = User.get().profile.level;
                } else {
                    User.get().invitation.response.q3Level = response.data.user.iciUsers[0].response[0].q3Level;
                }

                User.get().invitation.response.q4YearsExperienceJob = response.data.user.iciUsers[0].response[0].q4YearsExperienceJob;
                User.get().invitation.response.q5YearsInCompany = response.data.user.iciUsers[0].response[0].q5YearsInCompany;
                User.get().invitation.response.q6YearsExperienceField = response.data.user.iciUsers[0].response[0].q6YearsExperienceField;
                User.get().invitation.response.q7NumberOfEmployees = response.data.user.iciUsers[0].response[0].q7NumberOfEmployees;
                User.get().invitation.response.q8GrossRevenue = response.data.user.iciUsers[0].response[0].q8GrossRevenue;
                User.get().invitation.response.q9ExecutiveReport = response.data.user.iciUsers[0].response[0].q9ExecutiveReport;
                User.get().invitation.response.q10 = response.data.user.iciUsers[0].response[0].q10;
                User.get().invitation.response.q11 = response.data.user.iciUsers[0].response[0].q11;
                User.get().invitation.response.q12 = response.data.user.iciUsers[0].response[0].q12;
                User.get().invitation.response.q13 = response.data.user.iciUsers[0].response[0].q13;
                User.get().invitation.response.q14 = response.data.user.iciUsers[0].response[0].q14;
                User.get().invitation.response.q15 = response.data.user.iciUsers[0].response[0].q15;
                User.get().invitation.response.q16 = response.data.user.iciUsers[0].response[0].q16;
                User.get().invitation.response.q17 = response.data.user.iciUsers[0].response[0].q17;
                User.get().invitation.response.q18 = response.data.user.iciUsers[0].response[0].q18;
                User.get().invitation.response.q19 = response.data.user.iciUsers[0].response[0].q19;
                User.get().invitation.response.q20 = response.data.user.iciUsers[0].response[0].q20;
                User.get().invitation.response.q21 = response.data.user.iciUsers[0].response[0].q21;
                User.get().invitation.response.q22 = response.data.user.iciUsers[0].response[0].q22;
                User.get().invitation.response.q23 = response.data.user.iciUsers[0].response[0].q23;
                User.get().invitation.response.q24 = response.data.user.iciUsers[0].response[0].q24;
                User.get().invitation.response.q25 = response.data.user.iciUsers[0].response[0].q25;
                User.get().invitation.response.q26 = response.data.user.iciUsers[0].response[0].q26;
                User.get().invitation.response.q27 = response.data.user.iciUsers[0].response[0].q27;
                User.get().invitation.response.q28 = response.data.user.iciUsers[0].response[0].q28;
                User.get().invitation.response.q29 = response.data.user.iciUsers[0].response[0].q29;
                User.get().invitation.response.q30 = response.data.user.iciUsers[0].response[0].q30;
                User.get().invitation.response.q31 = response.data.user.iciUsers[0].response[0].q31;
                User.get().invitation.response.q32 = response.data.user.iciUsers[0].response[0].q32;
                User.get().invitation.response.q33 = response.data.user.iciUsers[0].response[0].q33;
                User.get().invitation.response.q34 = response.data.user.iciUsers[0].response[0].q34;
                User.get().invitation.response.q35 = response.data.user.iciUsers[0].response[0].q35;
                User.get().invitation.response.q36 = response.data.user.iciUsers[0].response[0].q36;
                User.get().invitation.response.q37 = response.data.user.iciUsers[0].response[0].q37;
                User.get().invitation.response.q38 = response.data.user.iciUsers[0].response[0].q38;
                User.get().invitation.response.q39 = response.data.user.iciUsers[0].response[0].q39;
                User.get().invitation.response.q40 = response.data.user.iciUsers[0].response[0].q40;
                User.get().invitation.response.q41 = response.data.user.iciUsers[0].response[0].q41;
                User.get().invitation.response.q42 = response.data.user.iciUsers[0].response[0].q42;
                User.get().invitation.response.q43 = response.data.user.iciUsers[0].response[0].q43;
                User.get().invitation.response.q44 = response.data.user.iciUsers[0].response[0].q44;
                User.get().invitation.response.q45 = response.data.user.iciUsers[0].response[0].q45;
                User.get().invitation.response.q46 = response.data.user.iciUsers[0].response[0].q46;
                User.get().invitation.response.q47 = response.data.user.iciUsers[0].response[0].q47;
                User.get().invitation.response.q48 = response.data.user.iciUsers[0].response[0].q48;
                User.get().invitation.response.q49 = response.data.user.iciUsers[0].response[0].q49;
                User.get().invitation.response.q50 = response.data.user.iciUsers[0].response[0].q50;
                User.get().invitation.response.q51 = response.data.user.iciUsers[0].response[0].q51;
                User.get().invitation.response.q52 = response.data.user.iciUsers[0].response[0].q52;
                User.get().invitation.response.q53 = response.data.user.iciUsers[0].response[0].q53;
                User.get().invitation.response.q54 = response.data.user.iciUsers[0].response[0].q54;
                User.get().invitation.response.q55 = response.data.user.iciUsers[0].response[0].q55;
                User.get().invitation.response.q56 = response.data.user.iciUsers[0].response[0].q56;
                User.get().invitation.response.q57 = response.data.user.iciUsers[0].response[0].q57;
                User.get().invitation.response.q58 = response.data.user.iciUsers[0].response[0].q58;
                User.get().invitation.response.q59 = response.data.user.iciUsers[0].response[0].q59;
                User.get().invitation.response.q60 = response.data.user.iciUsers[0].response[0].q60;
                User.get().invitation.response.q61 = response.data.user.iciUsers[0].response[0].q61;
                User.get().invitation.response.q62 = response.data.user.iciUsers[0].response[0].q62;
                User.get().invitation.response.q63 = response.data.user.iciUsers[0].response[0].q63;
                User.get().invitation.response.q64 = response.data.user.iciUsers[0].response[0].q64;
                User.get().invitation.response.q65 = response.data.user.iciUsers[0].response[0].q65;
                User.get().invitation.response.q66 = response.data.user.iciUsers[0].response[0].q66;
                User.get().invitation.response.q67 = response.data.user.iciUsers[0].response[0].q67;
                User.get().invitation.response.q68 = response.data.user.iciUsers[0].response[0].q68;
                User.get().invitation.response.q69 = response.data.user.iciUsers[0].response[0].q69;
                User.get().invitation.response.q70 = response.data.user.iciUsers[0].response[0].q70;
                User.get().invitation.response.q71 = response.data.user.iciUsers[0].response[0].q71;
                User.get().invitation.response.q72 = response.data.user.iciUsers[0].response[0].q72;
                User.get().invitation.response.q73 = response.data.user.iciUsers[0].response[0].q73;
                User.get().invitation.response.q74 = response.data.user.iciUsers[0].response[0].q74;
                User.get().invitation.response.q75 = response.data.user.iciUsers[0].response[0].q75;
                User.get().invitation.response.q76 = response.data.user.iciUsers[0].response[0].q76;
                User.get().invitation.response.q77 = response.data.user.iciUsers[0].response[0].q77;
                User.get().invitation.response.q78 = response.data.user.iciUsers[0].response[0].q78;
                User.get().invitation.response.q79 = response.data.user.iciUsers[0].response[0].q79;
                User.get().invitation.response.q80 = response.data.user.iciUsers[0].response[0].q80;
                User.get().invitation.response.q81 = response.data.user.iciUsers[0].response[0].q81;
                User.get().invitation.response.q82 = response.data.user.iciUsers[0].response[0].q82;
                User.get().invitation.response.q83 = response.data.user.iciUsers[0].response[0].q83;
                User.get().invitation.response.q84 = response.data.user.iciUsers[0].response[0].q84;
                User.get().invitation.response.q85 = response.data.user.iciUsers[0].response[0].q85;
                User.get().invitation.response.q86 = response.data.user.iciUsers[0].response[0].q86;
                User.get().invitation.response.q87 = response.data.user.iciUsers[0].response[0].q87;
                User.get().invitation.response.q88 = response.data.user.iciUsers[0].response[0].q88;
                User.get().invitation.response.q89 = response.data.user.iciUsers[0].response[0].q89;
                User.get().invitation.response.q90 = response.data.user.iciUsers[0].response[0].q90;
                User.get().invitation.response.q91 = response.data.user.iciUsers[0].response[0].q91;
                User.get().invitation.response.q92 = response.data.user.iciUsers[0].response[0].q92;
                User.get().invitation.response.q93 = response.data.user.iciUsers[0].response[0].q93;
                User.get().invitation.response.q94 = response.data.user.iciUsers[0].response[0].q94;
                User.get().invitation.response.q95 = response.data.user.iciUsers[0].response[0].q95;
                User.get().invitation.response.q96 = response.data.user.iciUsers[0].response[0].q96;
                User.get().invitation.response.q97 = response.data.user.iciUsers[0].response[0].q97;
                User.get().invitation.response.q98 = response.data.user.iciUsers[0].response[0].q98;
                User.get().invitation.response.q99 = response.data.user.iciUsers[0].response[0].q99;
                User.get().invitation.response.q100 = response.data.user.iciUsers[0].response[0].q100;
                User.get().invitation.response.q101 = response.data.user.iciUsers[0].response[0].q101;
                User.get().invitation.response.q102 = response.data.user.iciUsers[0].response[0].q102;
                User.get().invitation.response.q103 = response.data.user.iciUsers[0].response[0].q103;
                User.get().invitation.response.q104 = response.data.user.iciUsers[0].response[0].q104;
                User.get().invitation.response.q105 = response.data.user.iciUsers[0].response[0].q105;
                User.get().invitation.response.q106 = response.data.user.iciUsers[0].response[0].q106;
                User.get().invitation.response.q107 = response.data.user.iciUsers[0].response[0].q107;
                User.get().invitation.response.q108 = response.data.user.iciUsers[0].response[0].q108;
                User.get().invitation.response.q109 = response.data.user.iciUsers[0].response[0].q109;
                User.get().invitation.response.q110 = response.data.user.iciUsers[0].response[0].q110;
                User.get().invitation.response.q111 = response.data.user.iciUsers[0].response[0].q111;
                User.get().invitation.response.q112 = response.data.user.iciUsers[0].response[0].q112;
                User.get().invitation.response.q113 = response.data.user.iciUsers[0].response[0].q113;
                User.get().invitation.response.q114 = response.data.user.iciUsers[0].response[0].q114;
                User.get().invitation.response.q115 = response.data.user.iciUsers[0].response[0].q115;
                User.get().invitation.response.q116 = response.data.user.iciUsers[0].response[0].q116;
                User.get().invitation.response.q117 = response.data.user.iciUsers[0].response[0].q117;
                User.get().invitation.response.q118 = response.data.user.iciUsers[0].response[0].q118;
                User.get().invitation.response.q119 = response.data.user.iciUsers[0].response[0].q119;
                User.get().invitation.response.q120 = response.data.user.iciUsers[0].response[0].q120;
                User.get().invitation.response.q121 = response.data.user.iciUsers[0].response[0].q121;
                User.get().invitation.response.q122 = response.data.user.iciUsers[0].response[0].q122;
                User.get().invitation.response.q123 = response.data.user.iciUsers[0].response[0].q123;
                User.get().invitation.response.q124 = response.data.user.iciUsers[0].response[0].q124;
                User.get().invitation.response.q125 = response.data.user.iciUsers[0].response[0].q125;
                User.get().invitation.response.q126 = response.data.user.iciUsers[0].response[0].q126;
                User.get().invitation.response.q127 = response.data.user.iciUsers[0].response[0].q127;
                User.get().invitation.response.q128 = response.data.user.iciUsers[0].response[0].q128;
                User.get().invitation.response.q129 = response.data.user.iciUsers[0].response[0].q129;
                User.get().invitation.response.q130 = response.data.user.iciUsers[0].response[0].q130;
                User.get().invitation.response.q131 = response.data.user.iciUsers[0].response[0].q131;
                User.get().invitation.response.q132 = response.data.user.iciUsers[0].response[0].q132;
                User.get().invitation.response.q133 = response.data.user.iciUsers[0].response[0].q133;
                User.get().invitation.response.q134 = response.data.user.iciUsers[0].response[0].q134;
                User.get().invitation.response.q135 = response.data.user.iciUsers[0].response[0].q135;
                User.get().invitation.response.q136 = response.data.user.iciUsers[0].response[0].q136;
                User.get().invitation.response.q137 = response.data.user.iciUsers[0].response[0].q137;
                User.get().invitation.response.q138 = response.data.user.iciUsers[0].response[0].q138;
                User.get().invitation.response.q139 = response.data.user.iciUsers[0].response[0].q139;
                User.get().invitation.response.q140 = response.data.user.iciUsers[0].response[0].q140;
                User.get().invitation.response.q141 = response.data.user.iciUsers[0].response[0].q141;
                User.get().invitation.response.q142 = response.data.user.iciUsers[0].response[0].q142;
                User.get().invitation.response.q143 = response.data.user.iciUsers[0].response[0].q143;
                User.get().invitation.response.q144 = response.data.user.iciUsers[0].response[0].q144;
                User.get().invitation.response.q145 = response.data.user.iciUsers[0].response[0].q145;
                User.get().invitation.response.q146 = response.data.user.iciUsers[0].response[0].q146;
                User.get().invitation.response.q147 = response.data.user.iciUsers[0].response[0].q147;
                User.get().invitation.response.q148 = response.data.user.iciUsers[0].response[0].q148;
                User.get().invitation.response.q149 = response.data.user.iciUsers[0].response[0].q149;
            } else {
                User.get().invitation.response.companyActivity = Application.instance.findBusinessActivity(User.get().invitation.questionnaire.company.businessActivityId);
                User.get().invitation.response.q1JobTitle = User.get().profile.jobTitle;
                User.get().invitation.response.q2Company = User.get().invitation.questionnaire.company.name;
                User.get().invitation.response.q3Level = User.get().profile.level;
            }
        } else {
            User.get().invitation.active = false;
        }
    }

    public refreshConsCompanyList = (callback: any) => {
        axios.get(Application.instance.apiEndPoint + '/consultant/company/list')
            .then(response => {
                User.get().consultantCompanies = [];
                if(response.data.length > 0) {
                    for(let companyCons of response.data) {
                        let company = Company.build(
                            companyCons.id,
                            companyCons.name,
                            companyCons.numberOfEmployeesId,
                            companyCons.businessActivityId,
                            companyCons.grossRevenueId,
                            companyCons.executiveReportId,
                            companyCons.executiveReportOtherId);

                        if(companyCons.iciQuestionaireEntities.length > 0) {
                            for(let quest of companyCons.iciQuestionaireEntities) {
                                let dateParts: string[] = ['9999','12','31'];
                                if(quest.deadline != null) {
                                    dateParts = quest.deadline.split('-');
                                }
                                let questionnaire = Questionnaire.build(
                                    quest.id,
                                    quest.year,
                                    quest.name,
                                    quest.description,
                                    quest.createdDt,
                                    quest.active,
                                    new Date(Number(dateParts[0]), Number(dateParts[1]) -1, Number(dateParts[2]))
                                );
                                company.questionnaireList.push(questionnaire);
                                // @ts-ignore
                                questionnaire.status = questionnaire.active ? ((questionnaire.deadline - new Date() > 1) ? QuestionnaireState.ACTIVE : QuestionnaireState.EXPIRED) : ((questionnaire.deadline - new Date() > 1) ? QuestionnaireState.DRAFT : QuestionnaireState.CLOSED);
                                questionnaire.deadlineFormatted = questionnaire.deadline?.getDate() + '-' + questionnaire.deadline?.getMonth() + '-' + questionnaire.deadline?.getFullYear();
                            }
                            company.deadline = (company.questionnaireList[0].deadline == null) ? new Date(9999,12,31):company.questionnaireList[0].deadline;
                            company.deadlineFormatted =  company.deadline?.getDate() + '-' + company.deadline?.getMonth() + '-' + company.deadline?.getFullYear();
                            // @ts-ignore
                            company.status = company.questionnaireList[0].active ? ((company.deadline - new Date() > 1) ? QuestionnaireState.ACTIVE : QuestionnaireState.EXPIRED) : ((company.deadline - new Date() > 1) ? QuestionnaireState.DRAFT : QuestionnaireState.CLOSED);
                        }
                        User.get().consultantCompanies.push(company);
                    }
                }
                callback();
            })
            .catch(err => {
                // console.log(err);
            });
    }
}
