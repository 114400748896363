<template>
  <!-- div class="grid">
    <div class="col-1">
      <div class="label-number">&nbsp;</div>
    </div>
    <div class="col-11">
      <div class="grid">
        <div class="col-12">
      <div class="paragraph title-margin" v-html="$tc('questionnaireForm.titleSRLCapabilities')"/>
        </div>
      </div>
    </div>
  </div -->

  <div class="m-0 h-full" style="justify-content: space-between">
    <div class="flex w-full h-full" style="position: relative">

      <div class="questionCount">
        <span>Question</span>
        <div style='font-weight: 700;'>
          <span id='currentQuestion'>{{ activeQuestion - 116 }}</span>
          <span style='font-weight: 400;'>/</span>
          <span id='allQuestions'>33</span>
        </div>
      </div>

    <Stepper linear unstyled: true v-model:active-step="activeSub">
      
      <StepperPanel :header="$tc('questionnaireForm.titleSensingCapabilitiesMeasurement')">
        <QuestionRating v-if="activeQuestion == 117" question="117" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 118" question="118" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 119" question="119" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 120" question="120" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 121" question="121" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 122" question="122" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 123" question="123" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 124" question="124" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 125" question="125" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 126" question="126" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 127" question="127" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 128" question="128" :change="autoSave"></QuestionRating>
      </StepperPanel>

      <StepperPanel :header="$tc('questionnaireForm.titleRespondingCapabilitiesMeasurement')">
        <QuestionRating v-if="activeQuestion == 129" question="129" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 130" question="130" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 131" question="131" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 132" question="132" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 133" question="133" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 134" question="134" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 135" question="135" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 136" question="136" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 137" question="137" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 138" question="138" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 139" question="139" :change="autoSave"></QuestionRating>
      </StepperPanel>

      <StepperPanel :header="$tc('questionnaireForm.titleLearningCapabilitiesMeasurement')">
        <QuestionRating v-if="activeQuestion == 140" question="140" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 141" question="141" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 142" question="142" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 143" question="143" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 144" question="144" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 145" question="145" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 146" question="146" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 147" question="147" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 148" question="148" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 149" question="149" :change="autoSave"></QuestionRating>
      </StepperPanel>

    </Stepper>

    </div>
  </div>

</template>


<script lang="ts">
// @ is an alias to /src

import {Options, Vue} from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import axios from 'axios';
import {User} from "@/data/User";
import {Application} from "@/data/Application";
import {AuthService} from "@/service/AuthService";
import {ref} from "vue";

import { defineComponent, PropType } from 'vue';
import QuestionRating from "@/components/questionnaire/QuestionRating.vue";

export default defineComponent({
  components: {QuestionRating},
  computed: {
    User() {
      return User
    },
    Application() {
      return Application
    }
  },
  props: {
    activeQuestion: {
      type: Number,
      required: true
    },
    activeSub: {
      type: Number,
      required: true
    }
  },
  methods: {
    autoSave: () => {
      User.get().invitation.response.save();
    }
  },
  setup() {
  },
})
</script>
<style>
.title-margin {
  margin-left: -30px;
}
.p-stepper .p-stepper-header.p-highlight .p-stepper-number {
  background: #0d3d52;
  color: #ffffff;
}
.stepper-offset-horizontal {
  margin-left: 78px;
}
.p-stepper {
  width: 99% !important;
}
.p-stepper-content {
  margin-left: -75px;
}
.p-stepper-nav {
  padding-right: 200px;
}
.p-slider-range {
  background-color: #5EBCB8;
}
.p-slider .p-slider-handle {
  background-color: #0d3d52;
  border-style: none;
}
</style>

