import {AuthRoleModel} from "@/data/AuthRoleModel";
import {Response} from "@/data/Response";

export class Respondent {

    public id: number|null = null;
    public email: string|null = null;
    public state: AuthRoleModel|null = null;
    public response: Response|null = null;
    public sentInvitation: Boolean = false;
}
