<template>
  <Button @mouseover.native="hover = true" @mouseleave.native="hover = false" @click="onClick()" class="button-default header-button">
    <img v-if="!hover" src="../../assets/buttons/svg/logout.svg" width="42"/>
    <img v-else src="../../assets/buttons/svg/logout-hover.svg" height="42" width="42"/>
  </Button>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    click: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      hover: false
    }
  },
  methods: {
    onClick: () => {
      click();
    }
  },
  setup(props) {
    click = props.click;
  },
});
let click: Function;
</script>
<style scoped>

</style>
