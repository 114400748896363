import {Questionnaire} from "@/data/Questionnaire";
import {QuestionnaireState} from "@/data/QuestionnaireState";
import axios from "axios";
import {Application} from "@/data/Application";
import {User} from "@/data/User";

export class Company {
    public id: number|null = null;
    public name: string|null = null;
    public numberOfEmployeesId: number|null = null;
    public businessActivityId: number|null = null;
    public grossRevenueId: number|null = null;
    public executiveReportId: number|null = null;
    public executiveReportOtherId: string|null = null;
    public questionnaireList: Questionnaire[] = [];
    public deadline: Date | null = new Date(9999,12,31);
    public deadlineFormatted: string = 'n/a';
    public status: number = QuestionnaireState.UNKNOWN;

    public static build = (id: number,
                           name: string,
                           numberOfEmployeesId: number,
                           businessActivityId: number,
                           grossRevenueId: number,
                           executiveReportId: number,
                           executiveReportOtherId: string): Company =>{
        let com = new Company();
        com.id = id;
        com.name = name;
        com.numberOfEmployeesId = numberOfEmployeesId;
        com.businessActivityId = businessActivityId;
        com.grossRevenueId = grossRevenueId;
        com.executiveReportId = executiveReportId;
        com.executiveReportOtherId = executiveReportOtherId;

        return com;
    }

    public getActiveOrLatestQuestionnaire = () => {
        if(this.questionnaireList.length == 0) {
            return Questionnaire.build(
                0,
                9999,
                'No existing questionnaire',
                'No existing questionnaire',
                new Date(9999,12, 31),
                false,
                new Date(9999,12, 31))
        } else {
            return this.questionnaireList[0];
        }
    }

    public save = (callback: any) => {
        axios.post(Application.instance.apiEndPoint + '/company/', this)
            .then(response => {
                User.get().refreshConsCompanyList(
                    () => {
                        for(let company of User.get().consultantCompanies) {
                            if(company.id == response.data.id) {
                                callback(company);
                            }
                        }
                    }
                );
                //callback();
            })
            .catch(err => {
                // console.log(err);
            });
    }
}
