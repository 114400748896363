<template>
  <ItemBox id="item-n-4" :visible="visible" title="Innovation Capability Index 2" :headerItems="headerItems"
    toggleClass="item4-toggle minus" :closeAction="closeItem" :graphViewClick="graphViewClick"
    :graphToggle="graphToggle">
    <div v-if="graphToggle" class="featured col-span-12 flex flex-col  h-96 overflow-y-auto  gap-3 p-2 pb-0 ">

      <div class="col-span-12" v-for="(factor, index) in [
    {
      mean: questionnaireResults.env_FACTORS,
      capabilities: [
        {
          capability: '1',
          mean: questionnaireResults.slr_SENSING,
        },
        {
          capability: '2',
          mean: questionnaireResults.srl_RESPONDING,
        },
        {
          capability: '3',
          mean: questionnaireResults.srl_LEARNING,
        },
      ]
    }
  ]">
        <div class="grid grid-cols-12 gap-2 p-2">

          <div class="col-span-8 grid gap-2">
            <div v-for="(capability) in factor.capabilities" :key="capability.capability"
              class=" col-span-8 grid grid-cols-8 gap-2 pt-2 px-2">

              <div class="flex col-span-2 rounded-xl p-1 items-center justify-between" :class="getBgClass(index)">
                <div class="flex justify-center w-full">
                  <h4 class="text-start">{{ capability.capability }}</h4>
                </div>
              </div>

              <div class="flex col-span-1  rounded-xl py-1 px-4 items-center justify-between"
                :class="getBgClass(index)">
                <div class="flex justify-center w-full">
                  <h4>{{ capability.mean }}</h4>
                </div>
              </div>

              <div class="flex flex-col h-full bg-[#fbfaff] rounded-full col-span-5 gap-2">
                <div class="resultProgressBar rounded-full h-full barTransition"
                  :style="calculateBorder(capability.mean)"></div>
              </div>
            </div>
          </div>

          <div
            class="flex col-span-4 row-span-3 h-full border-2 border-[#aedddb] rounded-xl p-1 items-center justify-between">
            <div class="flex justify-center items-center w-full gap-2">

              <div class="flex items-center rateBg h-fit p-2 bg-[#d4d4d4] rounded-xl leading-4 font-bold text-center">
                {{ factor.mean }}
              </div>

            </div>
          </div>

        </div>



      </div>
    </div>


    <div v-else class="featured grid col-span-12 flex flex-col gap-2">
      <div class="f-graph grid grid-cols-12 gap-2">

        <GraphIci :circles="circles" :hoveredIndex="hoveredIndex" :setHoveredIndex="setHoveredIndex" />

        <div class="flex col-span-2 pt-16 pr-12 font-semibold">
          <div class="flex gap-4">
            <div class="border-2 border-black rounded-full h-5 w-5 aspect-square"></div>
            <p class="uppercase text-lg leading-5">Position of capability if aligned</p>
          </div>
        </div>

        <div class="flex flex-col gap-2 col-span-4 py-4 justify-center">

          <div class="flex justify-between border-2 border-[#aedddb] rounded-xl p-2 pl-3 w-full h-fit">
            <h4 class="leading-6">Category</h4>
            <div class="flex border-2 border-[#aedddb] rounded-lg p-1 leading-4">Mean</div>
          </div>

          <div v-for="(category, index) in categories" :key="index"
            class="flex justify-between rounded-xl p-2 pl-3 w-full h-fit duration-150 ease-in-out"
            @mouseover="setHoveredIndex(index)" @mouseleave="setHoveredIndex(null)" 
            :class="hoveredIndex === index ? 'bg-[#aedddb]' : 'bg-[#fbfaff]'">
            <h4 class="leading-6">{{ category.name }}</h4>
            <div class="flex border-2 rating rounded-lg p-1 leading-4" :style="calculateColor(category.mean, hoveredIndex=== index)">
              {{ category.mean == '1' ? '1.00' : category.mean }}
            </div>
          </div>
        </div>

      </div>
    </div>

  </ItemBox>
</template>

<script>
import ItemBox from '@/components/results/ItemBox.vue'
import GraphIci from '@/components/results/GraphIci.vue'

export default {
  components: {
    ItemBox, GraphIci
  },
  data() {
    return {
      hoveredIndex: null,

      circles: [
        { cx: 600, cy: 276.5, r: 87.64, fill: '#82CA74', rotation: 0, translateY: 0, strokeTranslateY: 10 }, // Top Circle
        { cx: 400, cy: 823.5, r: 85.6, fill: '#82CA74', rotation: 0, translateY: 0, strokeTranslateY: 10 }, // Bottom Left Circle
        { cx: 800, cy: 823.5, r: 55, fill: '#F4A14A', rotation: 0, translateY: 0, strokeTranslateY: 10 } // Bottom Right Circle
      ],
      headerItems: [
        { name: 'Capabilities', class: 'col-span-2' },
        { name: 'Mean', class: 'col-span-1' },
        { name: 'Scale', class: 'col-span-5', scale: true },
        { name: 'Environmental Pressure', class: 'col-span-4 ', color: 'border-2 border-[#aedddb] bg-white' },
      ]
    }
  },
  computed: {
    categories() {
      return [{
        name: 'Learning',
        mean: this.questionnaireResults.srl_LEARNING
      },
      {
        name: 'Responding',
        mean: this.questionnaireResults.srl_RESPONDING
      },
      {
        name: 'Sensing',
        mean: this.questionnaireResults.slr_SENSING
      }
      ]
    }
  }
  ,
  props: {
    graphViewClick: {
      type: Boolean,
      required: false
    },
    graphToggle: {
      type: Boolean,
      required: false
    },
    calculateColor: {
      type: Function,
      required: true
    },
    calculateBorder: {
      type: Function,
      required: true
    },
    questionnaireResults: {
      type: Array,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    closeAction: {
      type: Function,
      required: true
    },
    getBgClass: {
      type: Function,
      required: true
    }
  },
  methods: {
    closeItem() {
      this.closeAction();
    },
    setHoveredIndex(index) {
      this.hoveredIndex = index;
    }
  }
};
</script>
