<template>
  <ItemBox id="item-n-1" :visible="visible" title="Questionnaire Criteria and Scores" :headerItems="headerItems"
    toggleClass="item1-toggle minus" :closeAction="closeItem">
    <div class="featured col-span-12 flex flex-col  h-96 overflow-y-auto  gap-3 p-2 pb-0 ">

      <div class="col-span-12" v-for="(enabler, index) in [
    {
      enabler: 'Network Characteristics',
      escore: questionnaireResults.nc_CHARACTERISTICS,
      criterions: [
        {
          criterion: 'C1',
          criteria: 'Relationship based on trust',
          cscore: questionnaireResults.nc_RELATIONSHIPS
        },
        {
          criterion: 'C2',
          criteria: 'Performance metrics, measurement and benchmarking',
          cscore: questionnaireResults.nc_PERFORMANCE
        },
        {
          criterion: 'C3',
          criteria: 'Process integration and collaborative work',
          cscore: questionnaireResults.nc_PROCESS
        },
        {
          criterion: 'C4',
          criteria: 'Knowledge sharing',
          cscore: questionnaireResults.nc_KNOWLEDGE
        },
        {
          criterion: 'C5',
          criteria: 'Quick connect and disconnect',
          cscore: questionnaireResults.nc_CONNECT
        }
      ]
    },
    {
      enabler: 'Organisational Governance',
      escore: questionnaireResults.nc_ORGANIZATIONAL_GOVERNANCE,
      criterions: [
        {
          criterion: 'C6',
          criteria: 'Empowerment & rewards',
          cscore: questionnaireResults.og_EMPOWERMENT
        },
        {
          criterion: 'C7',
          criteria: 'Performance measures',
          cscore: questionnaireResults.og_PERFORMANCE
        },
        {
          criterion: 'C8',
          criteria: 'Flexible Budgeting',
          cscore: questionnaireResults.og_BUGETING
        }
      ]
    },
    {
      enabler: 'Organisational Architecture',
      escore: questionnaireResults.nc_ORGANIZATIONAL_ARCHITECTURE,
      criterions: [
        {
          criterion: 'C9',
          criteria: 'Standardisation & simplification',
          cscore: questionnaireResults.oa_PROCESS
        },
        {
          criterion: 'C10',
          criteria: 'Modularity',
          cscore: questionnaireResults.oa_MODULARITY
        },
        {
          criterion: 'C11',
          criteria: 'Customisation',
          cscore: questionnaireResults.oa_CUSTOMIZATION
        }
      ]
    },
    {
      enabler: 'Technical IT Infrastructure',
      escore: questionnaireResults.nc_TECHNICALIT_INFRASTRUCTURE,
      criterions: [
        {
          criterion: 'C12',
          criteria: 'Connectivity',
          cscore: questionnaireResults.ti_CONNECTIVITY
        },
        {
          criterion: 'C13',
          criteria: 'Compatibility',
          cscore: questionnaireResults.ti_COMPATIBILITY
        },
        {
          criterion: 'C14',
          criteria: 'Modularity',
          cscore: questionnaireResults.ti_MODULARITY
        },
        {
          criterion: 'C15',
          criteria: 'Reusability',
          cscore: questionnaireResults.ti_REUSABILITY
        },
        {
          criterion: 'C16',
          criteria: 'Data Transparency',
          cscore: questionnaireResults.ti_DATASHARING
        }
      ]
    },
    {
      enabler: 'Human IT Infrastructure',
      escore: questionnaireResults.hi_HUMANIT_INFRASTRUCTURE,
      criterions: [
        {
          criterion: 'C17',
          criteria: 'Technology management',
          cscore: questionnaireResults.hi_TECHNOLOGY
        },
        {
          criterion: 'C18',
          criteria: 'Management skills',
          cscore: questionnaireResults.hi_MANAGEMENT_SKILLS
        },
        {
          criterion: 'C19',
          criteria: 'Technical skills',
          cscore: questionnaireResults.hi_TECHNICAL_SKILLS
        }
      ]
    },
    {
      enabler: 'Organisational Culture',
      escore: questionnaireResults.hi_ORGANIZATIONAL_CULTURE,
      criterions: [
        {
          criterion: 'C20',
          criteria: 'Leadership by coaching',
          cscore: questionnaireResults.oc_LEADERSHIP
        },
        {
          criterion: 'C21',
          criteria: 'Fostering a culture of knowledge sharing & learning',
          cscore: questionnaireResults.oc_FOSTERING
        },
        {
          criterion: 'C22',
          criteria: 'Flexible Budgeting',
          cscore: questionnaireResults.oc_INTERACTIONS
        }
      ]
    },
    {
      enabler: 'Human Knowledge & Skills',
      escore: questionnaireResults.hi_HUMAN_KNOWLEDGE,
      criterions: [
        {
          criterion: 'C23',
          criteria: 'Knowledge (tacit), skills variety and redeployability',
          cscore: questionnaireResults.hs_KNOWLEDGE
        },
        {
          criterion: 'C24',
          criteria: 'Speed of acquiring and developing new skills',
          cscore: questionnaireResults.hs_SPEED_ACQ
        },

      ]
    }

  ]">
        <div class="grid grid-cols-12 gap-2 px-2 py-1  ">

          <div class="col-span-8 grid grid-cols-8 gap-2  ">
            <div v-for="(criterion) in enabler.criterions" :key="criterion.criterion"
              class=" col-span-8 grid grid-cols-8 gap-2 pt-2 px-2">
              <div class="flex col-span-1 rounded-xl p-1 items-center justify-between" :class="getBgClass(index)">
                <div class="flex justify-center w-full">
                  <h4 class="text-start">{{ criterion.criterion }}</h4>
                </div>
              </div>

              <div class="flex col-span-6  rounded-xl py-1 px-4 items-center justify-between"
                :class="getBgClass(index)">
                <div class="flex w-full">
                  <h4>{{ criterion.criteria }}</h4>
                </div>
              </div>

              <div class="flex col-span-1 rounded-xl p-1 items-center justify-between" :class="getBgClass(index)">
                <div class="flex justify-center w-full">
                  <div :style="calculateColor(criterion.cscore)"
                    class="flex  rating border-2 py-1 px-1.5 pb-1.5 border-[#d4d4d4] rounded-lg leading-3 justify-center">
                    {{ criterion.cscore === 1 ? '1.00' : criterion.cscore }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex col-span-2 rounded-xl ml-2 px-4 items-center justify-between"
            :class="getBgClass(index)">
            <div class="flex w-full justify-center ">
              <h4 class="text-center">{{ enabler.enabler }}</h4>
            </div>
          </div>

          <div class="flex col-span-2 rounded-xl p-1 items-center justify-center" :class="getBgClass(index)">
            <div class="flex justify-center">
              <div class="flex rating border-2 px-4 py-5 rounded-xl leading-4 w-full justify-center"
                :style="calculateColor(enabler.escore)">
                {{ enabler.escore === 1 ? '1.00' : enabler.escore }}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </ItemBox>
</template>

<script>
import ItemBox from '@/components/results/ItemBox.vue'

export default {
  components: {
    ItemBox
  },
  data() {
    return {
      headerItems: [
        { name: 'Criterion #', class: 'col-span-1' },
        { name: 'Criteria', class: 'col-span-6' },
        { name: 'C score', class: 'col-span-1' },
        { name: 'Enablers', class: 'col-span-2' },
        { name: 'E Score', class: 'col-span-2' },
      ]
    }
  },
  props: {
    calculateColor: {
      type: Function,
      required: true
    },
    getBgClass: {
      type: Function,
      required: true
    },
    questionnaireResults: {
      type: Array,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    closeAction: {
      type: Function,
      required: true
    },
    getBgClass: {
      type: Function,
      required: true
    }
  },
  methods: {
    closeItem() {
      this.closeAction();
    }
  }
};
</script>
