<template>
  <div class="panel" style="gap: 8px">
    <div class="label">Description</div>
    <div class="field">
      <Textarea v-model="description" rows="3" cols="150" ></Textarea>
    </div>
    <div class="label">Algorithm</div>
    <div class="field">
      <Textarea v-model="algorithm" rows="15" cols="150" ></Textarea>
    </div>
    <div class="save-button">
      <IciButton id="submit"
                 content="Save"
                 @buttonClick="this.save"
                 btnStyle="width: 140px;"
                 :dark="true"></IciButton>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import IciButton from "@/components/IciButton.vue";
import axios from "axios";
import {Application} from "@/data/Application";
import {Algorithm} from "@/data/Algorithm";
import {MailTemplate} from "@/data/MailTemplate";
@Options({
  components: {IciButton}
})
export default class AdminAlgorithmSettingsView extends Vue {

  private description:String = "";
  private algorithm:String = "";
  mounted() {
    axios.get(Application.instance.apiEndPoint + '/admin/algorithm/active')
        .then(response => {
          console.log(response.data);
          this.description = response.data.description;
          this.algorithm = response.data.algorithm;
        })
        .catch(err => {
          console.log(err);
        });
  }

  private save() {
    let algorithmModel: Algorithm = new Algorithm();
    algorithmModel.algorithm = this.algorithm;
    algorithmModel.description = this.description;
    axios.post(Application.instance.apiEndPoint + '/admin/algorithm/active',  algorithmModel)
        .then(response => {
          console.log(response.data);
        })
        .catch(err => {
          console.log(err);
        });
  }
}
</script>
<style scoped>
.panel {
  text-align: left;
  padding-left: 70px;
}
.label {
  font-family: "Public Sans";
  font-weight: bold;
}
.field {
  font-family: "Public Sans";
  font-weight: normal;
}
.input {
  font-family: "Public Sans";
  width: 750px;
}
</style>
