<template>
  <div v-if="visible" :id="id"
    class="boxItem col-span-12 w-full grid grid-cols-10 gap-2 bg-white rounded-xl drop-shadow-md p-3 mt-3">
    <div class="itemHead col-span-12 bg-[#f3f4f8] px-3 py-4 rounded-xl flex items-center justify-between">
      <h3 class="font-bold">{{ title }}</h3>

      <div class="flex gap-4 items-center">
        <div v-if="graphViewClick" class="viewBox flex bg-white px-3 py-2 rounded-xl gap-4 relative">
          <div :class="[graphToggle ? 'right-1' : 'left-1'], {
    'i3-toggle': id === 'item-n-3',
    'i4-toggle': id === 'item-n-4',
  }" class="i4-toggle absolute bg-[#aedddb] h-5/6 top-1 rounded-xl z-0 transition ease-in-out"
            :style="{ width: graphToggle ? 'calc(50% - 10px)' : '50%' }"></div>
          <p @click="graphViewClick(false)" :class="{
    'gv3-toggle': id === 'item-n-3',
    'gv4-toggle': id === 'item-n-4'
  }" class="w-1/2 text-nowrap z-10 hover:cursor-pointer">
            Graph View</p>
          <p @click="graphViewClick(true)" :class="{
    'gv3-toggle': id === 'item-n-3',
    'gv4-toggle': id === 'item-n-4'
  }" class="1/2 text-nowrap z-10 hover:cursor-pointer">
            Data View</p>
        </div>
        <img @click="closeItem" :class="toggleClass" style="cursor: pointer" :src="toggleIcon">

      </div>
    </div>

    <div v-if="(graphViewClick ? graphToggle: true) " class="f-head col-span-12 grid grid-cols-12 gap-2 p-2 pb-0">
      <div v-for="item in headerItems" :key="item.name" :class="item.class">
        <!-- Render this div if item.scale is false or undefined -->
        <div v-if="!item.scale" class="flex bg-[#f3f4f8] h-full rounded-xl p-3 items-center justify-between"
          :class="item.color">
          <div class="flex justify-center w-full">
            <h4 class="text-center leading-4">{{ item.name }}</h4>
          </div>

        </div>

        <!-- Render this div if item.scale is true -->
        <div v-else class="flex flex-col items-center justify-between gap-3 ">
          <div class="flex justify-center w-full bg-[#f3f4f8] rounded-xl p-2">
            <h4 class="text-center">{{ item.name }}</h4>
          </div>
          <div class="grid grid-cols-10 w-full" :class="item.color">
            <div v-for="i in 10" :key="i"
              :class="['flex p-1 pt-0 leading-4  border-t-0 border border-[#b8b8b8]', { 'rounded-bl-md border-r-0': i === 1, 'rounded-br-md': i === 10, 'border-r-0': i < 10 }]">
              {{ i - 1 }}
            </div>
          </div>
        </div>
      </div>
    </div>


    <slot></slot>

  </div>
</template>

<script>
export default {
  data() {
    return {
      toggleIcon: require('../../assets/minus-icon.svg'),
    }
  },
  props: {
    graphViewClick: {
      type: Boolean,
      required: false
    },
    graphToggle: {
      type: Boolean,
      required: false
    },
    id: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    toggleClass: {
      type: String,
      required: true
    },

    closeAction: {
      type: Function,
      required: true
    },
    headerItems: {
      type: Array,
      required: true
    }
  },
  methods: {
    closeItem() {
      this.closeAction();
    }
  }
};
</script>


