<template>

  <div class="content-respondent-dashboard">

    <div class="flex" style="align-items: center; justify-content: space-between">
      <div class="welcome-title-respondent-dashboard">
        <span v-if="selectedClient.id != 0" v-html="selectedClient.name"/>
        <span v-if="selectedClient.id == 0" v-html="$t('companyForm.titleAddNewClient')"/>
      </div>

      <div class="back-to-all-clients">
        <IciButton id="backToDashboard"
                   :content="constructLabel(buttonBackToAllClients, $t('companyForm.buttonBackToDashboard'))"
                   @buttonClick="backToClientDashboard(selectedClient.id)"
                   btnStyle="width: fit-content; height: auto; padding: 18px 24px!important;"></IciButton>
      </div>
    </div>


    <div class="respondent-dashboard-block">

      <div style="position: relative; width: 100%; height: 100%">
        <div style="position: absolute; top: 40px; left: 40px; right: 40px; height: 50px; border-radius: 10px; background-color: #fbfaff; font-family: 'Public Sans'; font-weight: bold; text-align: left; padding-left: 40px; line-height: 50px;" v-html="$t('companyForm.titleDashboard') + ' > ' + $t('companyForm.titleProjectOverview') + ' > Innovation Capability Index Questionnaire'"/>
      </div>

      <div style="position:absolute; top: 140px; bottom: 100px; left: 40px; right: 40px; min-width: 1400px; border-radius: 10px; min-height: 90px; background-color: #ffffff;">

        <div style="position: relative; width: 100%; height: 100%;">

          <div style="position: absolute; top: 0px; left: 0px; right: 0px; height: 50px; background-color: #F3F4F8; border-radius: 10px; min-width: 1000px;">
            
            <div style="position: relative; width: 100%; height: 100%; min-width: 1000px">
              <div style="float: left; width: 40%; min-width: 300px; font-family: 'Public Sans'; font-weight: bold; font-size: 16px; line-height: 50px; text-align: left; padding-left: 30px;" v-html="$t('companyForm.tableHeaderRespondentName')"></div>
              <div style="float: left; width: 30%; min-width: 300px; font-family: 'Public Sans'; font-weight: bold; font-size: 16px; line-height: 50px; text-align: left;" v-html="$t('companyForm.tableHeaderStatus')"></div>
              <div style="float: left; width: 30%; min-width: 300px; font-family: 'Public Sans'; font-weight: bold; font-size: 16px; line-height: 50px; text-align: left;" v-html="$t('companyForm.tableHeaderProgressOverview')"></div>
            </div>

          </div>

          <div style="position: absolute; top: 50px; left: 0px; right: 0px; bottom: 10px;">

            <div v-for="repondent of selectedClient?.getActiveOrLatestQuestionnaire().respondents" style="position: relative; width: 100%; min-width: 1000px; height: 70px; background-color: #FFFFFF; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
              
              <div style="float: left; width: 40%; min-width: 300px;  font-family: 'Public Sans'; font-size: 16px; line-height: 50px; text-align: left; padding-left: 20px;" v-html="repondent.email"></div>
              
              <div style="float: left; width: 30%; min-width: 300px; font-family: 'Public Sans'; font-size: 16px; line-height: 70px; text-align: left; padding-top: 20px;">
                <IciStatus :state="repondent.state.id"></IciStatus>
              </div>

              <div style="float: left; width: 30%; min-width: 300px; width: 200px; font-family: 'Public Sans'; font-size: 16px; line-height: 70px; text-align: left;">
                <div style="width: 200px; height: 40px;"><ProgressBar :value="repondent.response?.progress()"></ProgressBar></div>
              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>


</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import {Company} from "@/data/Company";
import {Application} from "@/data/Application";
import IciButton from "@/components/IciButton.vue";
import {User} from "@/data/User";
import router from "@/router";
import {useRouter} from "vue-router";
import DashboardButton from "@/components/button/DashboardButton.vue";
import ResultButton from "@/components/button/ResultButton.vue";
import SettingsButton from "@/components/button/SettingsButton.vue";
import IciStatus from "@/components/IciStatus.vue";
import {QuestionnaireState} from "@/data/QuestionnaireState";
import {Questionnaire} from "@/data/Questionnaire";
import {AuthService} from "@/service/AuthService";

export default defineComponent({
  components: {IciStatus, SettingsButton, ResultButton, DashboardButton, IciButton},
  computed: {
    QuestionnaireState() {
      return QuestionnaireState
    },
    Company() {
      return Company
    },
    Application() {
      return Application
    }
  },
  data() {
    return {
      hover: false,
      buttonAddProject: "<i class='pi pi-plus' style='font-size: 1rem'></i><label style='font-weight: bold;'>$label</label>",
      buttonBackToAllClients: "<i class='pi pi-chevron-left' style='font-size: 1rem'></i><label>$label</label>",
      buttonSaveContent: "<label>$label</label><i class='pi pi-chevron-right' style='font-size: 1rem'></i>",
      selectedClient: this.getCompany()
    }

  },
  methods: {
    constructLabel: (content: String, label: string) => {
      return content.replace("$label", label);
    },
    getCompany: ():Company|null => {
      let id: Number = Number(router.currentRoute.value.params.id);
      if(id == 0) {
        return Company.build(0, "", 0, 0, 0, 0, "");
      } else {
        return User.get().getCompany(id);
      }
    },
    backToClientDashboard: (id: Number) => {
      // @ts-ignore
      router.push("/client/" + Number(router.currentRoute.value.params.id) + "/dashboard");
    },
    gotoResults: () => {
      router.push("/client/" + Number(router.currentRoute.value.params.id) + "/project/"  + Number(router.currentRoute.value.params.qid) +  "/results-dashboard");
    },
    gotoSetting: () => {

    },
    projectDetails: (id: number | null) => {

    },
    addProject: () => {

    }
  },
  setup(props) {
    AuthService.validate();
  },
});
</script>
<style scoped>
.content-respondent-dashboard {
  font-family: "Public Sans";
  position: absolute;
  top: 130px;
  left: 60px !important;
  bottom: 0;
  right: 0;
  padding-left: 50px;
}
.welcome-title-respondent-dashboard {
  font-family: "Public Sans";
  font-size: 40px;
  font-weight: bold;
  text-align: left;
}
.respondent-dashboard-block {
  position: absolute;
  top: 80px;
  right: 65px;
  left: 0px;
  bottom: 0px;
  background-color: #ffffff;
  border-radius: 10px !important;
}

.p-progressbar {
  font-family: "Public Sans";
  top: 30px !important;
  left: 0px !important;
  width: 250px !important;
  height: 16px;
}

.back-to-all-clients {
  margin-top: 0px;
  margin-right: 50px;
}
</style>
