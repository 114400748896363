import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/buttons/svg/logout.svg'
import _imports_1 from '../../assets/buttons/svg/logout-hover.svg'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  width: "42"
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  height: "42",
  width: "42"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hover = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hover = false)),
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClick())),
    class: "button-default header-button"
  }, {
    default: _withCtx(() => [
      (!_ctx.hover)
        ? (_openBlock(), _createElementBlock("img", _hoisted_1))
        : (_openBlock(), _createElementBlock("img", _hoisted_2))
    ]),
    _: 1
  }))
}