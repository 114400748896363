import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import QuestionnaireView from "@/views/QuestionnaireView.vue";
import ProfileView from "@/views/ProfileView.vue";
import ResultsDashboard from "@/views/ResultsDashboard.vue";
import ResultsDashboard2 from "@/views/ResultsDashboard2.vue";
import EnvironmentalPressure from "@/views/EnvironmentalPressure.vue";
import ClientDetails from "@/views/ClientDetailsView.vue";
import ClientDetailsView from "@/views/ClientDetailsView.vue";
import ClientDashboardView from "@/views/ClientDashboardView.vue";
import ProjectDashboardView from "@/views/ProjectDashboardView.vue";
import ProjectRespondensDashboardView from "@/views/ProjectRespondensDashboardView.vue";
import ProjectDetailsView from "@/views/ProjectDetailsView.vue";
import DeviceNotAvailable from "@/views/DeviceNotAvailable.vue";
import LoginView from "@/views/LoginView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/questionnaire',
    name: 'questionnaire',
    component: QuestionnaireView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path:'/client/:id/project/:qid/results-dashboard',
    name: 'results',
    component:ResultsDashboard
  },
  {
    path:'/client/:id',
    name: 'client',
    component: ClientDetailsView
  },
  {
    path:'/client/:id/dashboard',
    name: 'clientDashboard1',
    component: ClientDashboardView
  },
  {
    path:'/device-not-available',
    name:'deviceNotAvailable',
    component:DeviceNotAvailable
  },
  {
    path:'/client/:id/project/:qid',
    name: 'projectDashboard2',
    // component: ProjectDashboardView
    component: ProjectDetailsView
  },
  {
    path:'/client/:id/project/:qid/dashboard',
    name: 'projectDashboard3',
    // component: ProjectDashboardView
    component: ProjectDashboardView
  },
  {
    path:'/client/:id/project/new',
    name: 'projectDetails',
    component: ProjectDetailsView
  },
  {
    path:'/client/:id/project/:qid/respondents/dashboard',
    name: 'projectRespondentDashboard',
    component: ProjectRespondensDashboardView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
