<template>
  <ItemBox id="item-n-3" :visible="visible" title="Innovation Capability Index 1" :headerItems="headerItems"
    toggleClass="item3-toggle minus" :closeAction="closeItem" :graphViewClick="graphViewClick"
    :graphToggle="graphToggle">

<!--  -->
      <template v-if="graphToggle">
<div class="pt-4 col-span-12">
        <div class="featured grid col-span-12 flex flex-col gap-2 px-3">
              <div class="f-data grid grid-cols-12 gap-2">

                <div class="ici1ValueContainer grid grid-cols-10 col-span-10 h-96 overflow-y-auto gap-2">

                  <!-- Enabler 1 -->
                  <div class="col-span-10 grid grid-cols-10 gap-2 m-2">

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C1</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.nc_RELATIONSHIPS }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#fbfaff] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.nc_RELATIONSHIPS)"></div>
                    </div>

                    <div class="flex col-span-1 row-span-5 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">1</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 row-span-5 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <div class="flex border rating border-2 py-1 px-1.5 pb-1.5 border-[#d4d4d4] rounded-lg leading-3 justify-center">
                          {{ this.questionnaireResults.nc_CHARACTERISTICS }}
                        </div>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C2</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.nc_PERFORMANCE }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#fbfaff] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.nc_PERFORMANCE)"></div>
                    </div>

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C3</h4>
                      </div>
                    </div>
                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.nc_PROCESS }}</h4>
                      </div>
                    </div>
                    <div class="flex flex-col h-full bg-[#fbfaff] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.nc_PROCESS)"></div>
                    </div>

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C4</h4>
                      </div>
                    </div>
                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.nc_KNOWLEDGE }}</h4>
                      </div>
                    </div>
                    <div class="flex flex-col h-full bg-[#fbfaff] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full">
                        <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.nc_KNOWLEDGE)"></div>
                      </div>
                    </div>
                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C5</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.nc_CONNECT }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#fbfaff] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full">
                        <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.nc_CONNECT)"></div>
                      </div>
                    </div>

                  </div>

                  <!-- Enabler 2 -->
                  <div class="col-span-10 grid grid-cols-10 gap-2 m-1">

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C6</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.og_EMPOWERMENT }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#f3f4f8] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.og_EMPOWERMENT)"></div>
                    </div>

                    <div class="flex col-span-1 row-span-3 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">2</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 row-span-3 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <div class="flex border rating border-2 py-1 px-1.5 pb-1.5 border-[#d4d4d4] rounded-lg leading-3 justify-center">
                          {{ this.questionnaireResults.nc_ORGANIZATIONAL_GOVERNANCE }}
                        </div>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C7</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.og_PERFORMANCE }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#f3f4f8] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.og_PERFORMANCE)"></div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C8</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.og_BUGETING }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#f3f4f8] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.og_BUGETING)"></div>
                    </div>

                  </div>

                  <!-- ^3 -->
                  <div class="col-span-10 grid grid-cols-10 gap-2 m-1">

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C9</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.oa_PROCESS }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#fbfaff] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.oa_PROCESS)"></div>
                    </div>

                    <div class="flex col-span-1 row-span-3 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">3</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 row-span-3 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <div class="flex border rating border-2 py-1 px-1.5 pb-1.5 border-[#d4d4d4] rounded-lg leading-3 justify-center">
                          {{ this.questionnaireResults.nc_ORGANIZATIONAL_ARCHITECTURE }}
                        </div>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C10</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.oa_MODULARITY }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#fbfaff] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.oa_MODULARITY)"></div>
                    </div>
                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C11</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.oa_CUSTOMIZATION }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#fbfaff] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.oa_CUSTOMIZATION)"></div>
                    </div>

                  </div>

                  <!-- Enabler 4 -->
                  <div class="col-span-10 grid grid-cols-10 gap-2 m-1">

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C12</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.ti_CONNECTIVITY}}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#f3f4f8] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.ti_CONNECTIVITY)"></div>
                    </div>

                    <div class="flex col-span-1 row-span-5 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">4</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 row-span-5 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <div class="flex border rating border-2 py-1 px-1.5 pb-1.5 border-[#d4d4d4] rounded-lg leading-3 justify-center">
                          {{ this.questionnaireResults.nc_TECHNICALIT_INFRASTRUCTURE }}
                        </div>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C13</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.ti_COMPATIBILITY}}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#f3f4f8] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.ti_COMPATIBILITY)"></div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C14</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.ti_MODULARITY }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#f3f4f8] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.ti_MODULARITY)"></div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C15</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.ti_REUSABILITY }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#f3f4f8] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.ti_REUSABILITY)"></div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C16</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.ti_DATASHARING }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#f3f4f8] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.ti_DATASHARING)"></div>
                    </div>

                  </div>

                  <!-- Enabler 5 -->
                  <div class="col-span-10 grid grid-cols-10 gap-2 m-1">

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C17</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.hi_TECHNOLOGY }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#fbfaff] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.hi_TECHNOLOGY)"></div>
                    </div>

                    <div class="flex col-span-1 row-span-3 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">5</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 row-span-3 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <div class="flex border rating border-2 py-1 px-1.5 pb-1.5 border-[#d4d4d4] rounded-lg leading-3 justify-center">
                          {{ this.questionnaireResults.hi_HUMANIT_INFRASTRUCTURE }}
                        </div>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C18</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.hi_MANAGEMENT_SKILLS }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#fbfaff] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.hi_MANAGEMENT_SKILLS)"></div>
                    </div>
                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C19</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.hi_TECHNICAL_SKILLS }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#fbfaff] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.hi_TECHNICAL_SKILLS)"></div>
                    </div>

                  </div>

                  <!-- Enabler 6 -->
                  <div class="col-span-10 grid grid-cols-10 gap-2 m-1">

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C20</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.oc_LEADERSHIP }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#f3f4f8] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.oc_LEADERSHIP)"></div>
                    </div>

                    <div class="flex col-span-1 row-span-3 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">6</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 row-span-3 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <div class="flex border rating border-2 py-1 px-1.5 pb-1.5 border-[#d4d4d4] rounded-lg leading-3 justify-center">
                          {{ this.questionnaireResults.hi_ORGANIZATIONAL_CULTURE }}
                        </div>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C21</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.oc_FOSTERING }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#f3f4f8] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.oc_FOSTERING)"></div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C22</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.oc_INTERACTIONS }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#f3f4f8] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.oc_INTERACTIONS)"></div>
                    </div>

                  </div>

                  <!-- Enabler 7 -->
                  <div class="col-span-10 grid grid-cols-10 gap-2 m-1">

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C23</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.hs_KNOWLEDGE }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#fbfaff] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.hs_KNOWLEDGE)"></div>
                    </div>

                    <div class="flex col-span-1 row-span-2 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">7</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 row-span-2 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <div class="flex border rating border-2 py-1 px-1.5 pb-1.5 border-[#d4d4d4] rounded-lg leading-3 justify-center">
                          {{ this.questionnaireResults.hi_HUMAN_KNOWLEDGE }}
                        </div>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="text-start leading-4">C24</h4>
                      </div>
                    </div>

                    <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                      <div class="flex justify-center w-full">
                        <h4 class="resultNumValue text-start leading-4">{{ this.questionnaireResults.hs_SPEED_ACQ }}</h4>
                      </div>
                    </div>

                    <div class="flex flex-col h-full bg-[#fbfaff] rounded-full col-span-6 gap-2">
                      <div class="resultProgressBar rounded-full h-full barTransition" :style="calculateBorder(this.questionnaireResults.hs_SPEED_ACQ)"></div>
                    </div>

                  </div>


                </div>

                <div class="grid grid-cols-2 col-span-2 gap-2 mr-0.5 ml-2 pt-1 text-sm">

                  <div class="flex col-span-1 bg-[#fbfaff] rounded-xl py-1 px-4 items-center justify-between">
                    <div class="flex w-full justify-center">
                      <h4 class="text-center">Network Characteristics</h4>
                    </div>
                  </div>

                  <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-center">
                    <div class="flex justify-center">
                      <div
                          class="flex border rating border-2 px-4 py-5 border-[#82ca74] rounded-xl leading-4 w-full justify-center">
                        {{ this.questionnaireResults.nc_CHARACTERISTICS }}
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
      </template>


      <div v-else class="featured grid col-span-12 flex flex-col gap-2">
        <div class="f-graph grid grid-cols-12 gap-2">

          <GraphIci :bgLines="true" :circles="circles" :hoveredIndex="hoveredIndex"
            :setHoveredIndex="setHoveredIndex" />


          <div class="flex col-span-2 pt-16 pr-12 font-semibold">


            <div class="">
              <div class=" shrink-0 grow-0  flex h-5 w-5  rounded-full border-2 border-black">
                <p class="invisible">helolo</p>
              </div>
            </div>
            <p class="uppercase text-lg leading-5 text-start">Position of enabler if aligned</p>




          </div>

          <div class="flex flex-col gap-2 col-span-4 py-4">

            <div class="flex justify-between border-2 border-[#aedddb] rounded-xl p-2 pl-3 w-full h-fit">
              <h4 class="leading-6">Category</h4>
              <div class="flex border-2 border-[#aedddb] rounded-lg p-1 leading-4">Mean</div>
            </div>

            <div v-for="(category, index) in categories" :key="index"
              class="flex justify-between rounded-xl p-2 pl-3 w-full h-fit  duration-150 ease-in-out"
              @mouseover="setHoveredIndex(index)" @mouseleave="setHoveredIndex(null)"
              :class="hoveredIndex === index ? 'bg-[#aedddb]' : 'bg-[#fbfaff]'">
              <h4 class="leading-6">{{ category.name }}</h4>
              <div class="flex border-2 rating rounded-lg p-1 leading-4"
                :style="calculateColor(category.mean, hoveredIndex === index)">
                {{ category.mean == '1' ? '1.00' : category.mean }}
              </div>
            </div>
          </div>

        </div>
      </div>
  </ItemBox>
</template>

<script>
import ItemBox from '@/components/results/ItemBox.vue'
import GraphCircles from '@/components/results/GraphCircles.vue'
import GraphIci from '@/components/results/GraphIci.vue'


export default {
  components: {
    ItemBox, GraphCircles, GraphIci
  },
  data() {
    return {
      hoveredIndex: null,
      headerItems: [
        { name: 'Criterion #', class: 'col-span-1' },
        { name: 'Mean', class: 'col-span-1' },
        { name: 'Scale', class: 'col-span-6', scale: true },

        { name: 'Enabler #', class: 'col-span-1' },
        { name: 'Mean', class: 'col-span-1' },
        { name: 'Mean Alignment', class: 'col-span-1' },
        { name: 'IC1', class: 'col-span-1 ', color: 'border border-[#aedddb] bg-white' },
      ],
      circles: [
        { cx: 600, cy: 600, r: 87.64, fill: '#82CA74', rotation: 0, translateY: -323.5, strokeTranslateY: -222 },
        { cx: 600, cy: 600, r: 73, fill: '#D4D4D4', rotation: 51.4286, translateY: -262.5, strokeTranslateY: -222 },
        { cx: 600, cy: 600, r: 85.6, fill: '#82CA74', rotation: 102.857, translateY: -315, strokeTranslateY: -222 },
        { cx: 600, cy: 600, r: 60.04, fill: '#F8D25B', rotation: 154.286, translateY: -208.5, strokeTranslateY: -222 },
        { cx: 600, cy: 600, r: 45.04, fill: '#F07620', rotation: 205.714, translateY: -146, strokeTranslateY: -222 },
        { cx: 600, cy: 600, r: 36.64, fill: '#F07620', rotation: 257.143, translateY: -111, strokeTranslateY: -222 },
        { cx: 600, cy: 600, r: 55, fill: '#F4A14A', rotation: 308.571, translateY: -187.5, strokeTranslateY: -222 }
      ]
    }
  },
  computed: {
    enablers() {
      return [
        {
          enabler: 1,
          mean: this.questionnaireResults.nc_CHARACTERISTICS,
          criterions: [{
            criterion: 'C1',
            mean: this.questionnaireResults.nc_RELATIONSHIPS
          },
          {
            criterion: 'C2',
            mean: this.questionnaireResults.nc_PERFORMANCE
          },
          {
            criterion: 'C3',
            mean: this.questionnaireResults.nc_PROCESS
          }, {
            criterion: 'C4',
            mean: this.questionnaireResults.nc_KNOWLEDGE
          },
          {
            criterion: 'C5',
            mean: this.questionnaireResults.nc_CONNECT
          },
          ]
        },
        {
          enabler: 2,
          mean: this.questionnaireResults.nc_ORGANIZATIONAL_GOVERNANCE,
          criterions: [{
            criterion: 'C6',
            mean: this.questionnaireResults.og_EMPOWERMENT
          },
          {
            criterion: 'C7',
            mean: this.questionnaireResults.nc_PERFORMANCE
          },
          {
            criterion: 'C8',
            mean: this.questionnaireResults.og_BUGETING
          }
          ]
        },
        {
          enabler: 3,
          mean: this.questionnaireResults.nc_ORGANIZATIONAL_ARCHITECTURE,
          criterions: [{
            criterion: 'C9',
            mean: this.questionnaireResults.oa_PROCESS
          },
          {
            criterion: 'C10',
            mean: this.questionnaireResults.oa_MODULARITY
          },
          {
            criterion: 'C11',
            mean: this.questionnaireResults.oa_CUSTOMIZATION
          }
          ]
        },
        {
          enabler: 4,
          mean: this.questionnaireResults.nc_TECHNICALIT_INFRASTRUCTURE,
          criterions: [{
            criterion: 'C12',
            mean: this.questionnaireResults.ti_CONNECTIVITY
          },
          {
            criterion: 'C13',
            mean: this.questionnaireResults.ti_COMPATIBILITY
          },
          {
            criterion: 'C14',
            mean: this.questionnaireResults.ti_MODULARITY
          },
          {
            criterion: 'C15',
            mean: this.questionnaireResults.ti_REUSABILITY
          },
          {
            criterion: 'C16',
            mean: this.questionnaireResults.ti_DATASHARING
          }
          ]
        },
        {
          enabler: 5,
          mean: this.questionnaireResults.hi_HUMANIT_INFRASTRUCTURE,
          criterions: [{
            criterion: 'C17',
            mean: this.questionnaireResults.hi_TECHNOLOGY
          },
          {
            criterion: 'C18',
            mean: this.questionnaireResults.hi_MANAGEMENT_SKILLS
          },
          {
            criterion: 'C19',
            mean: this.questionnaireResults.hi_TECHNICAL_SKILLS
          }
          ]
        },
        {
          enabler: 6,
          mean: this.questionnaireResults.hi_ORGANIZATIONAL_CULTURE,
          criterions: [{
            criterion: 'C20',
            mean: this.questionnaireResults.oc_LEADERSHIP
          },
          {
            criterion: 'C21',
            mean: this.questionnaireResults.oc_FOSTERING
          },
          {
            criterion: 'C22',
            mean: this.questionnaireResults.oc_INTERACTIONS
          }
          ]
        },
        {
          enabler: 7,
          mean: this.questionnaireResults.hi_HUMAN_KNOWLEDGE,
          criterions: [{
            criterion: 'C23',
            mean: this.questionnaireResults.hs_KNOWLEDGE
          },
          {
            criterion: 'C24',
            mean: this.questionnaireResults.hs_SPEED_ACQ
          },
          ]
        }
      ]
    },
    categories() {
      return [
        {
          name: 'Network Characteristics',
          mean: this.questionnaireResults.nc_CHARACTERISTICS
        },
        {
          name: 'Organisational Governance',
          mean: this.questionnaireResults.nc_ORGANIZATIONAL_GOVERNANCE
        },
        {
          name: 'Organisational Architecture',
          mean: this.questionnaireResults.nc_ORGANIZATIONAL_ARCHITECTURE
        },
        {
          name: 'Technical IT Infrastructure',
          mean: this.questionnaireResults.nc_TECHNICALIT_INFRASTRUCTURE
        },
        {
          name: 'Human IT Infrastructure',
          mean: this.questionnaireResults.hi_HUMANIT_INFRASTRUCTURE
        },
        {
          name: 'Organisational Culture',
          mean: this.questionnaireResults.hi_ORGANIZATIONAL_CULTURE
        },
        {
          name: 'Human Knowledge and Skills',
          mean: this.questionnaireResults.hi_HUMAN_KNOWLEDGE
        }
      ];
    }
  },
  props: {
    graphViewClick: {
      type: Boolean,
      required: false
    },
    graphToggle: {
      type: Boolean,
      required: false
    },
    calculateBorder: {
      type: Function,
      required: true
    },
    calculateColor: {
      type: Function,
      required: true
    },
    questionnaireResults: {
      type: Array,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    closeAction: {
      type: Function,
      required: true
    },
    getBgClass: {
      type: Function,
      required: true
    }
  },
  methods: {
    closeItem() {
      this.closeAction();
    },
    setHoveredIndex(index) {
      this.hoveredIndex = index;
    }
  }
};
</script>

<style scoped>
/* Inner shadow using filter */
.shadow-inner {
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.5));
}
</style>