import {Company} from "@/data/Company";
import {QuestionnaireState} from "@/data/QuestionnaireState";
import {Respondent} from "@/data/Respondent";
import axios from "axios";
import {Application} from "@/data/Application";
import {User} from "@/data/User";

export class Questionnaire {

    public id: number|null = null;
    public year: number|null = null;
    public name: string|null = null;
    public description: string|null = null;
    public createdDt: Date|null = null;
    public active: boolean = false;
    public status: number = QuestionnaireState.UNKNOWN;
    public deadline: Date = new Date();
    public deadlineFormatted: string = 'n/a';
    public respondents: Respondent[] = [];

    company: Company = new Company();

    public static getQuestionnaire = (id: number, target: any): Questionnaire => {
        // http://localhost:1000/api/questionnaire/1
        axios.get(Application.instance.apiEndPoint + '/questionnaire/' + id)
            .then(response => {
                console.log(response);
                let q = new Questionnaire();
                q.id = response.data.id;
                q.active = response.data.active;
                q.year = response.data.year;
                q.deadline = response.data.deadline;
                q.name = response.data.name;
                q.description = response.data.description;
                target.questionnaire = q;
            })
            .catch(err => {
                console.log(err);
            });
        return new Questionnaire();
    }

    public static build = (id: number,
                           year: number,
                           name: string,
                           description: string,
                           createdDt: Date,
                           active: boolean,
                           deadline: Date):Questionnaire => {
        let quest = new Questionnaire();
        quest.id = id;
        quest.year = year;
        quest.name = name;
        quest.description = description;
        quest.createdDt = createdDt;
        quest.active = active;
        quest.deadline = deadline;
        return quest;
    }

    public save = (client: number | null | undefined, callback: any) => {
        axios.post(Application.instance.apiEndPoint + '/questionnaire/'+client+"/save", this)
            .then(response => {
               console.log(response);
                callback({ok: true, message: "success"});
            })
            .catch(err => {
                if(err.response.status) {
                    console.log(err.response.data.message);
                    callback({ok: false, message: err.response.data.message});
                }
            });
    }
}
