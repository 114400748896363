<!--Client Details View-->
<!--Client Details View-->
<template>
  <div class="client-details-content">
    <div class="flex" style="align-items: center; justify-content: space-between;margin-left:30px;">
      <div class="welcome-title-consultant-client-details">
        <span v-if="selectedClient.id != 0" v-html="selectedClient.name" />
        <span v-if="selectedClient.id == 0" v-html="$t('companyForm.titleAddNewClient')" />
      </div>

      <div class="back-to-all-clients-client-details">
        <IciButton id="backToAllClients"
                   :content="constructLabel(buttonBackToAllClients, $t('companyForm.buttonBackToAllClients'))"
                   @buttonClick="backToAllClients()" btnStyle="width: fit-content; height: auto; padding: 18px 24px!important; top: 32px; position: static;"></IciButton>
      </div>
    </div>

    <div class="client-details-block">
      <div style="position: relative; width: 100%; height: 100%">
        <div style="position: absolute; top: 40px; left: 40px; right: 40px; height: 50px; border-radius: 10px; background-color: #fbfaff; font-family: 'Public Sans'; font-weight: bold; text-align: left; padding-left: 40px; line-height: 50px;" v-html="selectedClient.id == 0 ? 'Add client' : 'Edit client'"/>

        <div style="position:absolute; top: 140px; bottom: 100px; left: 40px; right: 40px; min-width: calc(100% - 110px); border-radius: 10px; min-height: 90px; background-color: #ffffff;">
          <div style="position: relative; width: 100%; height: 100%;">
            <div class="fields-block">
              <div class="flex" style="flex-direction: column; width: 100%; gap: 6px">
                <div class="label-client-name" v-html="$tc('companyForm.fieldLabelClientName')" />
                <div class="input-client-name">
                  <InputText type="text" v-model="selectedClient.name" class="field-client-name" />
                </div>
              </div>

              <div class="flex" style="gap: 24px">
                <div class="flex" style="flex-direction: column; width: 50%; gap: 6px">
                  <div class="label-business-activity" v-html="$tc('companyForm.fieldLabelBusinessActivity')" />
                  <div class="input-business-activity">
                    <Dropdown v-model="selectedClient.businessActivityId"
                              :options="Application.instance.businessActivityItems" @change="event => { this.$forceUpdate() }"
                              option-value="id" optionLabel="name" :placeholder="$t('ddlSelectPlaceHolder')"
                              class="level-ddl field-business-activity" />
                  </div>
                </div>

                <div class="flex" style="flex-direction: column; width: 50%; gap: 6px">
                  <div class="label-number-of-employees" v-html="$tc('companyForm.fieldLabelNumberOfEmployees')" />
                  <div class="input-number-of-employees">
                    <Dropdown v-model="selectedClient.numberOfEmployeesId"
                              :options="Application.instance.nummberOfEmployeesItems" @change="event => { this.$forceUpdate() }"
                              option-value="id" optionLabel="name" :placeholder="$t('ddlSelectPlaceHolder')"
                              class="level-ddl field-number-of-employees" />
                  </div>
                </div>
              </div>

              <div class="flex" style="gap: 24px">
                <div class="flex" style="flex-direction: column; width: 50%; gap: 6px">
                  <div class="label-gross-revenue" v-html="$tc('companyForm.fieldLabelGrossRevenue')" />
                  <div class="input-gross-revenue">
                    <Dropdown v-model="selectedClient.grossRevenueId" :options="Application.instance.grossRevenueItems"
                              @change="event => { this.$forceUpdate() }" option-value="id" optionLabel="name"
                              :placeholder="$t('ddlSelectPlaceHolder')" class="level-ddl field-gross-revenue" />
                  </div>
                </div>

                <div class="flex" style="flex-direction: column; width: 50%; gap: 6px">
                  <div class="label-executive-report" v-html="$tc('companyForm.fieldLabelExecutiveReport')" />
                  <div class="input-executive-report">
                    <Dropdown v-model="selectedClient.executiveReportId"
                              :options="Application.instance.grossExecutiveReportItems" @change="event => { this.$forceUpdate() }"
                              option-value="id" optionLabel="name" :placeholder="$t('ddlSelectPlaceHolder')"
                              class="level-ddl field-executive-report" />
                  </div>
                </div>
              </div>

              <div class="client-form-save">
                <IciButton
                    id="saveClient"
                    :content="constructLabel(buttonSaveContent, $t('profileForm.submit'))"
                    @buttonClick="selectedClient.save((updatedClient: Company) => { gotoClientDashboard(updatedClient); })"
                    :disabled="!isFormValid"
                    btnStyle="width: fit-content; height: auto; padding: 18px 24px!important; top: 32px; position: static;"
                    :dark="false">
                </IciButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { Company } from "@/data/Company";
import { Application } from "@/data/Application";
import IciButton from "@/components/IciButton.vue";
import { User } from "@/data/User";
import router from "@/router";
import { AuthService } from "@/service/AuthService";

export default defineComponent({
  computed: {
    Application() {
      return Application
    }
  },
  components: { IciButton },
  setup() {
    AuthService.validate();

    const selectedClient = ref<Company | null>(null);

    const getCompany = (): Company | null => {
      const id = Number(router.currentRoute.value.params.id);
      if (id === 0) {
        return Company.build(0, "", 0, 0, 0, 0, "");
      } else {
        return User.get().getCompany(id);
      }
    };

    selectedClient.value = getCompany();

    const buttonBackToAllClients = "<i class='pi pi-chevron-left' style='font-size: 1rem'></i><label>$label</label>";
    const buttonSaveContent = "<label>$label</label><i class='pi pi-chevron-right' style='font-size: 1rem'></i>";

    const constructLabel = (content: String, label: string): string => {
      return content.replace("$label", label);
    };

    const backToAllClients = () => {
      router.push("/");
    };

    const gotoClientDashboard = (client: any) => {
      const clientId = client?.id || router.currentRoute.value.params.id;

      router.push("/client/" + Number(clientId) + "/dashboard");
    };

    const gotoResults = () => {
      router.push("/results-dashboard");
    };

    const gotoSetting = () => {
      router.push("/client/" + Number(router.currentRoute.value.params.id));
    };

    const isFormValid = computed(() => {
      return (
          selectedClient.value?.name &&
          selectedClient.value.businessActivityId &&
          selectedClient.value.numberOfEmployeesId &&
          selectedClient.value.grossRevenueId &&
          selectedClient.value.executiveReportId
      );
    });

    return {
      selectedClient,
      buttonBackToAllClients,
      buttonSaveContent,
      constructLabel,
      backToAllClients,
      gotoClientDashboard,
      gotoResults,
      gotoSetting,
      isFormValid,
    };
  },
});
</script>


<style scoped>
.client-details-content {
  font-family: "Public Sans";
  position: static;
  top: 0;
  left: 0 !important;
  bottom: 0;
  right: 0;
  padding-left: 0px;
}

.welcome-title-consultant-client-details {
  font-family: "Public Sans";
  font-size: 40px;
  font-weight: bold;
  text-align: left;
  padding-top: 110px;
  padding-left: 110px;
}

.client-details-block {
  position: absolute;
  top: 210px;
  left: 140px;
  max-height: 75%;
  width: calc(100% - 180px);
  bottom: 0px;
  background-color: #ffffff;
  border-radius: 10px !important;
}

.back-to-all-clients-client-details {
  margin-top: 130px;
  margin-right: 40px;
}

.fields-block {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-left:10px !important;
  padding-top:0 !important;
}

.client-form-save {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

</style>
