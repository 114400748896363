<template>
  <button @click="buttonClick()" class="button-default header-button" :class="{ 'selected': selected }">
    <span class="icon"></span>
  </button>
</template>

<script setup lang="ts">
const emit = defineEmits(['buttonClick'])

function buttonClick() {
  emit('buttonClick')
}

defineProps({
  selected: { type: Boolean, default: false }
})
</script>

<style scoped>
.button-default {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  position: relative;
  width: 42px;
  height: 42px;
}

.icon {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/buttons/svg/settings.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-image 0.3s ease;
}

.button-default:hover .icon {
  background-image: url('../../assets/buttons/svg/settings-hover.svg');
}

.button-default.selected .icon {
  background-image: url('../../assets/buttons/svg/settings-select.svg');
}

.button-default.selected:hover .icon {
  background-image: url('../../assets/buttons/svg/settings-hover.svg');
}

:focus, :focus-visible, *[data-focus] {
  box-shadow: none !important;
  outline: none !important;
  border-color: inherit !important;
}
</style>