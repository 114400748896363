<template>
  <div v-if="state == 8" class="status-label status-dark">Active</div>
  <div v-else-if="state == 9" class="status-label status-light">Draft</div>
  <div v-else-if="state == 10" class="status-label status-light">Expired</div>
  <div v-else-if="state == 11" class="status-label status-light">Closed</div>


  <div v-else-if="state == 1" class="status-label status-light">Created</div>
  <div v-else-if="state == 2" class="status-label status-light">Invited</div>
  <div v-else-if="state == 3" class="status-label status-light">Failed to send invitation</div>
  <div v-else-if="state == 4" class="status-label status-light">Invitation accepted</div>
  <div v-else-if="state == 5" class="status-label status-light">Registered</div>
  <div v-else-if="state == 6" class="status-label status-light" style="width: 200px">Questionaire started</div>
  <div v-else-if="state == 7" class="status-label status-light">Questionaire submitted</div>
  <div v-else  class="status-label">N/A</div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    state: {
      type: Number
    }
  },
  setup(props) {
  },
});
</script>
<style scoped>
  .status-label {
    width: fit-content;
    height: fit-content;
    line-height: 1;
    font-size: 14px;
    border-radius: 32px;
    border-width: 1px;
    border-style: solid;
    font-family: 'Public Sans';
    text-align: center;
    padding: 8px 16px;
    font-weight: 700;
    text-transform: uppercase;    
    border-color: #0d3d52;
    border-color: #b8b8b8;
    color: #000
  }
  .status-dark {
    color: #ffffff;
    background-color: #0d3d52;
  }
  .status-light {
    color: #0d3d52;
    background-color: #ffffff;
    border-color: #0d3d52;
  }
</style>
