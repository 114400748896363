import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27f87860"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "client-details-content" }
const _hoisted_2 = {
  class: "flex",
  style: {"align-items":"center","justify-content":"space-between","margin-left":"30px"}
}
const _hoisted_3 = { class: "welcome-title-consultant-client-details" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "back-to-all-clients-client-details" }
const _hoisted_7 = { class: "client-details-block" }
const _hoisted_8 = { style: {"position":"relative","width":"100%","height":"100%"} }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { style: {"position":"absolute","top":"140px","bottom":"100px","left":"40px","right":"40px","min-width":"calc(100% - 110px)","border-radius":"10px","min-height":"90px","background-color":"#ffffff"} }
const _hoisted_11 = { style: {"position":"relative","width":"100%","height":"100%"} }
const _hoisted_12 = { class: "fields-block" }
const _hoisted_13 = {
  class: "flex",
  style: {"flex-direction":"column","width":"100%","gap":"6px"}
}
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "input-client-name" }
const _hoisted_16 = {
  class: "flex",
  style: {"gap":"24px"}
}
const _hoisted_17 = {
  class: "flex",
  style: {"flex-direction":"column","width":"50%","gap":"6px"}
}
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "input-business-activity" }
const _hoisted_20 = {
  class: "flex",
  style: {"flex-direction":"column","width":"50%","gap":"6px"}
}
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = { class: "input-number-of-employees" }
const _hoisted_23 = {
  class: "flex",
  style: {"gap":"24px"}
}
const _hoisted_24 = {
  class: "flex",
  style: {"flex-direction":"column","width":"50%","gap":"6px"}
}
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = { class: "input-gross-revenue" }
const _hoisted_27 = {
  class: "flex",
  style: {"flex-direction":"column","width":"50%","gap":"6px"}
}
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = { class: "input-executive-report" }
const _hoisted_30 = { class: "client-form-save" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IciButton = _resolveComponent("IciButton")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.selectedClient.id != 0)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              innerHTML: _ctx.selectedClient.name
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.selectedClient.id == 0)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              innerHTML: _ctx.$t('companyForm.titleAddNewClient')
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_IciButton, {
          id: "backToAllClients",
          content: _ctx.constructLabel(_ctx.buttonBackToAllClients, _ctx.$t('companyForm.buttonBackToAllClients')),
          onButtonClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backToAllClients())),
          btnStyle: "width: fit-content; height: auto; padding: 18px 24px!important; top: 32px; position: static;"
        }, null, 8, ["content"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", {
          style: {"position":"absolute","top":"40px","left":"40px","right":"40px","height":"50px","border-radius":"10px","background-color":"#fbfaff","font-family":"'Public Sans'","font-weight":"bold","text-align":"left","padding-left":"40px","line-height":"50px"},
          innerHTML: _ctx.selectedClient.id == 0 ? 'Add client' : 'Edit client'
        }, null, 8, _hoisted_9),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", {
                  class: "label-client-name",
                  innerHTML: _ctx.$tc('companyForm.fieldLabelClientName')
                }, null, 8, _hoisted_14),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_InputText, {
                    type: "text",
                    modelValue: _ctx.selectedClient.name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedClient.name) = $event)),
                    class: "field-client-name"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", {
                    class: "label-business-activity",
                    innerHTML: _ctx.$tc('companyForm.fieldLabelBusinessActivity')
                  }, null, 8, _hoisted_18),
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_component_Dropdown, {
                      modelValue: _ctx.selectedClient.businessActivityId,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedClient.businessActivityId) = $event)),
                      options: _ctx.Application.instance.businessActivityItems,
                      onChange: _cache[3] || (_cache[3] = event => { this.$forceUpdate() }),
                      "option-value": "id",
                      optionLabel: "name",
                      placeholder: _ctx.$t('ddlSelectPlaceHolder'),
                      class: "level-ddl field-business-activity"
                    }, null, 8, ["modelValue", "options", "placeholder"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", {
                    class: "label-number-of-employees",
                    innerHTML: _ctx.$tc('companyForm.fieldLabelNumberOfEmployees')
                  }, null, 8, _hoisted_21),
                  _createElementVNode("div", _hoisted_22, [
                    _createVNode(_component_Dropdown, {
                      modelValue: _ctx.selectedClient.numberOfEmployeesId,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedClient.numberOfEmployeesId) = $event)),
                      options: _ctx.Application.instance.nummberOfEmployeesItems,
                      onChange: _cache[5] || (_cache[5] = event => { this.$forceUpdate() }),
                      "option-value": "id",
                      optionLabel: "name",
                      placeholder: _ctx.$t('ddlSelectPlaceHolder'),
                      class: "level-ddl field-number-of-employees"
                    }, null, 8, ["modelValue", "options", "placeholder"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", {
                    class: "label-gross-revenue",
                    innerHTML: _ctx.$tc('companyForm.fieldLabelGrossRevenue')
                  }, null, 8, _hoisted_25),
                  _createElementVNode("div", _hoisted_26, [
                    _createVNode(_component_Dropdown, {
                      modelValue: _ctx.selectedClient.grossRevenueId,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedClient.grossRevenueId) = $event)),
                      options: _ctx.Application.instance.grossRevenueItems,
                      onChange: _cache[7] || (_cache[7] = event => { this.$forceUpdate() }),
                      "option-value": "id",
                      optionLabel: "name",
                      placeholder: _ctx.$t('ddlSelectPlaceHolder'),
                      class: "level-ddl field-gross-revenue"
                    }, null, 8, ["modelValue", "options", "placeholder"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("div", {
                    class: "label-executive-report",
                    innerHTML: _ctx.$tc('companyForm.fieldLabelExecutiveReport')
                  }, null, 8, _hoisted_28),
                  _createElementVNode("div", _hoisted_29, [
                    _createVNode(_component_Dropdown, {
                      modelValue: _ctx.selectedClient.executiveReportId,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedClient.executiveReportId) = $event)),
                      options: _ctx.Application.instance.grossExecutiveReportItems,
                      onChange: _cache[9] || (_cache[9] = event => { this.$forceUpdate() }),
                      "option-value": "id",
                      optionLabel: "name",
                      placeholder: _ctx.$t('ddlSelectPlaceHolder'),
                      class: "level-ddl field-executive-report"
                    }, null, 8, ["modelValue", "options", "placeholder"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_30, [
                _createVNode(_component_IciButton, {
                  id: "saveClient",
                  content: _ctx.constructLabel(_ctx.buttonSaveContent, _ctx.$t('profileForm.submit')),
                  onButtonClick: _cache[10] || (_cache[10] = ($event: any) => {_ctx.selectedClient.save((updatedClient) => { _ctx.gotoClientDashboard(updatedClient); })}),
                  disabled: !_ctx.isFormValid,
                  btnStyle: "width: fit-content; height: auto; padding: 18px 24px!important; top: 32px; position: static;",
                  dark: false
                }, null, 8, ["content", "disabled"])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}