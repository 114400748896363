<template>
  <Button @mouseover="hover = true" @mouseleave="hover = false" @click="buttonClick()"
          class="button-default header-button" style="width: 97px;">
    <img v-if="!hover && !selected" src="../../assets/buttons/svg/graph.svg" height="42" width="42"/>
    <img v-else-if="!hover && selected" src="../../assets/buttons/svg/graph-select.svg" height="42" width="42"/>
    <img v-else src="../../assets/buttons/svg/graph-hover.svg" height="42" width="42"/>
  </Button>
</template>
<script setup lang="ts">
const emit = defineEmits(['buttonClick', 'submit'])

function buttonClick() {
  emit('buttonClick')
}
defineProps({
  selected: { type: Boolean },
  hover: { type: Boolean, default: false }
})
</script>

<style scoped>
:focus, :focus-visible, *[data-focus] {
  box-shadow: none !important;
  outline: none !important;
  border-color: inherit !important;
}
</style>
