export class LanguageModel {
    public code :string = '';
    public name :string = '';

    public static create = (code: string, name: string) => {
        let model = new LanguageModel();
        model.code = code;
        model.name = name;
        return model;
    }
}
