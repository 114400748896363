import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-071be7e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "status-label status-dark"
}
const _hoisted_2 = {
  key: 1,
  class: "status-label status-light"
}
const _hoisted_3 = {
  key: 2,
  class: "status-label status-light"
}
const _hoisted_4 = {
  key: 3,
  class: "status-label status-light"
}
const _hoisted_5 = {
  key: 4,
  class: "status-label status-light"
}
const _hoisted_6 = {
  key: 5,
  class: "status-label status-light"
}
const _hoisted_7 = {
  key: 6,
  class: "status-label status-light"
}
const _hoisted_8 = {
  key: 7,
  class: "status-label status-light"
}
const _hoisted_9 = {
  key: 8,
  class: "status-label status-light"
}
const _hoisted_10 = {
  key: 9,
  class: "status-label status-light",
  style: {"width":"200px"}
}
const _hoisted_11 = {
  key: 10,
  class: "status-label status-light"
}
const _hoisted_12 = {
  key: 11,
  class: "status-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.state == 8)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Active"))
    : (_ctx.state == 9)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Draft"))
      : (_ctx.state == 10)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Expired"))
        : (_ctx.state == 11)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Closed"))
          : (_ctx.state == 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Created"))
            : (_ctx.state == 2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, "Invited"))
              : (_ctx.state == 3)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, "Failed to send invitation"))
                : (_ctx.state == 4)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, "Invitation accepted"))
                  : (_ctx.state == 5)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, "Registered"))
                    : (_ctx.state == 6)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, "Questionaire started"))
                      : (_ctx.state == 7)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, "Questionaire submitted"))
                        : (_openBlock(), _createElementBlock("div", _hoisted_12, "N/A"))
}