import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = {
  class: "flex",
  style: {"justify-content":"space-between","align-items":"center"}
}
const _hoisted_3 = { class: "welcome-title" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "questionnaire-state-block" }
const _hoisted_7 = {
  class: "flex",
  style: {"flex-direction":"column","justify-content":"space-between","gap":"24px"}
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  class: "flex",
  style: {"flex-direction":"column"}
}
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 1,
  class: "status-value progress",
  innerHTML: '100%'
}
const _hoisted_12 = {
  class: "flex",
  style: {"flex-direction":"column","justify-content":"space-between","gap":"24px"}
}
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  class: "flex",
  style: {"flex-direction":"column"}
}
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = {
  key: 1,
  class: "status-value deadline",
  innerHTML: '-'
}
const _hoisted_17 = {
  class: "flex",
  style: {"flex-direction":"column","justify-content":"space-between","gap":"24px"}
}
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = {
  class: "flex",
  style: {"flex-direction":"column"}
}
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = {
  key: 1,
  class: "status-value my-response",
  innerHTML: 'Finished'
}
const _hoisted_22 = { class: "intro-block" }
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = { class: "intro-block-content" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = {
  key: 1,
  class: "block-size"
}
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = {
  key: 0,
  class: "button-block",
  style: {"display":"flex","justify-content":"flex-end"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IciButton = _resolveComponent("IciButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", {
            innerHTML: _ctx.$t('homeForm.welcome')
          }, null, 8, _hoisted_4),
          _createTextVNode("  "),
          _createElementVNode("span", {
            innerHTML: _ctx.User.get().profile.firstName
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: "status-title progress",
              innerHTML: _ctx.$t('homeForm.titleProgress')
            }, null, 8, _hoisted_8),
            _createElementVNode("div", _hoisted_9, [
              (_ctx.User.get().invitation.active && _ctx.User.get().invitation.state < _ctx.QuestionnaireState.QUESTIONNAIRE_SUBMITTED)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "status-value progress",
                    innerHTML: _ctx.User.get().invitation.response.progressAsString()
                  }, null, 8, _hoisted_10))
                : _createCommentVNode("", true),
              (!_ctx.User.get().invitation.active || _ctx.User.get().invitation.state == _ctx.QuestionnaireState.QUESTIONNAIRE_SUBMITTED)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", {
              class: "status-title deadline",
              innerHTML: _ctx.$t('homeForm.titleDeadline')
            }, null, 8, _hoisted_13),
            _createElementVNode("div", _hoisted_14, [
              (_ctx.User.get().invitation.active && _ctx.User.get().invitation.state < _ctx.QuestionnaireState.QUESTIONNAIRE_SUBMITTED)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "status-value deadline",
                    innerHTML: _ctx.User.get().invitation.response.deadline()
                  }, null, 8, _hoisted_15))
                : _createCommentVNode("", true),
              (!_ctx.User.get().invitation.active || _ctx.User.get().invitation.state == _ctx.QuestionnaireState.QUESTIONNAIRE_SUBMITTED)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", {
              class: "status-title my-response",
              innerHTML: _ctx.$t('homeForm.titleMyResponses')
            }, null, 8, _hoisted_18),
            _createElementVNode("div", _hoisted_19, [
              (_ctx.User.get().invitation.active && _ctx.User.get().invitation.state < _ctx.QuestionnaireState.QUESTIONNAIRE_SUBMITTED)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "status-value my-response",
                    innerHTML: _ctx.User.get().invitation.response.state()
                  }, null, 8, _hoisted_20))
                : _createCommentVNode("", true),
              (!_ctx.User.get().invitation.active || _ctx.User.get().invitation.state == _ctx.QuestionnaireState.QUESTIONNAIRE_SUBMITTED)
                ? (_openBlock(), _createElementBlock("div", _hoisted_21))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("div", {
        class: "app-title",
        innerHTML: _ctx.$t('homeForm.appTitle')
      }, null, 8, _hoisted_23),
      _createElementVNode("div", _hoisted_24, [
        (!_ctx.User.get().invitation.active || _ctx.User.get().invitation.state == _ctx.QuestionnaireState.QUESTIONNAIRE_SUBMITTED)
          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
              _createElementVNode("span", {
                innerHTML: _ctx.$t('homeForm.contentNoOpenQuestionnaire')
              }, null, 8, _hoisted_26)
            ]))
          : _createCommentVNode("", true),
        (_ctx.User.get().invitation.active && _ctx.User.get().invitation.state < _ctx.QuestionnaireState.QUESTIONNAIRE_SUBMITTED)
          ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
              _createElementVNode("span", {
                innerHTML: _ctx.$t('homeForm.contentHeader')
              }, null, 8, _hoisted_28),
              _createElementVNode("span", {
                innerHTML: _ctx.$t('homeForm.contentOpenQuestionnaire')
              }, null, 8, _hoisted_29)
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.User.get().invitation.active)
        ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
            (_ctx.User.get().invitation.state < _ctx.QuestionnaireState.QUESTIONNAIRE_STARTED)
              ? (_openBlock(), _createBlock(_component_IciButton, {
                  key: 0,
                  id: "start",
                  content: _ctx.constructLabel(_ctx.buttonStartContent, _ctx.$t('homeForm.btnLabelStart')),
                  onButtonClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.startQuestionnaire())),
                  btnStyle: "width: fit-content; height: auto; padding: 18px 24px!important;"
                }, null, 8, ["content"]))
              : _createCommentVNode("", true),
            (_ctx.User.get().invitation.state == _ctx.QuestionnaireState.QUESTIONNAIRE_STARTED)
              ? (_openBlock(), _createBlock(_component_IciButton, {
                  key: 1,
                  id: "continue",
                  content: _ctx.constructLabel(_ctx.buttonProceedContent, _ctx.$t('homeForm.btnLabelProceed')),
                  onButtonClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.proceedQuestionnaire())),
                  btnStyle: "width: fit-content; height: auto; padding: 18px 24px!important;",
                  dark: false
                }, null, 8, ["content"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}