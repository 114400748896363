<template>
  <div class="project-details-content">
    <div class="flex" style="align-items: center; justify-content: space-between;margin-left:30px;">
      <div class="welcome-title-consultant-project-details">
        <span>{{ $route.path.includes('/new') ? 'Add' : 'Edit' }} Questionnaire</span>
      </div>
      <div class="back-to-all-clients">
        <IciButton id="backToAllClients"
                   :content="constructLabel(buttonBackToAllClients, $t('companyForm.buttonBackToAllClients'))"
                   @buttonClick="backToAllClients()"
                   btnStyle="width: fit-content; height: auto; padding: 18px 24px!important; top: 32px; position: static;"></IciButton>
      </div>
    </div>

    <div class="project-details-block">
      <div style="position: relative; width: 100%; height: 100%">
        <div style="position: absolute; top: 40px; left: 40px; right: 40px; height: 50px; border-radius: 10px; background-color: #fbfaff; font-family: 'Public Sans'; font-weight: bold; text-align: left; padding-left: 40px; line-height: 50px;" v-html="$route.path.includes('/new') ? 'Add Questionnaire' : 'Edit Questionnaire'"/>

        <div style="    position: absolute;
    inset: 110px 0px 100px;
    min-width: calc(100% - 110px);
    border-radius: 10px;
    right: 40px;">
          <div style="position: relative; width: 100%; height: 100%;">
            <div style="float: left; width: 500px">
              <div class="fields-block">
                <div class="flex" style="flex-direction: column; width: 100%; gap: 24px">
                  <div>
                    <div class="label-project-name">Questionnaire Name</div>
                    <div class="input-project-name">
                      <InputText type="text" v-model="questionnaire.name" class="field-project-name"/>
                    </div>
                  </div>

                  <div class="flex" style="gap: 24px">
                    <div style="width: 50%">
                      <div class="label-project-year">{{ $tc('companyForm.fieldLabelYear') }}</div>
                      <div class="input-project-year">
                        <InputText type="text" v-model="questionnaire.year" class="field-project-year"/>
                      </div>
                    </div>
                    <div style="width: 50%">
                      <div class="label-project-deadline">{{ $tc('companyForm.tableHeaderQuestionnaireDeadline') }}</div>
                      <div class="input-project-deadline">
                        <Calendar v-model="questionnaire.deadline" dateFormat="dd/mm/yy" class="field-project-deadline"/>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="label-project-description-details">Description</div>
                    <div class="input-project-description">
                      <Textarea v-model="questionnaire.description" rows="5" cols="42" style="width: 100%; height: 120px;"/>
                    </div>
                  </div>

                  <div class="flex" style="align-items: center;">
                    <div class="label-project-active">Active</div>
                    <div class="input-project-active">

                      <input class="check-box-inside"  type="checkbox"  v-model="questionnaire.active">
                    </div>
                  </div>

                  <div class="project-details-form-save">
                    <IciButton id="saveClient"
                               :content="constructLabel(buttonSaveContent, $t('profileForm.submit'))"
                               @buttonClick="save()"
                               btnStyle="width: fit-content; height: auto; padding: 18px 24px!important;"
                               :dark="false"></IciButton>
                  </div>
                  <div v-if="errorState" class="error-mess">
                    <Message severity="warn">{{errorMessage}}</Message>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="questionnaire.id != 0" style="float: left; width: calc(100% - 500px); padding-left: 100px;">
              <div class="flex" style="align-items: center; justify-content: space-between; margin-bottom: 24px;">
                <div style="font-weight: bold; text-align: left;">Respondents</div>
                <div>
                  <IciButton v-if="questionnaire.status != QuestionnaireState.CLOSED"
                             id="addRespondent"
                             :content="'Add Respondent'"
                             @buttonClick="addRespondent()"
                             btnStyle="width: fit-content; height: auto; padding: 18px 24px!important;"
                             :disabled="addRespondentMode"
                             :dark="false"></IciButton>
                </div>
              </div>
              <DataTable :value="questionnaire.respondents" stripedRows selectionMode="single" scrollHeight="420px" tableStyle="width: 100%;">
                <Column field="id" header="ID">
                  <template #body="slotProps">
                    <span v-if="slotProps.data.id == 0" class="pi pi-trash" @click="removeNewRespondent(slotProps.data)"></span>
                    <span v-if="slotProps.data.id == 0" class="pi pi-search-plus" @click="searchExistingRespondents()" style="padding-left: 5px;"></span>
                    <span v-else v-html="slotProps.data.id"/>
                  </template>
                </Column>
                <Column field="email" header="User">
                  <template #body="slotProps">
                    <InputText v-if="slotProps.data.id == 0" type="text" v-model="slotProps.data.email" class="field-project-year"/>
                    <span v-else v-html="slotProps.data.email"/>
                  </template>
                </Column>
                <Column field="state" header="State">
                  <template #body="slotProps">
                    <div v-if="slotProps.data.state.id == 0 && questionnaire.active" style="display: inline-block;line-height: 24px;height: 40px;">
                      <Checkbox v-model="slotProps.data.sentInvitation" :binary="true" />
                    </div>
                    <div v-if="slotProps.data.state.id == 0 && questionnaire.active" style="display: inline-block; padding-right: 30px;line-height: 25px;height: 40px;">sent invite</div>
                    <div v-if="slotProps.data.state.id == 0" style="display: inline-block; line-height: 40px;height: 40px;">
                      <IciButton id="saveRespondent"
                                 :content="'Save'"
                                 @buttonClick="saveRespondent(slotProps.data)"
                                 btnStyle="width: 80px;"
                                 :dark="false"></IciButton>
                    </div>
                    <div v-if="slotProps.data.state.id == 1" style="display: inline-block; line-height: 40px;height: 40px;">
                      <IciButton id="sentInvitation"
                                 :content="buttonInviteContent"
                                 @buttonClick="sentInvitationToRespondent(slotProps.data)"
                                 btnStyle="width: 100px; height: 30px; border-radius: 15px;"
                                 :dark="false"></IciButton>
                    </div>
                    <IciStatus v-if="slotProps.data.state.id != 1 && slotProps.data.state.id != 0" :state="slotProps.data.state.id"></IciStatus>
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent, PropType, ref} from 'vue';
import {Company} from "@/data/Company";
import {Application} from "@/data/Application";
import IciButton from "@/components/IciButton.vue";
import {User} from "@/data/User";
import router from "@/router";
import {useRouter} from "vue-router";
import DashboardButton from "@/components/button/DashboardButton.vue";
import ResultButton from "@/components/button/ResultButton.vue";
import SettingsButton from "@/components/button/SettingsButton.vue";
import {AuthService} from "@/service/AuthService";
import {Questionnaire} from "@/data/Questionnaire";
import IciStatus from "@/components/IciStatus.vue";
import axios from "axios";
import { Respondent } from '@/data/Respondent';
import {AuthRoleModel} from "@/data/AuthRoleModel";
import {QuestionnaireState} from "@/data/QuestionnaireState";

export default defineComponent({
  components: {IciStatus, SettingsButton, ResultButton, DashboardButton, IciButton},
  computed: {
    QuestionnaireState() {
      return QuestionnaireState
    },
    Company() {
      return Company
    },
    Application() {
      return Application
    }
  },
  data() {
    return {
      hover: false,
      buttonBackToAllClients: "<i class='pi pi-chevron-left' style='font-size: 1rem'></i>&nbsp;&nbsp;<label>$label</label>",
      buttonSaveContent: "<label>$label</label>&nbsp;&nbsp;<i class='pi pi-chevron-right' style='font-size: 1rem'></i>",
      buttonInviteContent: "<label>Invite</label><i class='pi pi-send' style='font-size: 1rem'></i>",
      selectedClient: this.getCompany()
    }

  },
  methods: {
    constructLabel: (content: String, label: string) => {
      return content.replace("$label", label);
    },
    getQuestionnaire: function() {
      let qid: number = Number(router.currentRoute.value.params.qid);
      console.log('qid: ' + isNaN(qid));
      if(isNaN(qid)) {
        let q = new Questionnaire();
        q.id = 0;
        return q;
      } else {
        Questionnaire.getQuestionnaire(qid, this);
        return new Questionnaire();
      }
    },
    getCompany: ():Company|null => {
      let id: Number = Number(router.currentRoute.value.params.id);
      if(id == 0) {
        return Company.build(0, "", 0, 0, 0, 0, "");
      } else {
        return User.get().getCompany(id);
      }
    },
    backToAllClients: () => {
      router.push("/");
    },
    gotoClientDashboard: () => {
      // @ts-ignore
      router.push("/client/" + Number(router.currentRoute.value.params.id) + "/dashboard");
    },
    gotoResults: () => {
      router.push("/results-dashboard");
    },
    gotoSetting: () => {
      router.push("/client/" + Number(router.currentRoute.value.params.id) + "/dashboard");
    },
    addRespondent() {
      this.errorState = false;
      this.addRespondentMode = true;
      let respondent = new Respondent();
      respondent.id = 0;
      respondent.state = new AuthRoleModel();
      respondent.state.id = 0;

      this.questionnaire.respondents.push(respondent);
    },
    saveRespondent(respondent: Respondent) {
      this.errorState = false;
      const index = this.questionnaire.respondents.indexOf(respondent);
      axios.post(Application.instance.apiEndPoint + '/consultant/questionnaire/respondents/' + Number(router.currentRoute.value.params.qid), respondent)
          .then(response => {
            this.questionnaire.respondents = [];
            for(let respondent of response.data) {
              let resp = new Respondent();
              resp.id = respondent.id;
              resp.email = respondent.email;
              resp.state =  AuthRoleModel.create(respondent.state, "")
              this.questionnaire.respondents.push(resp);

              this.addRespondentMode = false;
            }
          })
          .catch(err => {
            this.addRespondentMode = false;
            console.log(err);
          });
    },
    removeNewRespondent(respondent: Respondent) {
      this.addRespondentMode = false;
      const index = this.questionnaire.respondents.indexOf(respondent);
      this.questionnaire.respondents.splice(index, 1);
    },
    sentInvitationToRespondent(respondent: Respondent) {
      this.errorState = false;
      axios.post(Application.instance.apiEndPoint + '/consultant/questionnaire/respondents/' + Number(router.currentRoute.value.params.qid) + '/invite', respondent)
          .then(response => {
            this.questionnaire.respondents = [];
            for(let respondent of response.data) {
              let resp = new Respondent();
              resp.id = respondent.id;
              resp.email = respondent.email;
              resp.state =  AuthRoleModel.create(respondent.state, "")
              this.questionnaire.respondents.push(resp);

              this.addRespondentMode = false;
            }
          })
          .catch(err => {
            this.addRespondentMode = false;
            console.log(err);
          });
    },
    searchExistingRespondents() {

    },
    save: function() {
      this.questionnaire.save(this.selectedClient.id, (response: any) => {
        if(response.ok) {
          this.errorState = false;
          this.gotoClientDashboard();
        } else {
          this.errorState = true;
          this.errorMessage = "Only one questionnaire can be active at a time, and an active questionnaire already exists for this client. You can still save this questionnaire as a draft without the 'Active' checkbox selected.";
        }
      });
    }
  },
  setup(props) {
    AuthService.validate();
    const instance = this;
    let questionnaire = ref(new Questionnaire());
    let addRespondentMode = ref(false);
    let errorState = ref(false);
    let errorMessage = ref("");

    let qid: number = Number(router.currentRoute.value.params.qid);
    if(!isNaN(qid)) {
      axios.get(Application.instance.apiEndPoint + '/questionnaire/' + Number(router.currentRoute.value.params.qid))
          .then(response => {
            console.log(response);
            questionnaire.value = new Questionnaire();
            questionnaire.value.id = response.data.id;
            questionnaire.value.name = response.data.name;
            questionnaire.value.year = response.data.year;
            // questionnaire.value.deadline = response.data.deadline;
            questionnaire.value.description = response.data.description;
            questionnaire.value.active = response.data.active;
            questionnaire.value.respondents = [];
            let dateParts: string[] = ['9999','12','31'];
            if(response.data != null) {
              dateParts = response.data.deadline.split('-');
            }
            questionnaire.value.deadline =  new Date(Number(dateParts[0]), Number(dateParts[1]) -1, Number(dateParts[2]));
            // @ts-ignore
            questionnaire.value.status = questionnaire.value.active ? ((questionnaire.value.deadline - new Date() > 1) ? QuestionnaireState.ACTIVE : QuestionnaireState.EXPIRED) : ((questionnaire.value.deadline - new Date() > 1) ? QuestionnaireState.DRAFT : QuestionnaireState.CLOSED);
            console.log(questionnaire.value.deadline)
            console.log(questionnaire.value.status)

            for (let respondent of response.data.respondents) {
              let resp = new Respondent();
              resp.id = respondent.id;
              resp.email = respondent.email;
              resp.state = respondent.state;
              questionnaire.value.respondents.push(resp);
            }
          })
          .catch(err => {
            console.log(err);
          });
    } else {
      questionnaire.value = new Questionnaire();
      questionnaire.value.id = 0;
    }

    return {
      questionnaire, addRespondentMode, errorState, errorMessage
    }
  },
});
</script>
<style scoped>
.content {
  font-family: "Public Sans";
  position: static;
  top: 0;
  left: 60px !important;
  bottom: 0;
  right: 0;
  padding-left: 50px;
}
.welcome-title-consultant-project-details {
  font-family: "Public Sans";
  font-size: 40px;
  font-weight: bold;
  text-align: left;
  padding-left: 60px;
  padding-top: 130px;
}

.project-details-block {
  position: absolute;
  top: 210px;
  left: 140px;

  width: calc(100% - 180px);
  bottom: 0px;
  background-color: #ffffff;
  border-radius: 10px !important;
}

.label-project-name {
  font-family: "Public Sans";
  position: absolute;
  top: 50px;
  left: 50px;
  font-weight: bold;
}

.input-project-name {
  font-family: "Public Sans";
  position: absolute;
  top: 74px;
  left: 50px;
  font-weight: normal;
}
.field-project-name {
  width: 400px;
  border-color: #cccccc; border-style: solid;border-width: 1px;
}

.label-project-year{
  font-family: "Public Sans";
  position: absolute;
  top: 130px;
  left: 50px;
  font-weight: bold;
}

.input-project-year {
  font-family: "Public Sans";
  position: absolute;
  top: 154px;
  left: 50px;
  font-weight: normal;
}


.field-project-year {
  width: 100px;
  border-color: #cccccc; border-style: solid;border-width: 1px;
}

.label-project-deadline {
  font-family: "Public Sans";
  position: absolute;
  top: 130px;
  left: 280px;
  font-weight: bold;
}

.input-project-deadline {
  font-family: "Public Sans";
  position: absolute;
  top: 154px;
  left: 280px;
  font-weight: normal;
}
.field-project-deadline {
  width: 190px;
}

.label-project-description-details {
  font-family: "Public Sans";
  position: absolute;
  top: 210px;
  left: 50px;
  font-weight: bold;
}
.input-project-description {
  font-family: "Public Sans";
  position: absolute;
  top: 234px;
  left: 50px;
  font-weight: normal;
}
.project-details-form-save {
  font-family: "Public Sans";
  position: absolute;
  top: 370px;
  left: 330px;
  font-weight: normal;
}
.input-project-active input[type="checkbox"] {
  transform: scale(2);
  border: 2px solid black; /* Adjust the border thickness and color as needed */
}

.input-project-active .vue-checkbox-class {
  transform: scale(2);
  border: 2px solid black; /* Adjust the border thickness and color as needed */
}
.error-mess {
  position: absolute;
  top: 422px;
  left: 50px;
  width: 420px;
}
.label-project-active {
  font-family: "Public Sans";
  position: absolute;
  top: 380px;
  left: 50px;
  font-weight: bold;
}
.input-project-active {
  font-family: "Public Sans";
  position: absolute;
  top: 380px;
  left: 110px;
  font-weight: bold;
}

.back-to-all-clients {
  margin-top: 130px;
  margin-right: 50px;
}

 .project-details-content {
   font-family: "Public Sans";
   position: static;
   top: 0;
   left: 0 !important;
   bottom: 0;
   right: 0;
   padding-left: 0px;
 }

.welcome-title-consultant-project-details {
  font-family: "Public Sans";
  font-size: 40px;
  font-weight: bold;
  text-align: left;
  padding-top: 110px;
  padding-left: 110px;
}

.project-details-block {
  position: absolute;
  top: 210px;
  left: 140px;
  width: calc(100% - 180px);
  bottom: 0px;
  background-color: #ffffff;
  border-radius: 10px !important;
}

.back-to-all-clients {
  margin-top: 130px;
  margin-right: 40px;
}

.fields-block {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.label-project-name, .label-project-year, .label-project-deadline, .label-project-description-details, .label-project-active {
  font-weight: bold;
  margin-bottom: 6px;
}

.field-project-name, .field-project-year, .field-project-deadline {
  width: 100%;
}

.project-details-form-save {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.error-mess {
  margin-top: 24px;
}
.fields-block {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-left:10px !important;
  padding-top:0 !important;
}
.check-box-inside{
  accent-color: #0D3D52;
  border: solid 1px #0D3D52;
}




</style>
