<template>
    <svg viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
      <!-- Render filled circles -->
      <circle
        v-for="(circle, index) in circles"
        :key="index"
        :cx="circle.cx"
        :cy="circle.cy"
        :r="circle.r"
        :fill="circle.fill"
        class="filledCircle transition-transform duration-300 ease-in-out"
        :class="[
          `graphCircle${index}`,
          { 'shadow-inner scale-125 z-20': hoveredIndex === index }
        ]"
        @mouseover="handleMouseOver(index)"
        @mouseleave="handleMouseLeave"
        :style="{
          transform: `rotate(${circle.rotation}deg) translateY(${circle.translateY}px)`
        }"
      />
      <!-- Render stroke circles -->
      <circle
        v-for="(circle, index) in circles"
        :key="'stroke' + index"
        :cx="circle.cx"
        :cy="circle.cy"
        :r="circle.r"
        class="strokeCircle transition-transform duration-300 ease-in-out"
        :style="{
          transform: `rotate(${circle.rotation}deg) translateY(${circle.strokeTranslateY}px)`,
          zIndex: hoveredIndex === index ? 10 : 0
        }"
      />
    </svg>
  </template>
  
  <script>
  export default {
    props: {
      circles: {
        type: Array,
        required: true
      },
      hoveredIndex: {
        type: Number,
        default: null
      }
    },
    methods: {
      handleMouseOver(index) {
        this.$emit('hover', index);
      },
      handleMouseLeave() {
        this.$emit('hover', null);
      }
    }
  };
  </script>
  
  <style scoped>

  .shadow-inner {
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.5));
  }
  </style>
  