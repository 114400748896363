export class AuthRoleModel {

    public id :number = 0;
    public name :string = '';

    public static create = (id: number, name: string) => {
        let model = new AuthRoleModel();
        model.id = id;
        model.name = name;
        return model;
    }
}
