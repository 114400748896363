<template>

  <div v-if="selectedClient == null" class="content" style="display: flex;">

    <div class="flex" style="display: flex; flex-direction: column; gap: 8vh; width: 100%;">

    <div class="welcome-title-consultant-dashboard">
      <span v-html="$t('homeForm.welcome')" style="padding-right: 12px;" />
      <span v-html="User.get().profile.firstName" />
    </div>

    <div class="flex" style="gap: 36px; width: 100%; margin-top: -30px;">

    <div class="flex bg-white" style="flex-direction: column; padding: 16px; border-radius: 16px; width: 100%; gap: 16px;">

      <div style="height: 50px; background-color: #F3F4F8; border-radius: 10px;">
        <div style="width: 100%; height: 100%;">
          <div
            style="float: left; width: 40%; font-family: 'Public Sans'; font-weight: bold; font-size: 16px; line-height: 50px; text-align: left; padding-left: 30px;"
            v-html="$t('companyForm.tableHeaderClientName')"></div>
          <div
            style="float: left; width: 30%; font-family: 'Public Sans'; font-weight: bold; font-size: 16px; line-height: 50px; text-align: left;"
            v-html="$t('companyForm.tableHeaderQuestionnaireDeadline')"></div>
          <div
            style="float: left; width: 30%; font-family: 'Public Sans'; font-weight: bold; font-size: 16px; line-height: 50px; text-align: left;"
            v-html="$t('companyForm.tableHeaderProjectStatus')"></div>
        </div>
      </div>

      <div>
        <div v-for="client of User.get().consultantCompanies"
          style="position: relative; width: 100%; height: 70px; background-color: #FFFFFF; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; display: flex; align-items: center;">
          <div
            style="float: left; width: 40%; font-family: 'Public Sans'; font-size: 16px; line-height: 50px; text-align: left; padding-left: 20px; display: flex; align-items: center;">
            <IciButton :id="'buttonClick@' + client.id" :content="client.name" @buttonClick="clientDetails(client.id)"
              btnStyle="width: 200px; text-align: center;"></IciButton>
          </div>
          <div
            style="float: left; width: 30%; font-family: 'Public Sans'; font-size: 16px; line-height: 70px; text-align: left;">
            {{ client.deadlineFormatted }}</div>
          <div
            style="float: left; width: 30%; font-family: 'Public Sans'; font-size: 16px; line-height: 70px; text-align: left;">
            <IciStatus :state="client.status"></IciStatus>
          </div>
        </div>
      </div>

    </div>

    <div style="background-color: #FFFFFF; border-radius: 16px; padding: 40px; width: fit-content; height: fit-content;">
      <IciButton id="newClient" :content="constructLabel(buttonAddNewClient, $t('companyForm.buttonNewClient'))"
        @buttonClick="buttonClick('newClient')" btnStyle="padding: 16px 24px!important; width: fit-content; height: auto;"></IciButton>
    </div>

    </div>

    </div>

  </div>

  <ClientDetails v-if="selectedClient != null && selectedQuestionnaire == null" :selected-client="selectedClient"
    :click="buttonClick"></ClientDetails>

  <div v-if="selectedQuestionnaire != null" class="content">
    <div class="welcome-title-consultant">
      <span v-html="selectedClient.name" />
    </div>

    <div class="back-to-all-clients">
      <IciButton id="backToAllClients"
        :content="constructLabel(buttonBackToAllClients, $t('companyForm.buttonBackToAllClients'))" :click="buttonClick"
        btnStyle="width: 240px;"></IciButton>
    </div>
    <div class="client-details-block-details">
      <div style="height: 100%; width: 100%">
        <div class="fields-block">
          <div class="label-client-name" v-html="$tc('companyForm.fieldLabelYear')" />
          <div class="input-client-name">
            <InputText type="text" v-model="selectedQuestionnaire.year" class="field-questionnaire-year" />
          </div>

          <div class="label-business-activity" v-html="$tc('companyForm.fieldLabelDeadline')" />
          <div class="input-business-activity">
            <InputText type="text" v-model="selectedQuestionnaire.deadlineFormatted"
              class="field-questionnaire-deadline" />
          </div>

          <div class="label-number-of-employees" v-html="$tc('companyForm.fieldLabelStatus')" />
          <div class="input-number-of-employees">
            <Dropdown v-model="selectedQuestionnaire.active" :options="statusOptions"
              @change="event => { this.$forceUpdate() }" option-value="id" optionLabel="name"
              :placeholder="$t('ddlSelectPlaceHolder')" class="md:w-14rem level-ddl field-number-of-employees" />
          </div>

          <div class="project-block">
            <DataTable tableStyle="min-width: 50rem">
              <Column field="email" :header="$t('companyForm.tableHeaderEmail')"></Column>
              <Column field="status" :header="$t('companyForm.tableHeaderStatus')"></Column>
              <Column field="progress" :header="$t('companyForm.tableHeaderProgress')"></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
// @ is an alias to /src
import router from "@/router";
import { Inject } from "vue-property-decorator";
import { User } from "@/data/User";
import { Application } from "@/data/Application";
import { QuestionnaireState } from "@/data/QuestionnaireState";
import { AuthService } from "../service/AuthService";
import { RoleType } from "@/data/RoleType";
import { MenuItem } from "primevue/menuitem";
import { ConsultanService } from "@/service/ConsultanService";
import { ref } from "vue";
import { Company } from "@/data/Company";
import { Questionnaire } from "@/data/Questionnaire";
import IciButton from "@/components/IciButton.vue";
import IciStatus from "@/components/IciStatus.vue";
import ClientDetails from "@/views/ClientDetailsView.vue";

@Options({
  methods: {
    router() {
      return router
    }
  },
  components: { ClientDetails, IciStatus, IciButton },
  computed: {
    QuestionnaireState() {
      return QuestionnaireState
    },
    ConsultanService() {
      return ConsultanService
    },
    AuthService() {
      return AuthService
    },
    Application() {
      return Application
    },
    User() {
      return User
    }
  }
})
export default class ConsultantView extends Vue {

  private selectedClient: Company | null = null;
  private selectedQuestionnaire: Questionnaire | null = null;

  private buttonAddNewClient = "<i class='pi pi-plus' style='font-size: 1rem'></i><label style='font-weight: bold;'>$label</label>";
  private buttonBackToAllClients = "<i class='pi pi-chevron-left' style='font-size: 1rem'></i><label>$label</label>";
  private buttonSaveContent = "<label>$label</label><i class='pi pi-chevron-right' style='font-size: 1rem'></i>";

  private constructLabel(content: String, label: string) {
    return content.replace("$label", label);
  }

  private statusOptions = [
    { id: true, name: "Active" },
    { id: false, name: "Inactive" },
  ];

  mounted() {
    AuthService.validate();
  }

  public clientClick = (id: Number) => {
    let company: Company | null = User.get().getCompany(id);
    if (company != null) {
      this.selectedClient = company;
    }
  }

  private buttonClick = (id: String) => {
    if (id == "backToAllClients") {
      this.backToAllCLients();
    }
    if (id == "newClient") {
      this.addNewClient();
      router.push("/client/0");
    }
    if (id == "saveClient") {
      this.saveClient();
    }
    if (id.startsWith('buttonClick@')) {
      let companyId = id.split("@")[1];
      router.push("/client/" + companyId);
    }
  }

  private clientDetails = (id: Number | null) => {
    router.push("/client/" + id+"/dashboard");
  }

  private saveClient = () => {
    if (this.selectedClient != null) {
      this.selectedClient.save(
        (updatedClient: Company) => {
          this.selectedClient = updatedClient;

          this.selectedClient = null;
          this.selectedQuestionnaire = null;
        }
      );
    }
  }

  public addNewClient = () => {
    this.selectedClient = Company.build(0, "", 0, 0, 0, 0, "");
  }

  public backToAllCLients = () => {
    this.selectedClient = null;
    this.selectedQuestionnaire = null;
    router.push("/")
  }

  public backToCLient = () => {
    this.selectedQuestionnaire = null;
  }

  public questionnaireClick = (e: any) => {
    this.selectedQuestionnaire = e.data;
  }
}
</script>
<style>

.pi-plus {
  display: flex;
  align-items: center;
}

.pi-plus:before {
  font-size: 9px!important
}

.panel {
  position: relative;
}

.content {
  font-family: "Public Sans";
  width: 100%;
  padding-left: 40px;
}

.status-label {
  height: 34px;
}

.client-details-block-details {
  position: absolute;
  top: 50px;
  right: 50px;
  left: 0px;
  bottom: 0px;
  background-color: #ffffff;
  margin-top: -10px;
}

.welcome-title-consultant-dashboard {
  font-family: "Public Sans";
  font-size: 40px;
  font-weight: 800!important;
  text-align: left;
  margin-top:40px;
  padding-left:0;
}

.fields-block {
  font-family: "Public Sans";
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  padding-top: 20px;
  padding-left: 50px;
}

.label-client-name {
  font-family: "Public Sans";
  top: 70px;
  left: 50px;
  font-weight: bold;
}

.input-client-name {
  font-family: "Public Sans";
  top: 94px;
  left: 50px;
  font-weight: normal;
}

.field-client-name {
  font-family: "Public Sans";
  width: 520px;
}


.label-business-activity {
  font-family: "Public Sans";
  top: 180px;
  left: 50px;
  font-weight: bold;
}

.input-business-activity {
  font-family: "Public Sans";
  top: 204px;
  left: 50px;
  font-weight: normal;
}

.field-business-activity {
  font-family: "Public Sans";
  width: 245px;
  height: 45px !important;
}


.label-number-of-employees {
  font-family: "Public Sans";
  top: 180px;
  left: 350px;
  font-weight: bold;
}

.input-number-of-employees {
  font-family: "Public Sans";
  top: 204px;
  left: 350px;
  font-weight: normal;
}

.field-number-of-employees {
  font-family: "Public Sans";
  width: 245px;
  height: 45px !important;
}


.label-gross-revenue {
  font-family: "Public Sans";
  top: 270px;
  left: 50px;
  font-weight: bold;
}

.input-gross-revenue {
  font-family: "Public Sans";
  top: 294px;
  left: 50px;
  font-weight: normal;
}

.field-gross-revenue {
  font-family: "Public Sans";
  width: 245px;
  height: 45px !important;
}


.label-executive-report {
  font-family: "Public Sans";
  top: 270px;
  left: 350px;
  font-weight: bold;
}

.input-executive-report {
  font-family: "Public Sans";
  top: 294px;
  left: 350px;
  font-weight: normal;
}

.field-executive-report {
  font-family: "Public Sans";
  width: 245px;
  height: 45px !important;
}

.client-form-save {
  font-family: "Public Sans";
  top: 350px;
  left: 432px;
  font-weight: normal;
}

.project-block {
  top: 360px;
  left: 50px;
  font-weight: bold;
}

.field-questionnaire-year {
  font-family: "Public Sans";
  width: 100px;
}

.field-questionnaire-deadline {
  font-family: "Public Sans";
  width: 200px;
}

.intro-block {
  font-family: "Public Sans";
  border-radius: 6px;
  height: 400px;
  border-left-style: solid;
  border-left-width: 8px;
  border-left-color: #0D3D52;
  box-shadow: 5px 10px 8px #EEEEEE;
}

.intro-block-content {
  font-family: "Public Sans";
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  padding-top: 20px;
  padding-left: 50px;
}

.block-size {
  font-family: "Public Sans";
  width: 530px;
}

.app-title {
  font-family: "Public Sans";
  font-size: 32px;
  font-weight: bold;
  text-align: left;
  padding-top: 20px;
  padding-left: 50px;
}

.button-block {
  font-family: "Public Sans";
  bottom: 20px;
  right: 50px;
}

.questionnaire-state-block {
  font-family: "Public Sans";
  top: 20px;
  left: 500px;
  width: 600px;
  height: 140px;
  border-radius: 6px;
  box-shadow: 5px 10px 8px #EEEEEE;
}

.status-title {
  font-family: "Public Sans";
  top: 30px;
  font-size: 16px;
  font-weight: bold;
}

.progress {
  font-family: "Public Sans";
  left: 100px;
}

.deadline {
  font-family: "Public Sans";
  left: 250px;
}

.my-response {
  font-family: "Public Sans";
  left: 360px;
}

.status-value {
  font-family: "Public Sans";
  top: 70px;
  font-size: 16px;
  font-weight: bold;
}

.tabbed-panel-block {
  font-family: "Public Sans";
  left: 10px;
  width: 100%;
  top: 35px;
}

.consultant-panel {
  font-family: "Public Sans";
  background-color: #ffffff;
  border-radius: 6px;
  min-height: 650px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-left-style: solid;
  border-left-width: 8px;
  border-left-color: #0D3D52;
  box-shadow: 5px 10px 8px #EEEEEE;
}

.consultant-menu-bar {
  font-family: "Public Sans";
  left: 0px;
  width: 100%;
  top: -30px;
}

.form-title {
  font-family: "Public Sans";
  font-size: 32px;
  font-weight: bold;
  text-align: left;
  padding-top: 20px;
  padding-left: 50px;
}

.label-company-name {
  font-family: "Public Sans";
  top: 90px;
  left: 50px;
  font-weight: bold;
}

.input-company-name {
  font-family: "Public Sans";
  top: 114px;
  left: 50px;
  font-weight: normal;
}

.field-company-name {
  font-family: "Public Sans";
  width: 525px;
}

.label-number-employees {
  font-family: "Public Sans";
  top: 270px;
  left: 50px;
  font-weight: bold;
}

.input-number-employees {
  font-family: "Public Sans";
  top: 294px;
  left: 50px;
  font-weight: normal;
}

.button-company-submit {
  font-family: "Public Sans";
  top: 360px;
  left: 460px;
  font-weight: bold;
}

.field-year {
  font-family: "Public Sans";
  width: 125px;
}

.button-questionnaire-submit {
  font-family: "Public Sans";
  top: 450px;
  left: 460px;
  font-weight: bold;
}

.button-questionnaire-inactive {
  font-family: "Public Sans";
  top: 450px;
  left: 50px;
  font-weight: bold;
}

.bnt-inactive {
  font-family: "Public Sans";
  width: 180px;
}

.text-no-active-questionnaire {
  font-family: "Public Sans";
  top: 90px;
  left: 50px;
}

.button-questionnaire-new {
  font-family: "Public Sans";
  top: 180px;
  left: 50px;
  font-weight: bold;
}

.bnt-new-questionnaire {
  font-family: "Public Sans";
  width: 280px !important;
}
</style>
