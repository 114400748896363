<template>
  <div class="panel" style="gap: 0px">
    <div class="label">User State</div>
    <div class="field">
      <DataTable  :value="refUserState" stripedRows  scrollable   scrollHeight="200px" tableStyle="width: 600px;">
        <Column field="id" header="ID" style="width: 100px"></Column>
        <Column field="name" header="Name"></Column>
      </DataTable>
    </div>

    <div class="label details">Number of Employees</div>
    <div class="field">
      <DataTable  :value="refNumberOfEmployees" stripedRows  scrollable   scrollHeight="200px" tableStyle="width: 600px;">
        <Column field="id" header="ID" style="width: 100px"></Column>
        <Column field="name" header="Name"></Column>
      </DataTable>
    </div>

    <div class="label details">Job Level</div>
    <div class="field">
      <DataTable  :value="refJobLevel" stripedRows  scrollable   scrollHeight="200px" tableStyle="width: 600px;">
        <Column field="id" header="ID" style="width: 100px"></Column>
        <Column field="name" header="Name"></Column>
      </DataTable>
    </div>

    <div class="label details">Job Level</div>
    <div class="field">
      <DataTable  :value="refGrossRevenue" stripedRows  scrollable   scrollHeight="200px" tableStyle="width: 600px;">
        <Column field="id" header="ID" style="width: 100px"></Column>
        <Column field="name" header="Name"></Column>
      </DataTable>
    </div>

    <div class="label details">Executive Report</div>
    <div class="field">
      <DataTable  :value="refExecutiveReport" stripedRows  scrollable   scrollHeight="200px" tableStyle="width: 600px;">
        <Column field="id" header="ID" style="width: 100px"></Column>
        <Column field="name" header="Name"></Column>
      </DataTable>
    </div>

    <div class="label details">Business Activity</div>
    <div class="field">
      <DataTable  :value="refBusinessActivity" stripedRows  scrollable   scrollHeight="200px" tableStyle="width: 600px;">
        <Column field="id" header="ID" style="width: 100px"></Column>
        <Column field="name" header="Name"></Column>
      </DataTable>
    </div>
  </div>
</template>

<script lang="ts">
import {Vue} from 'vue-class-component';
import {AuthRoleModel} from "@/data/AuthRoleModel";
import axios from "axios";
import {Application} from "@/data/Application";
export default class AdminReferenceDataView extends Vue {

  private refUserState : AuthRoleModel[] =[];
  private refNumberOfEmployees : AuthRoleModel[] =[];
  private refJobLevel : AuthRoleModel[] =[];
  private refGrossRevenue : AuthRoleModel[] =[];
  private refExecutiveReport : AuthRoleModel[] =[];
  private refBusinessActivity : AuthRoleModel[] =[];
  mounted() {
    axios.get( Application.instance.apiEndPoint + '/ref/user-states')
        .then(response => {
          this.refUserState = [];
          for(let state of response.data) {
            this.refUserState.push(AuthRoleModel.create(state.id, state.name))
          }
        })
        .catch(err => {
          if(err.response.status == 403) {
            localStorage.removeItem("token");
          }
        });

    axios.get( Application.instance.apiEndPoint + '/ref/number-of-employees')
        .then(response => {
          this.refNumberOfEmployees = [];
          for(let state of response.data) {
            this.refNumberOfEmployees.push(AuthRoleModel.create(state.id, state.name))
          }
        })
        .catch(err => {
          if(err.response.status == 403) {
            localStorage.removeItem("token");
          }
        });

    axios.get( Application.instance.apiEndPoint + '/ref/job-level')
        .then(response => {
          this.refJobLevel = [];
          for(let state of response.data) {
            this.refJobLevel.push(AuthRoleModel.create(state.id, state.name))
          }
        })
        .catch(err => {
          if(err.response.status == 403) {
            localStorage.removeItem("token");
          }
        });

    axios.get( Application.instance.apiEndPoint + '/ref/gross-revenue')
        .then(response => {
          this.refGrossRevenue = [];
          for(let state of response.data) {
            this.refGrossRevenue.push(AuthRoleModel.create(state.id, state.name))
          }
        })
        .catch(err => {
          if(err.response.status == 403) {
            localStorage.removeItem("token");
          }
        });

    axios.get( Application.instance.apiEndPoint + '/ref/executive-report')
        .then(response => {
          this.refExecutiveReport = [];
          for(let state of response.data) {
            this.refExecutiveReport.push(AuthRoleModel.create(state.id, state.name))
          }
        })
        .catch(err => {
          if(err.response.status == 403) {
            localStorage.removeItem("token");
          }
        });

    axios.get( Application.instance.apiEndPoint + '/ref/business-activity')
        .then(response => {
          this.refBusinessActivity = [];
          for(let state of response.data) {
            this.refBusinessActivity.push(AuthRoleModel.create(state.id, state.name))
          }
        })
        .catch(err => {
          if(err.response.status == 403) {
            localStorage.removeItem("token");
          }
        });
  }
}
</script>
<style scoped>
.panel {
  text-align: left;
  padding-left: 70px;
}
.details {
  margin-top:50px;
}
.label {
  font-family: "Public Sans";
  font-weight: bold;
  width: 600px; background-color: #0D3D52; color: #ffffff; padding-left: 10px; height: 30px; line-height: 30px;
}
.field {
  font-family: "Public Sans";
  font-weight: normal;
}
.input {
  font-family: "Public Sans";
  width: 750px;
}
</style>
