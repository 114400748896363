<template>
  <div class="panel">

    <div class="flex py-6" style="justify-content: flex-end ;">

      <div class="stepper">
        <div class="flow-item" @click="this.$forceUpdate(); select(9)">
          <img v-if="activeQuestion > 9" src="../assets/questionnaire/demographics-small.png" height="40" />
          <img v-if="activeQuestion <= 9" src="../assets/questionnaire/demographics-big.png" height="40" />
        </div>
        <div class="flow-item" @click="this.$forceUpdate(); select(10);">
          <img v-if="!(activeQuestion >= 10 && activeQuestion <= 21)"
            src="../assets/questionnaire/environmental-factors-small.png" height="40" />
          <img v-if="activeQuestion >= 10 && activeQuestion <= 21"
            src="../assets/questionnaire/environmental-factors-big.png" height="40" />
        </div>
        <div class="flow-item" @click="this.$forceUpdate(); select(22)">
          <img v-if="!(activeQuestion >= 22 && activeQuestion <= 39)"
            src="../assets/questionnaire/network-characteristics-small.png" height="40" />
          <img v-if="activeQuestion >= 22 && activeQuestion <= 39"
            src="../assets/questionnaire/network-characteristics-big.png" height="40" />
        </div>
        <div class="flow-item" @click="this.$forceUpdate(); select(40)">
          <img v-if="!(activeQuestion >= 40 && activeQuestion <= 54)"
            src="../assets/questionnaire/organizational-governance-small.png" height="40" />
          <img v-if="activeQuestion >= 40 && activeQuestion <= 54"
            src="../assets/questionnaire/organizational-governance-big.png" height="40" />
        </div>
        <div class="flow-item" @click="this.$forceUpdate(); select(55)">
          <img v-if="!(activeQuestion >= 55 && activeQuestion <= 63)"
            src="../assets/questionnaire/organizational-architecture-small.png" height="40" />
          <img v-if="activeQuestion >= 55 && activeQuestion <= 63"
            src="../assets/questionnaire/organizational-architecture-big.png" height="40" />
        </div>
        <div class="flow-item" @click="this.$forceUpdate(); select(64)">
          <img v-if="!(activeQuestion >= 64 && activeQuestion <= 83)"
            src="../assets/questionnaire/technical-IT-infrastructure-small.png" height="40" />
          <img v-if="activeQuestion >= 64 && activeQuestion <= 83"
            src="../assets/questionnaire/technical-IT-infrastructure-big.png" height="40" />
        </div>
        <div class="flow-item" @click="this.$forceUpdate(); select(84)">
          <img v-if="!(activeQuestion >= 84 && activeQuestion <= 97)"
            src="../assets/questionnaire/human-IT-infrastructure-small.png" height="40" />
          <img v-if="activeQuestion >= 84 && activeQuestion <= 97"
            src="../assets/questionnaire/human-IT-infrastructure-big.png" height="40" />
        </div>
        <div class="flow-item" @click="this.$forceUpdate(); select(98)">
          <img v-if="!(activeQuestion >= 98 && activeQuestion <= 109)"
            src="../assets/questionnaire/organizational-culture-small.png" height="40" />
          <img v-if="activeQuestion >= 98 && activeQuestion <= 109"
            src="../assets/questionnaire/organizational-culture-big.png" height="40" />
        </div>
        <div class="flow-item" @click="this.$forceUpdate(); select(110)">
          <img v-if="!(activeQuestion >= 110 && activeQuestion <= 116)"
            src="../assets/questionnaire/human-knowledge-and-skills-small.png" height="40" />
          <img v-if="activeQuestion >= 110 && activeQuestion <= 116"
            src="../assets/questionnaire/human-knowledge-and-skills-big.png" height="40" />
        </div>
        <div class="flow-item" @click="this.$forceUpdate(); select(117)">
          <img v-if="!(activeQuestion >= 117 && activeQuestion <= 149)"
            src="../assets/questionnaire/srl-capabilities-small.png" height="40" />
          <img v-if="activeQuestion >= 117 && activeQuestion <= 149"
            src="../assets/questionnaire/srl-capabilities-big.png" height="40" />
        </div>
      </div>
      <div class="questions-panel" style="display: flex; flex-direction: column;">
        <QDemographics v-if="activeQuestion <= 9" :active-question="this.activeQuestion"></QDemographics>
        <QEnvironmentalFactors v-if="activeQuestion >= 10 && activeQuestion <= 21"
          :active-question="this.activeQuestion"
          :activeSub="(this.activeQuestion >= 10 && this.activeQuestion <= 14 ? 0 : (this.activeQuestion >= 15 && this.activeQuestion <= 18 ? 1 : 2))">
        </QEnvironmentalFactors>
        <QNetworkCharacteristics v-if="activeQuestion >= 22 && activeQuestion <= 39"
          :active-question="this.activeQuestion"
          :activeSub="(this.activeQuestion >= 22 && this.activeQuestion <= 25 ? 0 : (this.activeQuestion >= 26 && this.activeQuestion <= 29 ? 1 : (this.activeQuestion >= 30 && this.activeQuestion <= 33 ? 2 : this.activeQuestion >= 34 && this.activeQuestion <= 36 ? 3 : 4)))">
        </QNetworkCharacteristics>
        <QOrganizationalGovernance v-if="activeQuestion >= 40 && activeQuestion <= 54"
          :active-question="this.activeQuestion"
          :activeSub="(this.activeQuestion >= 40 && this.activeQuestion <= 44 ? 0 : (this.activeQuestion >= 45 && this.activeQuestion <= 47 ? 1 : (this.activeQuestion >= 48 && this.activeQuestion <= 51 ? 2 : 3)))">
        </QOrganizationalGovernance>
        <QOrganizationalArchitecture v-if="activeQuestion >= 55 && activeQuestion <= 63"
          :active-question="this.activeQuestion"
          :activeSub="(this.activeQuestion >= 55 && this.activeQuestion <= 57 ? 0 : (this.activeQuestion >= 58 && this.activeQuestion <= 60 ? 1 : 2))">
        </QOrganizationalArchitecture>
        <QTechnicalITInfrastructure v-if="activeQuestion >= 64 && activeQuestion <= 83"
          :active-question="this.activeQuestion"
          :activeSub="(this.activeQuestion >= 64 && this.activeQuestion <= 67 ? 0 : (this.activeQuestion >= 68 && this.activeQuestion <= 71 ? 1 : (this.activeQuestion >= 72 && this.activeQuestion <= 75 ? 2 : this.activeQuestion >= 76 && this.activeQuestion <= 79 ? 3 : 4)))">
        </QTechnicalITInfrastructure>
        <QHumanITInfrastructure v-if="activeQuestion >= 84 && activeQuestion <= 97"
          :active-question="this.activeQuestion"
          :activeSub="(this.activeQuestion >= 84 && this.activeQuestion <= 88 ? 0 : (this.activeQuestion >= 89 && this.activeQuestion <= 92 ? 1 : 2))">
        </QHumanITInfrastructure>
        <QOrganizationalCulture v-if="activeQuestion >= 98 && activeQuestion <= 109"
          :active-question="this.activeQuestion"
          :activeSub="(this.activeQuestion >= 98 && this.activeQuestion <= 100 ? 0 : (this.activeQuestion >= 101 && this.activeQuestion <= 104 ? 1 : 2))">
        </QOrganizationalCulture>
        <QHumanKnowledgeandSkills v-if="activeQuestion >= 110 && activeQuestion <= 116"
          :active-question="this.activeQuestion"
          :activeSub="(this.activeQuestion >= 110 && this.activeQuestion <= 113 ? 0 : 1)"></QHumanKnowledgeandSkills>
        <QSRLCapabilities v-if="activeQuestion >= 117 && activeQuestion <= 149" :active-question="this.activeQuestion"
          :activeSub="(this.activeQuestion >= 117 && this.activeQuestion <= 128 ? 0 : (this.activeQuestion >= 129 && this.activeQuestion <= 139 ? 1 : 2))">
        </QSRLCapabilities>

        <div class="buttonsQuestionaire flex">
          <div v-if="activeQuestion > 9">
            <IciButton id="prev"
              :content="constructLabel(buttonPreviousContent, $t('questionnaireForm.btnLabelPrevious'))"
              @buttonClick="previous()" btnStyle="width: fit-content; height: auto; padding: 18px 24px!important;">
            </IciButton>
          </div>
          <div v-if="activeQuestion < 149">
            <IciButton id="next" :content="constructLabel(buttonNextContent, $t('questionnaireForm.btnLabelNext'))"
              @buttonClick="next()" btnStyle="width: fit-content; height: auto; padding: 18px 24px!important;">
            </IciButton>
          </div>
          <div id="submit" v-if="activeQuestion >= 149" style="align-self: flex-end;">
            <IciButton id="submit" :content="$t('questionnaireForm.btnLabelSubmit')" @buttonClick="submit()"
              btnStyle="width: fit-content; height: auto; padding: 18px 24px!important;"></IciButton>
          </div>
        </div>

      </div>
      <div class="m-0" style=" flex-direction: column; width: fit-content; margin-left: auto; padding: 24px 24px;">
        <p class="m-0 mb-2 font-bold">Progress</p>
        <Knob class="knob flex" style="justify-content: center;" :model-value="User.get().invitation.response.progress()" valueColor="#5EBCB8" valueTemplate="{value}%" />
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import Steps from 'primevue/steps';
import QDemographics from "@/components/QDemographics.vue";
import QEnvironmentalFactors from "@/components/QEnvironmentalFactors.vue";
import QNetworkCharacteristics from "@/components/QNetworkCharacteristics.vue";
import QOrganizationalGovernance from "@/components/QOrganizationalGovernance.vue";
import QOrganizationalArchitecture from "@/components/QOrganizationalArchitecture.vue";
import QTechnicalITInfrastructure from "@/components/QTechnicalITInfrastructure.vue";
import QHumanITInfrastructure from "@/components/QHumanITInfrastructure.vue";
import QOrganizationalCulture from "@/components/QOrganizationalCulture.vue";
import QHumanKnowledgeandSkills from "@/components/QHumanKnowledgeandSkills.vue";
import QSRLCapabilities from "@/components/QSRLCapabilities.vue";
import QClosing from "@/components/QClosing.vue"; // Import the Steps component from PrimeVue
import { User } from "@/data/User";
import { Application } from "@/data/Application";
import { ref } from "vue";
import { Navigation } from "@/data/Navigation";
import SubmitButton from "@/components/button/SubmitButton.vue";
import IciButton from "@/components/IciButton.vue";
import { AuthService } from "@/service/AuthService";


@Options({
  computed: {
    Application() {
      return Application
    },
    User() {
      return User
    }
  },
  components: {
    IciButton,
    QClosing,
    QSRLCapabilities,
    QHumanKnowledgeandSkills,
    QOrganizationalCulture,
    QHumanITInfrastructure,
    QTechnicalITInfrastructure,
    QOrganizationalArchitecture,
    QOrganizationalGovernance,
    QNetworkCharacteristics,
    QEnvironmentalFactors,
    QDemographics,
    HelloWorld,
    Steps, // Add the Steps component to the Vue instance
  },  
  watch: {
    activeQuestion(newValue, oldValue) {
      console.log('Active question changed. New value:', newValue);
      // Do whatever you need to do when the active question changes
    }
  },
})
export default class QuesionnaireView extends Vue {
  // Add a data model for the Steps component
  private active = 0;
  private activeQuestion = 9;

  private buttonNextContent = "<label>$label</label><i class='pi pi-chevron-right' style='font-size: 1rem'></i>";
  private buttonPreviousContent = "<i class='pi pi-chevron-left' style='font-size: 1rem'></i><label>$label</label>";

  private views = [
    {
      view: "QSRLCapabilities", index: 9, questions: [],
      steps: [
        {
          id: "Sensing Capabilities Measurement",
          questions: [117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128]
        },
        {
          id: "Responding Capabilities Measurement",
          questions: [129, 130, 131, 131, 132, 133, 134, 135, 136, 137, 138, 139]
        },
        { id: "Learning Capabilities Measurement", questions: [140, 141, 142, 143, 144, 145, 146, 147, 148, 149] }]
    },
    {
      view: "QHumanKnowledgeandSkills", index: 8, questions: [],
      steps: [
        { id: "Knowledge (tacit), skills variety and redeployability", questions: [110, 111, 112, 113] },
        { id: "Speed of acquiring and developing new skills, or dynamic specialization", questions: [114, 115, 116] }]
    },
    {
      view: "QOrganizationalCulture", index: 7, questions: [],
      steps: [
        { id: "Leadership by coaching", questions: [98, 99, 100] },
        { id: "Fostering a culture of knowledge sharing and learning", questions: [101, 102, 103, 104] },
        { id: "Interactions among employees", questions: [105, 106, 107, 108, 109] }]
    },
    {
      view: "QHumanITInfrastructure", index: 6, questions: [],
      steps: [
        { id: "Technology management", questions: [84, 85, 86, 87, 88] },
        { id: "Management skills", questions: [89, 90, 91, 92] },
        { id: "Technical skills", questions: [93, 94, 95, 96, 97] }]
    },
    {
      view: "QTechnicalITInfrastructure", index: 5, questions: [],
      steps: [
        { id: "Connectivity", questions: [64, 65, 66, 67] },
        { id: "Compatibility", questions: [68, 69, 70, 71] },
        { id: "Modularity", questions: [72, 73, 74, 75] },
        { id: "Reusability", questions: [76, 77, 78, 79] },
        { id: "Data Sharing", questions: [80, 81, 82, 83] }]
    },
    {
      view: "QOrganizationalArchitecture", index: 4, questions: [],
      steps: [
        { id: "Processes standardization", questions: [55, 56, 57] },
        { id: "Modularity", questions: [58, 59, 60] },
        { id: "Customization of products and services", questions: [61, 62, 63] }]
    },
    {
      view: "QOrganizationalGovernance", index: 3, questions: [],
      steps: [
        { id: "Empowerment", questions: [40, 41, 42, 43, 44] },
        { id: "Rewards", questions: [45, 46, 47] },
        { id: "Performance measure", questions: [48, 49, 50, 51] },
        { id: "Flexible budgeting", questions: [52, 53, 54] }]
    },
    {
      view: "QNetworkCharacteristics", index: 2, questions: [],
      steps: [
        { id: "Relationships based on trust", questions: [22, 23, 24, 25] },
        { id: "Performance metrics, measurement and benchmarking", questions: [26, 27, 28, 29] },
        { id: "Process integration and collaborative work", questions: [30, 31, 32, 33] },
        { id: "Knowledge sharing", questions: [34, 35, 36] },
        { id: "Quick connect and disconnect", questions: [37, 38, 39] }]
    },
    {
      view: "QEnvironmentalFactors", index: 1, questions: [],
      steps: [
        { id: "Environmental Hostility", questions: [10, 11, 12, 13, 14] },
        { id: "Environmental Dynamism", questions: [15, 16, 17, 18] },
        { id: "Environmental Complexity", questions: [19, 20, 21] }]
    },
    { view: "QDemographics", index: 0, steps: [], questions: [1, 2, 3, 4, 5, 6, 7, 8, 9] }
  ];

  mounted() {
    AuthService.validate();
    Application.instance.setActiveView(Navigation.QUESTIONNAIRE_VIEW);
    Application.instance.setQuestionsSubProgress(1);
    // this.$i18n.locale = User.get().profile.language;
  }

  private select = (question: number) => {
    this.activeQuestion = question;
  }

  private next = () => {
    this.activeQuestion = this.activeQuestion + 1;
  }

  private previous = () => {
    this.activeQuestion = this.activeQuestion - 1;
  }

  private existQuestionInSection = (arr: number[], index: number) => {
    for (let num of arr) {
      if (num == index) {
        return true;
      }
    }
    return false;
  }

  private submit = () => {
    User.get().invitation.response.submit();
  }

  private constructLabel(content: String, label: string) {
    return content.replace("$label", label);
  }
}

</script>

<style scoped>


.panel {
  padding: 90px 0!important;
  gap: 0;
}

</style>

<style>

#app {
  height: auto!important;
}

.p-stepper-header[data-p-active="false"] .p-stepper-title {
  display: none;
}

.questionCount {
  margin-left: 3em;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  gap: .5em;
  position: absolute;
  top: 0;
  right: 0;
}

.p-stepper-nav {
  align-items: baseline!important;
}

.p-stepper-number {
  font-size: 13px;
}

.p-stepper {
  display: flex;
  width: 100%!important;
  gap: 80px
}

.p-stepper-nav {
  padding-right: 0!important;
  overflow-y: hidden!important;
  padding: 0 0 1em 0;
  width: 85%;
}

.p-stepper .p-stepper-header:has(~ .p-highlight) .p-stepper-separator {
  background-color: #0d3d52!important;
}

.p-stepper-nav::-webkit-scrollbar {
  height: 5px;
  border-radius: 10px;
}

.p-stepper-nav::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.p-stepper-nav::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.p-stepper-nav::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}

.p-stepper-header {
  padding: 0;
}

.p-stepper-content {
  margin-left: 0!important;
}

.p-stepper-panels {
  padding: 0;
}

.p-stepper-panels > div {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.buttonsQuestionaire {
  justify-content: space-between;
}

.buttonsQuestionaire > *:only-child {
  margin-left: auto;
}

.knob svg {
  display: flex;
  width: 75%;
  height: fit-content;
}

.app-panel {
  position: static!important;
}

.panel {
  font-family: "Public Sans";
  height: 100%;
  padding: 90px 64px 64px 64px;
}

.stepper {
  font-family: "Public Sans";
  top: 35px;
  left: 104px;
  bottom: 0px;
  width: 240px;
  text-align: right;
  max-width: 35vw;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.questions-panel-old {
  font-family: "Public Sans";
  top: 0px;
  left: 344px;
  bottom: 0px;
  right: 0px;
  padding-top: 20px;
  background-color: #ffffff;
}

.questions-panel {
  font-family: "Public Sans";
  top: 40px;
  background-color: #ffffff;
  left: 344px;
  right: 50px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 40px 64px;
  border-left-style: solid;
  border-left-width: 8px;
  border-left-color: #0D3D52;
  box-shadow: 5px 10px 8px #EEEEEE;
  width: 65vw;
}

.flow-item {
  font-family: "Public Sans";
  cursor: pointer;
  height: 56px;
  margin-right: -1px;
  position: relative;
}

.flow-item img {
  position: absolute;
  right: 0;
}

.button-bar {
  font-family: "Public Sans";
  text-align: left;
  padding-left: 112px;
}

.p-progressbar {
  font-family: "Public Sans";
}

.p-progressbar .p-progressbar-value {
  font-family: "Public Sans";
  background-color: #5EBCB8 !important;
}

.p-progressbar-label {
  font-size: 10px;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  background: #FF6347 !important;
  /* This changes the background of the selected radio */
  border-color: #FF6347 !important;
  /* This changes the border of the selected radio */
}

/* Modifying the color of checked icon */
.p-radiobutton .p-radiobutton-box.p-highlight .p-radiobutton-icon {
  font-family: "Public Sans";
  color: #FFFFFF !important;
  /* This changes the icon color of the selected radio */
}
</style>
