export class RegisterModel {

    public firstName: string = "";
    public lastName: string = "";
    public email: string = "";
    public department: string = "";
    public password: string = "";
    public passwordConfirm: string = "";

    public register = () => {
        // todo axion register api call
    }
}
