<template>
  <!-- <div class="grid">
    <div class="col-1">
      <div class="label-number">&nbsp;</div>
    </div>
    <div class="col-11">
      <div class="grid">
        <div class="col-12">
          <div class="paragraph" v-html="$tc('questionnaireForm.titleEnvironmentalFactors')"/>
        </div>
      </div>
    </div>
  </div> -->

  <div class="m-0 h-full" style="justify-content: space-between">
    <div class="flex w-full h-full" style="position: relative">

      <div class="questionCount">
        <span>Question</span>
        <div style='font-weight: 700;'>
          <span id='currentQuestion'>{{ activeQuestion - 9 }}</span>
          <span style='font-weight: 400;'>/</span>
          <span id='allQuestions'>12</span>
        </div>
      </div>

      <Stepper linear unstyled: true v-model:active-step="activeSub">

        <StepperPanel :header="$tc('questionnaireForm.titleEnvironmentalHostility')">

          <QuestionRating v-if="activeQuestion == 10" question="10" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 11" question="11" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 12" question="12" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 13" question="13" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 14" question="14" :change="autoSave"></QuestionRating>
        </StepperPanel>

        <StepperPanel :header="$tc('questionnaireForm.titleEnvironmentalDynamism')">
          <QuestionRating v-if="activeQuestion == 15" question="15" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 16" question="16" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 17" question="17" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 18" question="18" :change="autoSave"></QuestionRating>
        </StepperPanel>

        <StepperPanel :header="$tc('questionnaireForm.titleEnvironmentalComplexity')">
          <QuestionRating v-if="activeQuestion == 19" question="19" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 20" question="20" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 21" question="21" :change="autoSave"></QuestionRating>
        </StepperPanel>

      </Stepper>

    </div>
  </div>

</template>

<script lang="ts">
// @ is an alias to /src

import { Options, Vue } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import axios from 'axios';
import { User } from "@/data/User";
import { Application } from "@/data/Application";
import { AuthService } from "@/service/AuthService";
import { ref } from "vue";

import { defineComponent, PropType } from 'vue';
import QuestionRating from "@/components/questionnaire/QuestionRating.vue";

export default defineComponent({
  inheritAttrs: false,
  components: { QuestionRating },
  data() {
    return {
      questionNumber: 1 as number
    }
  },
  computed: {
    User() {
      return User
    },
    Application() {
      return Application
    }
  },
  props: {
    activeQuestion: {
      type: Number,
      required: true
    },
    activeSub: {
      type: Number,
      required: true
    }
  },
  watch: {
    activeQuestion(newValue, oldValue) {
      console.log('Active question changed. New value:', newValue);
      // Do whatever you need to do when the active question changes
    }
  },
  methods: {
    autoSave: () => {
      User.get().invitation.response.save();
    }
  },
  setup() {
  },
})


</script>

<style>
.label-number {
  height: 30px;
  line-height: 30px;
  text-align: right;
}

.question-field {
  text-align: left;
  min-height: 30px;
  line-height: 30px;
}

.paragraph {
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  padding-left: 45px;
}

.sub-paragraph {
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.spacer {
  padding-top: 20px;
}

.p-stepper .p-stepper-header.p-highlight .p-stepper-number {
  background: #0d3d52;
  color: #ffffff;
}

.p-stepper .p-stepper-panels {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

.stepper-offset-horizontal {
  margin-left: 78px;
}

.p-stepper {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.p-slider-range {
  background-color: #5EBCB8;
}

.p-slider .p-slider-handle {
  background-color: #0d3d52;
  border-style: none;
}
</style>