<template>
  <Button @mouseover.native="hover = true" @mouseleave.native="hover = false" @click="onClick()"
          class="button-default header-button" style="width: 97px; cursor: pointer;">
    <img v-if="!selected" src="../../assets/buttons/radio-notselected.png" height="20" style="height: 20px !important;"/>
    <img v-else src="../../assets/buttons/radio-selected.png" height="20" style="height: 20px !important;"/>
  </Button>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    click: {
      type: Function,
      required: true
    },
    selected: {
      type: Boolean
    }
  },
  data() {
    return {
      hover: false
    }
  },
  methods: {
    onClick: () => {
      click();
    }
  },
  setup(props) {
    click = props.click;
  },
});
let click: Function;
</script>
<style scoped>
:focus, :focus-visible, *[data-focus] {
  box-shadow: none !important;
  outline: none !important;
  border-color: inherit !important;
  border-style:none;
}
.header-button{
  border-style:none!important;
}

</style>
