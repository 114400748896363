import {Content} from "@/data/Content";
import axios from "axios";
import {AuthRoleModel} from "@/data/AuthRoleModel";
import ApplicationView from "@/views/ApplicationView.vue";
import {LanguageModel} from "@/data/LanguageModel";
//import {useI18n} from "vue-i18n";

export class Application {
    public static instance : Application = new Application();

    public application: ApplicationView|null = null;
    public activeView: number = 0;
    public questionsProgress: number = 0;
    public questionsSubProgress: number = 0;
    public questionsNumber: number = 0;
    public content: Content = new Content();
    public apiEndPoint :string = "https://ici.dev.ybinspire.com/api";
    // public apiEndPoint :string = "http://localhost:1000/api";
    public levelItems: AuthRoleModel[] = [];
    public grossRevenueItems: AuthRoleModel[] = [];
    public grossExecutiveReportItems: AuthRoleModel[] = [];
    public nummberOfEmployeesItems: AuthRoleModel[] = [];
    public businessActivityItems: AuthRoleModel[] = [];
    public languages: LanguageModel[] = [
        LanguageModel.create('en', 'English'),
        LanguageModel.create('fr', 'French')
    ];

    public setActiveView = (view: number) => {
        this.activeView = view;
        // @ts-ignore
        this.application.update();
    }

    public setProgress = (index: number) => {
        this.questionsProgress = index;
    }
    public setSubProgress = (index: number) => {
        this.questionsSubProgress = index;
    }

    public setQuestionsSubProgress = (index: number) => {
        this.questionsNumber = index;
    }

    public create = (application :ApplicationView) => {
        this.application = application;
        this.getRefData();
    }
    public getRefData() {
        this.getLevelsRef();
        this.getNumberOfEmployeesRef();
        this.getExecutiveReportRef();
        this.getGrossRevenueRef();
        this.getBusinessActivitiesRef();
    }

    public findBusinessActivity = (id: number | null) => {
        if(id != null) {
            for(let businessActivity of this.businessActivityItems) {
                if(businessActivity.id == id) {
                    return businessActivity.name;
                }
            }
        }
        return "";
    }

    private getLevelsRef = () => {
        axios.get(this.apiEndPoint + '/ref/job-level')
            .then(response => {
                this.levelItems = response.data;
            })
            .catch(err => {
                if(err.response.status == 403) {
                    localStorage.removeItem("token");
                }
            });
    }
    private getGrossRevenueRef = () => {
        axios.get(this.apiEndPoint + '/ref/gross-revenue')
            .then(response => {
                this.grossRevenueItems = response.data;
            })
            .catch(err => {
                if(err.response.status == 403) {
                    localStorage.removeItem("token");
                }
            });
    }
    private getExecutiveReportRef = () => {
        axios.get(this.apiEndPoint + '/ref/executive-report')
            .then(response => {
                this.grossExecutiveReportItems = response.data;
            })
            .catch(err => {
                if(err.response.status == 403) {
                    localStorage.removeItem("token");
                }
            });
    }
    private getNumberOfEmployeesRef = () => {
        axios.get(this.apiEndPoint + '/ref/number-of-employees')
            .then(response => {
                this.nummberOfEmployeesItems = response.data;
            })
            .catch(err => {
                if(err.response.status == 403) {
                    localStorage.removeItem("token");
                }
            });
    }

    private getBusinessActivitiesRef = () => {
        axios.get(this.apiEndPoint + '/ref/business-activity')
            .then(response => {
                this.businessActivityItems = response.data;
            })
            .catch(err => {
                if(err.response.status == 403) {
                    localStorage.removeItem("token");
                }
            });
    }

    public getLevel = (id: number | null): string => {
        for(const item of this.levelItems) {
            if(item.id == id) {
                return item.name;
            }
        }
        return "Not Specified";
    }
    public getNumberOfEmployee = (id: number | null): string => {
        for(const item of this.nummberOfEmployeesItems) {
            if(item.id == id) {
                return item.name;
            }
        }
        return "Not Specified";
    }
    public getGrossRevenue = (id: number | null): string => {
        for(const item of this.grossRevenueItems) {
            if(item.id == id) {
                return item.name;
            }
        }
        return "Not Specified";
    }
    public getExecutiveReport = (id: number | null): string => {
        for(const item of this.grossExecutiveReportItems) {
            if(item.id == id) {
                return item.name;
            }
        }
        return "Not Specified";
    }

}
