<template>
  <!-- div class="grid">
    <div class="col-1">
      <div class="label-number">&nbsp;</div>
    </div>
    <div class="col-11">
      <div class="grid">
        <div class="col-12">
          <div class="paragraph title-margin" v-html="$tc('questionnaireForm.titleOrganizationalArchitecture')"/>
        </div>
      </div>
    </div>
  </div -->
  
  <div class="m-0 h-full" style="justify-content: space-between">
    <div class="flex w-full h-full" style="position: relative">

      <div class="questionCount">
        <span>Question</span>
        <div style='font-weight: 700;'>
          <span id='currentQuestion'>{{ activeQuestion - 54 }}</span>
          <span style='font-weight: 400;'>/</span>
          <span id='allQuestions'>9</span>
        </div>
      </div>
    
    <Stepper linear unstyled: true v-model:active-step="activeSub">

      <StepperPanel :header="$tc('questionnaireForm.titleProcessesStandardization')">
        <QuestionRating v-if="activeQuestion == 55" question="55" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 56" question="56" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 57" question="57" :change="autoSave"></QuestionRating>
      </StepperPanel>

      <StepperPanel :header="$tc('questionnaireForm.titleModularity')">
        <QuestionRating v-if="activeQuestion == 58" question="58" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 59" question="59" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 60" question="60" :change="autoSave"></QuestionRating>
      </StepperPanel>

      <StepperPanel :header="$tc('questionnaireForm.titleCustomizationOfProductsAndServices')">
        <QuestionRating v-if="activeQuestion == 61" question="61" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 62" question="62" :change="autoSave"></QuestionRating>
        <QuestionRating v-if="activeQuestion == 63" question="63" :change="autoSave"></QuestionRating>
      </StepperPanel>

    </Stepper>
    
    </div>
  </div>

</template>


<script lang="ts">
// @ is an alias to /src

import {Options, Vue} from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import axios from 'axios';
import {User} from "@/data/User";
import {Application} from "@/data/Application";
import {AuthService} from "@/service/AuthService";
import {ref} from "vue";

import { defineComponent, PropType } from 'vue';
import QuestionRating from "@/components/questionnaire/QuestionRating.vue";

export default defineComponent({
  components: {QuestionRating},
  computed: {
    User() {
      return User
    },
    Application() {
      return Application
    }
  },
  props: {
    activeQuestion: {
      type: Number,
      required: true
    },
    activeSub: {
      type: Number,
      required: true
    }
  },
  methods: {
    autoSave: () => {
      User.get().invitation.response.save();
    }
  },
  setup() {
  },
})
</script>
<style>

.title-margin {
  margin-left: -30px;
}
.p-stepper .p-stepper-header.p-highlight .p-stepper-number {
  background: #0d3d52;
  color: #ffffff;
}
.stepper-offset-horizontal {
  margin-left: 78px;
}

.p-stepper {
  width: 99% !important;
}

.p-stepper-content {
  margin-left: -75px;
}
.p-slider-range {
  background-color: #5EBCB8;
}
.p-slider .p-slider-handle {
  background-color: #0d3d52;
  border-style: none;
}
</style>

