<template>
  <div class="grid">
    <div class="col-1">
      <div class="label-number-1">&nbsp;</div>
    </div>
    <div class="col-11">
      <div class="paragraph">Closing</div>
    </div>
  </div>
</template>

<script lang="ts">
// @ is an alias to /sr

import {Vue} from "vue-class-component";

export default class QClosing extends Vue {
  // Add a data model for the Steps component

}
</script>
<style>

</style>

