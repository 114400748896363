import {Questionnaire} from "@/data/Questionnaire";
import axios from "axios";
import {User} from "@/data/User";
import {Response} from "@/data/Response";
import {Application} from "@/data/Application";

export class Invitation {
    public id: number|null = null;
    public email: string|null = null;
    public state: number = 0;
    public active: boolean = false;
    public questionnaire: Questionnaire = new Questionnaire();
    public response: Response = new Response();

    public startQuestionnaire = () => {

        axios.post(Application.instance.apiEndPoint + '/questionnaire/start',
            {
                id: this.id,
                email: this.email,
                state: this.state,
                active: this.active
            })
            .then(response => {
                this.state = response.data.state;
            })
            .catch(err => {
                // console.log(err);
            });
    }
}
