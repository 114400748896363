<template>
  <ItemBox id="item-n-2" :visible="visible" title="Environmental Pressure" :headerItems="headerItems"
    toggleClass="item2-toggle minus" :closeAction="closeItem">
    <div class="featured col-span-12 flex flex-col overflow-y-auto  gap-3 p-2 pb-0 ">

      <div class="col-span-12" v-for="(factor, index) in [
    {
      mean: questionnaireResults.env_FACTORS,
      agility: questionnaireResults.business_AGILITY_2,
      match: 95.61,
      capabilities: [
        {
          capability: '1',
          mean: questionnaireResults.env_HOSTILITY,
        },
        {
          capability: '2',
          mean: questionnaireResults.env_DYNAMISM,
        },
        {
          capability: '3',
          mean: questionnaireResults.env_COMPLEXITY,
        },
      ]
    }
  ]">
        <div class="grid grid-cols-12 gap-2 p-2 ">

          <div class="col-span-8 grid grid-cols-8 gap-2 py-2  ">
            <div v-for="(capability, index) in factor.capabilities" :key="capability.capability"
              class=" col-span-8 grid grid-cols-8 gap-2 pt-2 px-2">

              <div class="flex col-span-2 rounded-xl p-1 items-center justify-between" :class="getBgClass(index)">
                <div class="flex justify-center w-full">
                  <h4 class="text-start">{{ capability.capability }}</h4>
                </div>
              </div>

              <div class="flex col-span-1  rounded-xl py-1 px-4 items-center justify-between"
                :class="getBgClass(index)">
                <div class="flex justify-center w-full">
                  <h4>{{ capability.mean }}</h4>
                </div>
              </div>

              <div class="flex flex-col h-full bg-[#fbfaff] rounded-full col-span-5 gap-2">
                <div class="resultProgressBar rounded-full h-full barTransition"
                  :style="calculateBorder(capability.mean)"></div>
              </div>
            </div>
          </div>

          <div class="flex col-span-2 rounded-xl ml-2 py-1 px-4 items-center justify-between"
            :class="getBgClass(index)">
            <div class="flex w-full justify-center ">
              <h4 class="text-start">{{ factor.mean }}</h4>
            </div>
          </div>

          <div class="flex col-span-2 rounded-xl p-1 items-center justify-between border-2 border-[#aedddb]">
            <div class="flex justify-center items-center w-full gap-2">
              <div
                class="flex items-center rateBg h-fit p-2 bg-[#2d7e3c] text-white rounded-xl leading-4 font-semibold text-center">
                {{ factor.agility }}
              </div>
              <div
                class="flex items-center rateBg2 p-2 bg-[#2d7e3c] text-white rounded-xl leading-4 font-semibold text-center">
                {{ factor.match }} %
                MATCH -
              </div>

            </div>
          </div>
        </div>



      </div>
    </div>

  </ItemBox>
</template>

<script>
import ItemBox from '@/components/results/ItemBox.vue'

export default {
  components: {
    ItemBox
  },
  data() {
    return {
      headerItems: [
        { name: 'Capabilities', class: 'col-span-2' },
        { name: 'Mean', class: 'col-span-1' },
        { name: 'Scale', class: 'col-span-5', scale: true },
        { name: 'Mean Alignment', class: 'col-span-2' },
        { name: 'ICI2', class: 'col-span-2 ', color: 'border border-[#aedddb] bg-white' },
      ]
    }
  },
  props: {
    calculateBorder: {
      type: Function,
      required: true
    },
    questionnaireResults: {
      type: Array,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    closeAction: {
      type: Function,
      required: true
    },
    getBgClass: {
      type: Function,
      required: true
    }
  },
  methods: {
    closeItem() {
      this.closeAction();
    }
  }
};
</script>
