<template>
  <!-- div class="grid">
    <div class="col-1">
      <div class="label-number">&nbsp;</div>
    </div>
    <div class="col-11">
      <div class="grid">
        <div class="col-12">
      <div class="paragraph title-margin" v-html="$tc('questionnaireForm.titleOrganizationalGovernance')"/>
        </div>
      </div>
    </div>
  </div -->

  <div class="m-0 h-full" style="justify-content: space-between">
    <div class="flex w-full h-full" style="position: relative">

      <div class="questionCount">
        <span>Question</span>
        <div style='font-weight: 700;'>
          <span id='currentQuestion'>{{ activeQuestion - 39 }}</span>
          <span style='font-weight: 400;'>/</span>
          <span id='allQuestions'>15</span>
        </div>
      </div>

      <Stepper linear unstyled: true v-model:active-step="activeSub">

        <StepperPanel :header="$tc('questionnaireForm.titleEmpowerment')">
          <QuestionRating v-if="activeQuestion == 40" question="40" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 41" question="41" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 42" question="42" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 43" question="43" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 44" question="44" :change="autoSave"></QuestionRating>
        </StepperPanel>

        <StepperPanel :header="$tc('questionnaireForm.titleRewards')">
          <QuestionRating v-if="activeQuestion == 45" question="45" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 46" question="46" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 47" question="47" :change="autoSave"></QuestionRating>
        </StepperPanel>

        <StepperPanel :header="$tc('questionnaireForm.titlePerformanceMeasure')">
          <QuestionRating v-if="activeQuestion == 48" question="48" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 49" question="49" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 50" question="50" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 51" question="51" :change="autoSave"></QuestionRating>
        </StepperPanel>

        <StepperPanel :header="$tc('questionnaireForm.titleFlexibleBudgeting')">
          <QuestionRating v-if="activeQuestion == 52" question="52" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 53" question="53" :change="autoSave"></QuestionRating>
          <QuestionRating v-if="activeQuestion == 54" question="54" :change="autoSave"></QuestionRating>
        </StepperPanel>

      </Stepper>

    </div>
  </div>

</template>

<script lang="ts">
// @ is an alias to /src

import { Options, Vue } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import axios from 'axios';
import { User } from "@/data/User";
import { Application } from "@/data/Application";
import { AuthService } from "@/service/AuthService";
import { ref } from "vue";

import { defineComponent, PropType } from 'vue';
import QuestionRating from "@/components/questionnaire/QuestionRating.vue";

export default defineComponent({
  components: { QuestionRating },
  computed: {
    User() {
      return User
    },
    Application() {
      return Application
    }
  },
  props: {
    activeQuestion: {
      type: Number,
      required: true
    },
    activeSub: {
      type: Number,
      required: true
    }
  },
  methods: {
    autoSave: () => {
      User.get().invitation.response.save();
    }
  },
  setup() {
  },
})
</script>
<style>
.title-margin {
  margin-left: -30px;
}

.p-stepper .p-stepper-header.p-highlight .p-stepper-number {
  background: #0d3d52;
  color: #ffffff;
}

.stepper-offset-horizontal {
  margin-left: 78px;
}

.p-stepper {
  width: 99% !important;
}

.p-stepper-content {
  margin-left: -75px;
}

.p-slider-range {
  background-color: #5EBCB8;
}

.p-slider .p-slider-handle {
  background-color: #0d3d52;
  border-style: none;
}
</style>
