import {LoginModel} from "@/data/LoginModel";
import axios from "axios";
import {User} from "@/data/User";
import {AuthRoleModel} from "@/data/AuthRoleModel";
import {Application} from "@/data/Application";

export class AuthService {

    private static loginModel: LoginModel|null = null;
    public static token :string|null = null;
    public static roles :AuthRoleModel[] = [];
    public static currentRole :AuthRoleModel = new AuthRoleModel();
    private static application :any|null = null;

    public static selectRole = (id: number | null) => {
        // @ts-ignore
        for (let role:AuthRoleModel of this.roles){
            if(role.id == id) {
                AuthService.currentRole = role;
            }
        }
    }

    public static create = (application :any) :AuthService => {
        this.application = application;
        return this;
    }

    public static getModel = () => {
        if (this.loginModel == null) {
            this.loginModel = new LoginModel();
        }
        return this.loginModel;
    }

    public static getToken = () => {
        if(!this.isLoggedIn() && localStorage.getItem('token') != null) {
            this.token = localStorage.getItem('token');
            this.validate();
        }
        return this.token;
    }

    private static setToken = (token :string) => {
        localStorage.setItem('token', token);
        this.token = token;
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    public static isLoggedIn = () => {
        return this.token != null;
    }

    public static logOut = () => {
        this.token = null;
        localStorage.removeItem("token");
        this.application.update();
    }

    public static login = () => {

        axios.post(Application.instance.apiEndPoint + '/auth/user/auth', this.getModel()).then(response => {
            if(response.data.token == null) {
                this.logOut();
            } else {
                this.setToken(response.data.token);
                User.fill(response);
                this.fillRoles(response);
                this.application.update();
            }
        })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    this.logOut();
                }
                this.logOut();
            });
    }

    public static validate = () => {
        if(this.getToken() != null) {
            axios.post(Application.instance.apiEndPoint + '/auth/user/validate', {token: this.getToken()})
                .then(response => {
                    this.setToken(response.data.token);
                    User.fill(response);
                    this.fillRoles(response);
                    this.application.update();
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        this.logOut();
                    }
                    this.logOut();
                });
        } else {
            this.application.update();
        }
    }

    private static fillRoles = (response :any) => {
        this.roles = [];
        for (let role in Object.values(response.data.user.authRoles)) {
            let o = {
                id: response.data.user.authRoles[role].id,
                name: response.data.user.authRoles[role].name
            };
            //this.role = o;
            this.roles.push(
                AuthRoleModel
                    .create(
                        response.data.user.authRoles[role].id,
                        response.data.user.authRoles[role].name));
        }
        if(User.get().profile.defaultRole != null) {
            AuthService.selectRole(User.get().profile.defaultRole);
        } else {
        if (this.roles.length > 0) {
            this.currentRole = this.roles[0];
        }
        }
    }
}
