<template>
  <div class="project-dashboard-content">
    <div class="flex" style="align-items: center; justify-content: space-between;margin-left:30px;">
      <div class="welcome-title-consultant-project-dashboard">
        <span v-if="selectedClient.id != 0" v-html="selectedClient.name"/>
        <span v-if="selectedClient.id == 0" v-html="$t('companyForm.titleAddNewClient')"/>
      </div>

      <div class="back-to-all-clients">
        <IciButton id="backToDashboard"
                   :content="constructLabel(buttonBackToAllClients, $t('companyForm.buttonBackToDashboard'))"
                   @buttonClick="backToClientDashboard(selectedClient.id)"
                   btnStyle="width: fit-content; height: auto; padding: 18px 24px!important; top: 32px; position: static;"></IciButton>
      </div>
    </div>

    <div class="project-dashboard-block">
      <div style="position: relative; width: 100%; height: 100%">
        <div style="position: absolute; top: 40px; left: 40px; right: 40px; height: 50px; border-radius: 10px; background-color: #fbfaff; font-family: 'Public Sans'; font-weight: bold; text-align: left; padding-left: 40px; line-height: 50px;" v-html="$t('companyForm.titleDashboard') + ' > ' + $t('companyForm.titleProjectOverview')"/>

        <div style="position:absolute; top: 140px; bottom: 100px; left: 40px; right: 40px; min-width: calc(100% - 110px); border-radius: 10px; min-height: 90px; background-color: #ffffff;">
          <div style="position: relative; width: 100%; height: 100%;">
            <div style="position: absolute; top: 0px; left: 0px; right: 0px; height: 50px; background-color: #F3F4F8; border-radius: 10px; min-width: 1000px;">
              <div style="position: relative; width: 100%; height: 100%; min-width: 1000px">
                <div style="float: left; width: 40%; min-width: 300px; font-family: 'Public Sans'; font-weight: bold; font-size: 16px; line-height: 50px; text-align: left; padding-left: 30px;" v-html="$t('companyForm.tableHeaderActiveProject')"></div>
                <div style="float: left; width: 30%; min-width: 300px; font-family: 'Public Sans'; font-weight: bold; font-size: 16px; line-height: 50px; text-align: left;" v-html="$t('companyForm.tableHeaderProgressOverview')"></div>
                <div style="float: left; width: 30%; min-width: 300px; font-family: 'Public Sans'; font-weight: bold; font-size: 16px; line-height: 50px; text-align: left;" v-html="$t('companyForm.tableHeaderProjectStatus')"></div>
              </div>
            </div>
            <div style="position: absolute; top: 65px; left: 0px; right: 0px; bottom: 10px;">
              <div style="position: relative; width: 100%; min-width: 1000px; height: 70px; background-color: #FFFFFF; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
                <div style="float: left; width: 40%; min-width: 300px;  font-family: 'Public Sans'; font-size: 16px; line-height: 50px; text-align: left; padding-left: 20px;">
                  <IciButton :id="'buttonClick@'"
                             :content="getQuestionnaire().name"
                             @buttonClick="projectDetails(getQuestionnaire().id)"
                             btnStyle="width: fit-content; height: auto; padding: 18px 24px!important;"></IciButton>
                </div>
                <div style="float: left; width: 30%; min-width: 300px; font-family: 'Public Sans'; font-size: 16px; line-height: 70px; text-align: left;">
                  <div style="width: 200px; height: 40px;"><ProgressBar :value="60" style="top: 15px !important;"></ProgressBar></div>
                </div>
                <div v-if="getQuestionnaire().status == QuestionnaireState.ACTIVE" style="float: left; width: 30%; min-width: 300px; width: 200px; font-family: 'Public Sans'; font-size: 16px; line-height: 70px; text-align: left; padding-top: 20px;">
                  <IciStatus :state="getQuestionnaire().status"></IciStatus>
                </div>

                <div v-else style="float: left; width: 30%; min-width: 300px; width: 200px; font-family: 'Public Sans'; font-size: 16px; text-align: left; ">
                  <IciButton :id="'buttonClick@'"
                             :content="'View Responses'"
                             @buttonClick="respondentDashboard(getQuestionnaire().id)"
                             btnStyle="width: fit-content; height: auto; padding: 18px 24px!important;"></IciButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import {Company} from "@/data/Company";
import {Application} from "@/data/Application";
import IciButton from "@/components/IciButton.vue";
import {User} from "@/data/User";
import router from "@/router";
import {useRouter} from "vue-router";
import DashboardButton from "@/components/button/DashboardButton.vue";
import ResultButton from "@/components/button/ResultButton.vue";
import SettingsButton from "@/components/button/SettingsButton.vue";
import IciStatus from "@/components/IciStatus.vue";
import {QuestionnaireState} from "@/data/QuestionnaireState";
import {Questionnaire} from "@/data/Questionnaire";
import {AuthService} from "@/service/AuthService";

export default defineComponent({
  components: {IciStatus, SettingsButton, ResultButton, DashboardButton, IciButton},
  computed: {
    QuestionnaireState() {
      return QuestionnaireState
    },
    Company() {
      return Company
    },
    Application() {
      return Application
    }
  },
  data() {
    return {
      hover: false,
      buttonAddProject: "<i class='pi pi-plus' style='font-size: 1rem'></i><label style='font-weight: bold;'>$label</label>",
      buttonBackToAllClients: "<i class='pi pi-chevron-left' style='font-size: 1rem'></i><label>$label</label>",
      buttonSaveContent: "<label>$label</label><i class='pi pi-chevron-right' style='font-size: 1rem'></i>",
      selectedClient: this.getCompany(),
      selectedQuestionnaire: this.getQuestionnaire()
    }
  },
  methods: {
    constructLabel: (content: String, label: string) => {
      return content.replace("$label", label);
    },
    getCompany():Company {
      let id: Number = Number(router.currentRoute.value.params.id);
      if(id == 0) {
        return Company.build(0, "", 0, 0, 0, 0, "");
      } else {
        return User.get().getCompany(id);
      }
    },
    getQuestionnaire() {
      for(const q of this.getCompany().questionnaireList) {
        if(q.id ==Number(router.currentRoute.value.params.qid)) {
          return q;
        }
      }
    },
    backToClientDashboard: (id: Number) => {
      // @ts-ignore
      router.push("/client/" + Number(router.currentRoute.value.params.id) + "/dashboard");
    },
    gotoResults: () => {
      router.push("/client/" + Number(router.currentRoute.value.params.id) + "/project/"  + Number(router.currentRoute.value.params.qid) +  "/results-dashboard");
    },
    gotoSetting: () => {
      router.push("/client/" + Number(router.currentRoute.value.params.id) );
    },
    respondentDashboard: (id: number | null) => {
      router.push("/client/" + Number(router.currentRoute.value.params.id) + "/project/"  + Number(router.currentRoute.value.params.qid) +  "/respondents/dashboard");
    },
    addProject: () => {
      router.push("/client/" + Number(router.currentRoute.value.params.id) + "/project/new");
    },
    projectDetails(id: number | null) {
      router.push("/client/" + Number(router.currentRoute.value.params.id) + "/project/" + id);
    },
    calculateCompletionPercentage() {
      const questionnaire = this.getQuestionnaire();
      if (!questionnaire || !questionnaire.respondents) {
        return 0;
      }

      const totalRespondents = questionnaire.respondents.length;
      const completedRespondents = questionnaire.respondents.filter(
          respondent => respondent && respondent.state && respondent.state.name === "QUESTIONNAIRE SUBMITTED"
      ).length;

      if (totalRespondents === 0) {
        return 0;
      }

      const completionPercentage = (completedRespondents / totalRespondents) * 100;
      return Math.round(completionPercentage);
    }
  },
  setup(props) {
    AuthService.validate();
  },
});
</script>

<style scoped>
.project-dashboard-content {
  font-family: "Public Sans";
  position: static;
  top: 0;
  left: 0 !important;
  bottom: 0;
  right: 0;
  padding-left: 0px;
}

.welcome-title-consultant-project-dashboard {
  font-family: "Public Sans";
  font-size: 40px;
  font-weight: bold;
  text-align: left;
  padding-top: 110px;
  padding-left: 110px;
}

.project-dashboard-block {
  position: absolute;
  top: 210px;
  left: 140px;
  width: calc(100% - 180px);
  bottom: 0px;
  background-color: #ffffff;
  border-radius: 10px !important;
}

.back-to-all-clients {
  margin-top: 130px;
  margin-right: 40px;
}

.p-progressbar {
  font-family: "Public Sans";
  top: 30px !important;
  left: 0px !important;
  width: 250px !important;
  height: 16px;
}
</style>