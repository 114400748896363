<template>
	<Button @mouseover.native="hover = true"
					@mouseleave.native="hover = false"
					@click="buttonClick()"
					:disabled="disabled"
					class="button-default header-button ici_button"
					:class="[!hover ? (dark ? 'button-normal-dark' : 'button-normal') : 'button-over']"
					:style="btnStyle">
		<div class="mx-auto" style="text-align: center; display: flex; align-items: center; gap: 8px; justify-content: center;"><label class="">Download Report</label></div>
	</Button>
</template>
<script setup lang="ts">
const emit = defineEmits(['buttonClick', 'submit'])

function buttonClick() {
	emit('buttonClick')
}
defineProps({
	selected: { type: Boolean },
	btnStyle: { type:String },
	content: { type:String },
	id: { type: String, required: true },
	dark: { type: Boolean, default: false },
	hover: { type: Boolean, default: false },
	disabled: { type: Boolean, default: false }

})
</script>
<style scoped>
:focus, :focus-visible, *[data-focus] {
	box-shadow: none !important;
	outline: none !important;
	border-color: inherit !important;
}

.ici_button {
	font-family: "Public Sans";
	font-size: 16px;
	border-radius: 10px !important;
	border-style: solid !important;
	border-width: 1px !important;
	font-weight: 700;
}

.button-normal {
	color: #0d3d52 !important;
	border-color: #0d3d52 !important;
}

.button-normal-dark {
	color: #ffffff !important;
	background-color: #0d3d52 !important;
}

.button-over {
	background-color: #5ebcb8 !important;
	border-color: #5ebcb8 !important;
	color: #1e1e1e !important;
}
</style>
