<!--Client Dashboard View-->
<template>
  <div class="client-dashboard-content">
    <div class="flex" style="align-items: center; justify-content: space-between;margin-left:30px;">

      <div class="welcome-title-consultant-client-dashboard">
        <span v-if="selectedClient.id != 0" v-html="selectedClient.name" />
        <span v-if="selectedClient.id == 0" v-html="$t('companyForm.titleAddNewClient')" />
      </div>

      <div class="back-to-all-clients-client-dashboard">
        <IciButton id="backToAllClients"
                   :content="constructLabel(buttonBackToAllClients, $t('companyForm.buttonBackToAllClients'))"
                   @buttonClick="backToAllClients()" btnStyle="width: fit-content; height: auto; padding: 18px 24px!important; top: 32px; position: static;"></IciButton>
      </div>

    </div>


    <div class="client-dashboard-block">
      <div style="position: relative; width: 100%; height: 100%">

        <div style="position: absolute; top: 40px; left: 40px; right: 40px; height: 50px; border-radius: 10px; background-color: #fbfaff; font-family: 'Public Sans'; font-weight: bold; text-align: left; padding-left: 40px; line-height: 50px;" v-html="$t('companyForm.titleDashboard')"/>

        <div style="position:absolute; top: 140px; bottom: 100px; left: 40px; right: 40px; min-width: calc(100% - 110px); border-radius: 10px; min-height: 90px; background-color: #ffffff;">
          <div style="position: relative; width: 100%; height: 100%;">
            <div style="position: absolute; top: 0px; left: 0px; right: 0px; height: 50px; background-color: #F3F4F8; border-radius: 10px; min-width: 1000px;">
              <div style="position: relative; width: 100%; height: 100%; min-width: 1000px">
                <div style="float: left; width: 40%; min-width: 300px; font-family: 'Public Sans'; font-weight: bold; font-size: 16px; line-height: 50px; text-align: left; padding-left: 30px;" v-html="$t('companyForm.tableHeaderActiveProject')"></div>
                <div style="float: left; width: 30%; min-width: 300px; font-family: 'Public Sans'; font-weight: bold; font-size: 16px; line-height: 50px; text-align: left;" v-html="$t('companyForm.tableHeaderQuestionnaireDeadline')"></div>
                <div style="float: left; width: 30%; min-width: 300px; font-family: 'Public Sans'; font-weight: bold; font-size: 16px; line-height: 50px; text-align: left;" v-html="$t('companyForm.tableHeaderProjectStatus')"></div>
              </div>
            </div>
            <div style="position: absolute; top: 65px; left: 0px; right: 0px; bottom: 10px;">
              <div v-if="questionnaireList.length > 0" v-for="questionnaire of questionnaireList" style="position: relative; width: 100%; min-width: 1000px; height: 70px; background-color: #FFFFFF; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
                <div style="float: left; width: 40%; min-width: 300px;  font-family: 'Public Sans'; font-size: 16px; line-height: 50px; text-align: left; padding-left: 20px;">
                  <IciButton v-if="questionnaire.status == QuestionnaireState.CLOSED"
                             :id="'buttonClick@'"
                             :content="questionnaire.name"
                             @buttonClick="projectDashboard(questionnaire.id)"
                             btnStyle="width: fit-content; height: auto; padding: 18px 24px!important;"></IciButton>

                  <IciButton v-else
                             :id="'buttonClick@'"
                             :content="questionnaire.name"
                             @buttonClick="projectDetails(questionnaire.id)"
                             btnStyle="width: fit-content; height: auto; padding: 18px 24px!important;"></IciButton>
                </div>
                <div style="float: left; width: 30%; min-width: 300px; font-family: 'Public Sans'; font-size: 16px; line-height: 70px; text-align: left;">{{questionnaire.deadlineFormatted}}</div>
                <div style="float: left; width: 30%; min-width: 300px; width: 200px; font-family: 'Public Sans'; font-size: 16px; line-height: 70px; text-align: left; padding-top: 20px;">
                  <IciStatus :state="questionnaire.status"></IciStatus>
                </div>
              </div>
              <div style="position: relative; width: 100%; min-width: 1000px; height: 70px; background-color: #FFFFFF; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
                <div v-if="newProject == null" style="float: left; width: 40%; min-width: 300px;  font-family: 'Public Sans'; font-size: 16px; line-height: 50px; text-align: left; padding-left: 20px;">
                  <IciButton :id="'buttonClick@'"
                             :content="constructLabel(buttonAddProject, $t('companyForm.buttonNewProject'))"
                             @buttonClick="addProject()"
                             btnStyle="width: fit-content; height: auto; padding: 18px 24px!important;"></IciButton>

                </div>
              </div>

            </div>
          </div>
        </div>

        <!-- div style="position: absolute; top: 440px; left: 40px; right: 40px; height: 50px; border-radius: 10px; background-color: #F3F4F8; font-family: 'Public Sans'; font-weight: bold; text-align: left; padding-left: 40px; line-height: 50px;"
             v-html="$t('companyForm.tableHeaderRespondents')"/ -->

        <!-- div style="position: absolute; top: 420px; left: 40px; right: 40px; height: 50px;  font-family: 'Public Sans'; font-weight: bold; text-align: left; padding-left: 40px; line-height: 50px;">
          Email
        </div -->

      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import {Company} from "@/data/Company";
import {Application} from "@/data/Application";
import IciButton from "@/components/IciButton.vue";
import {User} from "@/data/User";
import router from "@/router";
import {useRouter} from "vue-router";
import SettingsButton from "@/components/button/SettingsButton.vue";
import IciStatus from "@/components/IciStatus.vue";
import {QuestionnaireState} from "@/data/QuestionnaireState";
import {Questionnaire} from "@/data/Questionnaire";
import {AuthService} from "@/service/AuthService";
import {ConsultanService} from "@/service/ConsultanService";

export default defineComponent({
  components: {IciStatus, SettingsButton,  IciButton},
  computed: {
    QuestionnaireState() {
      return QuestionnaireState
    },
    Company() {
      return Company
    },
    Application() {
      return Application
    }
  },
  data() {
    return {
      hover: false,
      buttonAddProject: "<i class='pi pi-plus' style='font-size: 1rem'></i><label style='font-weight: bold;'>$label</label>",
      buttonBackToAllClients: "<i class='pi pi-chevron-left' style='font-size: 1rem'></i><label>$label</label>",
      buttonSaveContent: "<label>$label</label><i class='pi pi-chevron-right' style='font-size: 1rem'></i>",
      selectedClient: this.getCompany(),
      questionnaireList: []
    }

  },
  methods: {
    constructLabel: (content: String, label: string) => {
      return content.replace("$label", label);
    },
    getCompany: function()  {
      let id: Number = Number(router.currentRoute.value.params.id);
      if(id == 0) {
        return Company.build(0, "", 0, 0, 0, 0, "");
      } else {
        ConsultanService.instance.retrieveActiveQuestionnaireForClient(id, this);
        return User.get().getCompany(id);
      }
    },
    gotoClientDashboard: () => {
      // @ts-ignore
      router.push("/client/" + Number(router.currentRoute.value.params.id) + "/dashboard");
    },
    gotoResults: () => {
      router.push("/results-dashboard");
    },
    gotoSetting: () => {
      router.push("/client/" + Number(router.currentRoute.value.params.id) );
    },
    projectDetails: (id: number | null) => {
      // @ts-ignore
      router.push("/client/" + Number(router.currentRoute.value.params.id) + "/project/" + id);
    },
    projectDashboard: (id: number | null) => {
      // @ts-ignore
      router.push("/client/" + Number(router.currentRoute.value.params.id) + "/project/" + id + "/dashboard");
    },
    addProject: () => {
      router.push("/client/" + Number(router.currentRoute.value.params.id) + "/project/new");
    },
    backToAllClients: () => {
      router.push("/");
    }
  },
  setup(props) {
    AuthService.validate();
  },
});
</script>
<style>

.app-panel {
  position: static;
  top: 0;
  left: 0;
}

.client-dashboard-content {
  font-family: "Public Sans";
  position: static;
  top: 0;
  left: 0 !important;
  bottom: 0;
  right: 0;
  padding-left: 0px;
}
.welcome-title-consultant-client-dashboard {
  font-family: "Public Sans";
  font-size: 40px;
  font-weight: bold;
  text-align: left;
  padding-top: 110px;
  padding-left: 110px;
}
.client-dashboard-block {
  position: absolute;
  top: 210px;
  left: 140px;
  max-height: 75%;
  width: calc(100% - 180px);
  bottom: 0px;
  background-color: #ffffff;
  border-radius: 10px !important;
}
.back-to-all-clients-client-dashboard {
  margin-top: 130px;
  margin-right: 40px;
}
</style>
