export class UserActivityLog {
    public id: Number = 0;
    public user: Number = 0;
    public email: String = "";
    public details: String = "";
    public operation: String = "";
    public activity: String = "";
    public result: String = "";
    public exception: String = "";
    public request: String = "";
    public  timestamp: Date = new Date();
    public error: Boolean = false;
    public dateFormatted: String = "";

    public static create = (id: Number, user: Number, email: String, details: String,operation: String,activity: String,result: String,exception: String, request: String, timestamp: Date) => {
        let model = new UserActivityLog();
        model.id = id;
        model.user = user;
        model.email = email;
        model.details = details;
        model.operation = operation;
        model.activity = activity;
        model.result = result;
        model.exception = exception;
        model.request = request;
        model.timestamp = timestamp;
        model.error = (exception != "null");

        model.dateFormatted = timestamp.getDate() + "-" + (timestamp.getMonth() < 9 ? "0" : "") + (timestamp.getMonth()+1)+"-" + timestamp.getFullYear()+ " " + (timestamp.getHours() < 10 ? "0" : "") + timestamp.getHours() + ":"  + (timestamp.getMinutes() < 10 ? "0" : "") + timestamp.getMinutes() + ":" +(timestamp.getSeconds() < 10 ? "0" : "") + timestamp.getSeconds();
        return model;
    }
}