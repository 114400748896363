import axios from "axios";
import {Application} from "@/data/Application";
import {Company} from "@/data/Company";
import {Questionnaire} from "@/data/Questionnaire";
import {QuestionnaireState} from "@/data/QuestionnaireState";

export class ConsultanService {

    public static instance : ConsultanService = new ConsultanService();
    public company: Company = new Company();
    public activeQuestionnaire: Questionnaire = new Questionnaire();
    public questionnaireArchive: Questionnaire[] = [];

    public retrieveCompany = (callback: any) => {
        axios.get(Application.instance.apiEndPoint + '/consultant/company')
            .then(response => {
                this.company.id = response.data.id;
                this.company.name = response.data.name;
                this.company.businessActivityId = response.data.businessActivityId;
                this.company.grossRevenueId = response.data.grossRevenueId;
                this.company.numberOfEmployeesId = response.data.grossRevenueId;
                this.company.executiveReportId = response.data.executiveReportId;
                callback();
            })
            .catch(err => {
                callback();
            });
    }

    public saveCompany = () => {
        axios.post(Application.instance.apiEndPoint + '/consultant/company', this.company)
            .then(response => {
                this.company.id = response.data.id;
                this.company.name = response.data.name;
                this.company.businessActivityId = response.data.businessActivityId;
                this.company.grossRevenueId = response.data.grossRevenueId;
                this.company.numberOfEmployeesId = response.data.grossRevenueId;
                this.company.executiveReportId = response.data.executiveReportId;
            })
            .catch(err => {
                // console.log(err);
            });
    }

    public retrieveActiveQuestionnaire = (callback: any) => {
        axios.get(Application.instance.apiEndPoint + '/consultant/questionnaire/active')
            .then(response => {
                if(response.data.exist) {
                    this.activeQuestionnaire.id = response.data.questionnaire.id;
                    this.activeQuestionnaire.name = response.data.questionnaire.name;
                    this.activeQuestionnaire.year = response.data.questionnaire.year;
                    this.activeQuestionnaire.description = response.data.questionnaire.description;
                    this.activeQuestionnaire.active = response.data.questionnaire.active;
                    this.activeQuestionnaire.createdDt = response.data.questionnaire.createdDt;
                    callback();
                } else {
                    this.activeQuestionnaire.id = 0;
                    this.activeQuestionnaire.name = "";
                    this.activeQuestionnaire.year = 0;
                    this.activeQuestionnaire.description = "";
                    this.activeQuestionnaire.active = false;
                    this.activeQuestionnaire.createdDt = new Date();
                    callback();
                }
            })
            .catch(err => {
                // console.log(err);
                callback();
            });
    }

    public saveActiveQuestionnaire = (callback: any) => {
        axios.post(Application.instance.apiEndPoint + '/consultant/questionnaire/active', this.activeQuestionnaire)
            .then(response => {
                this.activeQuestionnaire.id = response.data.questionnaire.id;
                this.activeQuestionnaire.name = response.data.questionnaire.name;
                this.activeQuestionnaire.year = response.data.questionnaire.year;
                this.activeQuestionnaire.description = response.data.questionnaire.description;
                this.activeQuestionnaire.active = response.data.questionnaire.active;
                this.activeQuestionnaire.createdDt = response.data.questionnaire.createdDt;
                callback();
            })
            .catch(err => {
                // console.log(err);
            });
    }


    //http://localhost:1000/api/questionnaire/active/16
    public retrieveActiveQuestionnaireForClient = (id: Number, target: any) => {
        console.log(target);
        console.log(target.questionnaireList);
        console.log(Application.instance.apiEndPoint + '/questionnaire/active/'+id);
        axios.get(Application.instance.apiEndPoint + '/questionnaire/active/'+id)
            .then(response => {
                console.log(response);
                    target.questionnaireList = [];
                    for(let responseQuestionnaire of response.data) {
                        let q = new Questionnaire();
                        q.id = responseQuestionnaire.id;
                        q.name = responseQuestionnaire.name;
                        q.year = responseQuestionnaire.year;
                        q.description = responseQuestionnaire.description;
                        q.active = responseQuestionnaire.active;
                        q.createdDt = responseQuestionnaire.createdDt;
                        q.deadline = responseQuestionnaire.deadline;
                        let dateParts: string[] = ['9999','12','31'];
                        if(responseQuestionnaire.deadline != null) {
                            dateParts = responseQuestionnaire.deadline.split('-');
                        }
                        q.deadline = new Date(Number(dateParts[0]), Number(dateParts[1]) -1, Number(dateParts[2]));
                        q.deadlineFormatted = ((q.deadline.getDate()<10?'0':'')+q.deadline.getDate()) + '-' + (((q.deadline.getMonth()+1)<10?'0':'')+(q.deadline.getMonth()+1)) + '-' + q.deadline.getFullYear();
                        // @ts-ignore
                        q.status = responseQuestionnaire.active ? ((q.deadline - new Date() > 1) ? QuestionnaireState.ACTIVE : QuestionnaireState.EXPIRED) : ((q.deadline - new Date() > 1) ? QuestionnaireState.DRAFT : QuestionnaireState.CLOSED);
                        target.questionnaireList.push(q);
                    }
            })
            .catch(err => {
                console.log(err);
                target.questionnaireList = [];
            });
    }
}
