<template>
  <div class="content px-5 flex flex-col gap-4 results-dashboard-content ">
    <div class="px-2 " style="margin-top: 100px; margin-left: -15px;padding-left:0;">
      <div class="flex justify-between">
        <div class="client-details-welcome-title-consultant px-0D">
          <span>Client A</span>
        </div>

        <div class="flex gap-4">
          <!--          <download-report id="backToAllClients" btnStyle="width: 160px;"></download-report>-->
          <back-to-project-button id="backToAllClients"
            :content="constructLabel(buttonBackToProject, $t('companyForm.buttonBackToProject'))"
            @buttonClick="backToProject()" btnStyle="width: 260px;">
          </back-to-project-button>
        </div>
      </div>
      <div class="bg-white rounded-xl p-3 flex justify-start drop-shadow-md mt-3">
        <span><strong>Results</strong></span>
      </div>
    </div>

    <div class="p-0">
      <!-- ############################################################################################ -->
      <!-- ############################################################################################ -->
      <!-- ########################            START DASHBOARD CONTENT         ######################## -->
      <!-- ############################################################################################ -->
      <!-- ############################################################################################ -->

      <!-- top box -->

      <!-- expanded segment start -->
      <div aria-expanded="false" id="featuredBox" class="grid grid-cols-12 w-full ml-0.5 gap-10">

        <Panel1 :questionnaireResults='questionnaireResults' :visible="itemN1" :calculateColor="calculateColor"
          :getBgClass="getBgClass" :closeAction="() => {
              itemn1(false); resetView();
            }" />


        <Panel2 :questionnaireResults='questionnaireResults' :visible="itemN2" :calculateBorder="calculateBorder"
          :getBgClass="getBgClass" :closeAction="() => {
              itemn2(false); resetView();
            }" />

        <Panel3 :questionnaireResults='questionnaireResults' :visible="itemN3" :calculateBorder="calculateBorder"
          :calculateColor="calculateColor" :getBgClass="getBgClass" :graphToggle="graphToggle3"
          :graphViewClick="ici1GraphViewClick" :closeAction="() => {
              itemn3(false); resetView();
            }" />

        <Panel4 :questionnaireResults='questionnaireResults' :visible="itemN4" :calculateBorder="calculateBorder"
          :calculateColor="calculateColor" :getBgClass="getBgClass" :graphToggle="graphToggle4"
          :graphViewClick="ici2GraphViewClick" :closeAction="() => {
              itemn4(false); resetView();
            }" />


      </div>

      <!-- expanded segment end -->

      <!-- collapsed segment Start -->

      <div :class="['rowWrap ', 'flex', 'gap-4', 'w-full mt-4 ml-0.5', { row1: row1 }, { row2: row2 }]">
        <div class="row-1 flex flex-col w-1/2 gap-4 ">

          <div v-if="!itemN1" id="item-n-1"
            class="boxItem col-span-12 w-full grid grid-cols-10 gap-2 bg-white rounded-xl drop-shadow-md p-3">
            <div class="itemHead col-span-12 bg-[#f3f4f8] px-3 rounded-xl flex items-center justify-between min-h-14">
              <h3 class="font-bold">Questionnaire Criteria and Scores</h3>
              <img @click="itemn1(true), toggleRow1()" class="item1-toggle plus" style="cursor: pointer"
                src="../assets/plus-icon.svg">
            </div>

            <div class="data-view col-span-12 grid grid-cols-12 gap-2  collapsed-segment">
              <div class="flex col-span-6 bg-[#fbfaff] rounded-xl items-center justify-between gap-2 py-2 px-3">
                <div class="flex justify-start  w-full">
                  <h4 class="text-start">Network Characteristics</h4>
                </div>
                <div class="flex rating border border-2 p-2 border-[#82ca74] rounded-xl leading-4">{{
              this.questionnaireResults.nc_CHARACTERISTICS }}</div>
              </div>

              <div class="flex col-span-6 bg-[#fbfaff] rounded-xl items-center justify-between gap-2 py-2 px-3">
                <div class="flex justify-start w-full">
                  <h4 class="text-start">Organisational Governance</h4>
                </div>
                <div class="flex border rating border-2 p-2 border-[#d4d4d4] rounded-xl leading-4">{{
              this.questionnaireResults.nc_ORGANIZATIONAL_GOVERNANCE }}</div>
              </div>

              <div class="flex col-span-6 bg-[#fbfaff] rounded-xl items-center justify-between gap-2 py-2 px-3">
                <div class="flex justify-start w-full">
                  <h4 class="text-start">Organisational Architecture</h4>
                </div>
                <div class="flex border rating border-2 p-2 border-[#82ca74] rounded-xl leading-4">{{
              this.questionnaireResults.nc_ORGANIZATIONAL_ARCHITECTURE }}</div>
              </div>

              <div class="flex col-span-6 bg-[#fbfaff] rounded-xl items-center justify-between gap-2 py-2 px-3">
                <div class="flex justify-start w-full">
                  <h4 class="text-start">Technical IT Infrastructure</h4>
                </div>
                <div class="flex border rating border-2 p-2 border-[#f8d25b] rounded-xl leading-4">{{
              this.questionnaireResults.nc_TECHNICALIT_INFRASTRUCTURE }}</div>
              </div>

              <div class="flex col-span-6 bg-[#fbfaff] rounded-xl items-center justify-between gap-2 py-2 px-3">
                <div class="flex justify-start w-full">
                  <h4 class="text-start">Human IT Infrastructure</h4>
                </div>
                <div class="flex border rating border-2 p-2 border-[#f07620] rounded-xl leading-4">{{
              this.questionnaireResults.hi_HUMANIT_INFRASTRUCTURE }}</div>
              </div>

              <div class="flex col-span-6 bg-[#fbfaff] rounded-xl items-center justify-between gap-2 py-2 px-3">
                <div class="flex justify-start w-full">
                  <h4 class="text-start">Organisational Culture</h4>
                </div>
                <div class="flex border rating border-2 p-2 border-[#f07620] rounded-xl leading-4">{{
              this.questionnaireResults.hi_ORGANIZATIONAL_CULTURE }}</div>
              </div>

              <div class="flex col-span-6 bg-[#fbfaff] rounded-xl items-center justify-between gap-2 py-2 px-3">
                <div class="flex justify-start w-full">
                  <h4 class="text-start">Human Knowledge & Skills</h4>
                </div>
                <div class="flex border rating border-2 p-2 border-[#f4a14a] rounded-xl leading-4">{{
              this.questionnaireResults.hi_HUMAN_KNOWLEDGE }}</div>
              </div>
            </div>

          </div>

          <div v-if="!itemN2" id="item-n-2"
            class="boxItem col-span-12 w-full grid grid-cols-10 gap-2 bg-white rounded-xl drop-shadow-md p-3">

            <div class="itemHead col-span-12 bg-[#f3f4f8] px-3 rounded-xl flex items-center justify-between min-h-14">
              <h3 class="font-bold">Environmental Pressure</h3>
              <img @click="itemn2(true), toggleRow1()" class="item2-toggle" style="cursor: pointer"
                src="../assets/plus-icon.svg">
            </div>

            <div class="data-view col-span-12 grid grid-cols-12 gap-2  collapsed-segment">
              <div class="flex col-span-3 bg-[#f3f4f8] rounded-xl p-2 items-center justify-between">
                <div class="flex justify-center w-full">
                  <h4 class="text-center">Environment Factors</h4>
                </div>
              </div>

              <div class="flex col-span-3 bg-[#fbfaff] rounded-xl p-2 items-center justify-between">
                <div class="flex justify-center w-full">
                  <h4 class="text-center">Hostility</h4>
                </div>
              </div>

              <div class="flex col-span-3 bg-[#f3f4f8] rounded-xl p-2 items-center justify-between">
                <div class="flex justify-center w-full">
                  <h4 class="text-center">Dynamism</h4>
                </div>
              </div>

              <div class="flex col-span-3 bg-[#fbfaff] rounded-xl p-2 items-center justify-between">
                <div class="flex justify-center w-full">
                  <h4 class="text-center">Complexity</h4>
                </div>
              </div>

              <div class="flex col-span-3 bg-[#f3f4f8] rounded-xl p-2 items-center justify-between">
                <div class="flex justify-center w-full">
                  <h4 class="text-start">Mean</h4>
                </div>
              </div>

              <div class="flex col-span-3 bg-[#fbfaff] rounded-xl p-2 items-center justify-between">
                <div class="flex justify-center w-full">
                  <div class="flex border rating border-2 p-2 border-[#d4d4d4] rounded-xl leading-4">{{
              this.questionnaireResults.env_HOSTILITY }}
                  </div>
                </div>
              </div>

              <div class="flex col-span-3 bg-[#f3f4f8] rounded-xl p-2 items-center justify-between">
                <div class="flex justify-center w-full">
                  <div class="flex border rating border-2 p-2 border-[#d4d4d4] rounded-xl leading-4">{{
              this.questionnaireResults.env_DYNAMISM }}
                  </div>
                </div>
              </div>

              <div class="flex col-span-3 bg-[#fbfaff] rounded-xl p-2 items-center justify-between">
                <div class="flex justify-center w-full">
                  <div class="flex border rating border-2 p-2 border-[#82ca74] rounded-xl leading-4">{{
              this.questionnaireResults.env_COMPLEXITY }}
                  </div>
                </div>
              </div>

              <div class="flex col-span-3 border-2 border-[#aedddb] rounded-xl p-2 items-center justify-between">
                <div class="flex justify-center w-full">
                  <h4 class="text-center">Environmental Pressure</h4>
                </div>
              </div>

              <div class="flex col-span-9 border-2 border-[#aedddb] rounded-xl p-2 items-center justify-between">
                <div class="flex justify-center w-full">
                  <div class="flex p-2 rateBg bg-[#d4d4d4] rounded-xl leading-4 font-bold">{{
              this.questionnaireResults.env_FACTORS }}</div>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div class="row-2 flex flex-col w-1/2 gap-4">

          <div v-if="!itemN3" aria-details="data" id="item-n-3"
            class="boxItem col-span-12 w-full grid grid-cols-10 gap-2 bg-white rounded-xl drop-shadow-md p-3">

            <div
              class="itemHead col-span-12 bg-[#f3f4f8] px-3 py-1.5 rounded-xl flex items-center justify-between min-h-14">
              <h3 class="font-bold">Innovation Capability Index 1</h3>
              <div class="flex gap-4 items-center">
                <div class="viewBox flex bg-white px-3 py-2 rounded-xl gap-4 relative">
                  <div :class="[graphToggle3 ? 'right-1' : 'left-1']"
                    class="i3-toggle absolute bg-[#aedddb] h-5/6 top-1 rounded-xl z-0 transition ease-in-out"
                    :style="{ width: graphToggle3 ? 'calc(50% - 10px)' : '50%' }"></div>
                  <p @click="ici1GraphViewClick(false)" class="gv3-toggle w-1/2 text-nowrap z-10 hover:cursor-pointer">
                    Graph View</p>
                  <p @click="ici1GraphViewClick(true)" class="dv3-toggle w-1/2 text-nowrap z-10 hover:cursor-pointer">
                    Data View</p>
                </div>
                <img @click="itemn3(true), toggleRow2()" class="item3-toggle" style="cursor: pointer"
                  src="../assets/plus-icon.svg">
              </div>
            </div>

            <div v-if="graphToggle3" class="data-view col-span-12 grid grid-cols-12  gap-2 collapsed-segment">

              <!-- Row 1 -->
              <div class="grid col-span-12 grid-cols-10 gap-2 collapsed-segment pb-0">
                <div class="flex col-span-3 bg-[#f3f4f8] rounded-xl p-2 items-center justify-between left-col-mw">
                  <div class="flex justify-center w-full">
                    <h4 class="text-start">Enabler #</h4>
                  </div>
                </div>

                <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-2 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <h4 class="text-start">1</h4>
                  </div>
                </div>

                <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-2 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <h4 class="text-start">2</h4>
                  </div>
                </div>

                <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-2 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <h4 class="text-start">3</h4>
                  </div>
                </div>

                <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-2 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <h4 class="text-start">4</h4>
                  </div>
                </div>

                <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-2 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <h4 class="text-start">5</h4>
                  </div>
                </div>

                <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-2 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <h4 class="text-start">6</h4>
                  </div>
                </div>

                <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-2 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <h4 class="text-start">7</h4>
                  </div>
                </div>

              </div>



              <!-- row 2 -->
              <div class="grid col-span-12 grid-cols-10 gap-2 collapsed-segment pb-0">
                <div class="flex col-span-3 bg-[#f3f4f8] rounded-xl p-2 items-center justify-between left-col-mw">
                  <div class="flex justify-center w-full">
                    <h4 class="text-start">Mean</h4>
                  </div>
                </div>

                <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <div
                      class="flex border rating border-2 p-2 border-[#82ca74] rounded-xl leading-4 w-full justify-center">
                      {{ this.questionnaireResults.nc_CHARACTERISTICS }}
                    </div>
                  </div>
                </div>

                <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <div
                      class="flex border rating border-2 p-2 border-[#d4d4d4] rounded-xl leading-4 w-full justify-center">
                      {{ this.questionnaireResults.nc_ORGANIZATIONAL_GOVERNANCE }}
                    </div>
                  </div>
                </div>

                <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <div
                      class="flex border rating border-2 p-2 border-[#82ca74] rounded-xl leading-4 w-full justify-center">
                      {{ this.questionnaireResults.nc_ORGANIZATIONAL_ARCHITECTURE }}
                    </div>
                  </div>
                </div>

                <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <div
                      class="flex border rating border-2 p-2 border-[#f8d25b] rounded-xl leading-4 w-full justify-center">
                      {{ this.questionnaireResults.nc_TECHNICALIT_INFRASTRUCTURE }}
                    </div>
                  </div>
                </div>

                <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <div
                      class="flex border rating border-2 p-2 border-[#f07620] rounded-xl leading-4 w-full justify-center">
                      {{ this.questionnaireResults.hi_HUMANIT_INFRASTRUCTURE }}
                    </div>
                  </div>
                </div>

                <div class="flex col-span-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <div
                      class="flex border rating border-2 p-2 border-[#f07620] rounded-xl leading-4 w-full justify-center">
                      {{ this.questionnaireResults.hi_ORGANIZATIONAL_CULTURE }}
                    </div>
                  </div>
                </div>

                <div class="flex col-span-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <div
                      class="flex border rating border-2 p-2 border-[#f4a14a] rounded-xl leading-4 w-full justify-center">
                      {{ this.questionnaireResults.hi_HUMAN_KNOWLEDGE }}
                    </div>
                  </div>
                </div>

              </div>





              <!-- row 3 -->
              <div class="flex col-span-12 gap-2">
                <div class="colspan-3 bg-[#f3f4f8] rounded-xl p-2 items-center justify-between left-col-w">
                  <div class="flex justify-center w-full">
                    <h4 class="text-start mb-0 pb-0">Mean Alignment</h4>
                  </div>
                </div>

                <div class="flex-1 bg-[#fbfaff] rounded-xl p-2 items-center justify-between"
                  v-bind:class="{ 'my-auto': this.questionnaireResults.enableres, 'my-0': !this.questionnaireResults.enableres }">
                  <div class="flex justify-center my-auto  w-full">
                    <div class="flex my-auto p-2 rounded-xl leading-4 w-full justify-center">{{
              this.questionnaireResults.enableres }}</div>
                  </div>
                </div>
              </div>

              <!-- row 4 -->

              <div class="grid col-span-12 grid-cols-10 gap-2 collapsed-segment pb-0">

                <div class="flex col-span-3 border-2 border-[#aedddb] rounded-xl p-1 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <h4 class="text-center">ICI 1</h4>
                  </div>
                </div>

                <div class="flex col-span-3 border-2 border-[#aedddb] rounded-xl p-1 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <div class="flex p-2 rateBg bg-[#d4d4d4] rounded-xl leading-4 font-bold">
                      {{ questionnaireResults.business_AGILITY_1 }}
                    </div>
                  </div>
                </div>

                <div class="flex col-span-4 border-2 border-[#aedddb] rounded-xl p-1 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <div
                      class="flex p-2 rateBg2 bg-[#2d7e3c] text-white rounded-xl leading-4 font-semibold text-center">
                      95.61 %
                      MATCH -
                    </div>
                  </div>
                </div>


              </div>



            </div>

            <div v-if="!graphToggle3" class="graph-view col-span-12 grid grid-cols-10  collapsed-segment">
              <GraphIci :small="true" :class="'h-64 pt-4'" :circles="circlesIci1" :hoveredIndex="hoveredIci1"
                :setHoveredIndex="(index: any) => hoveredIci1 = index" />

              <div class="flex items-center col-span-5 pl-12 pr-24">
                <div class="flex items-center gap-4">
                  <div class="">
                    <div class=" shrink-0 grow-0  flex h-5 w-5  rounded-full border-2 border-black">
                      <p class="invisible">helolo</p>
                    </div>
                  </div>
                  <p class="uppercase text-lg leading-5">Position of enabler if aligned</p>
                </div>
              </div>
            </div>

          </div>

          <div v-if="!itemN4" aria-details="data" id="item-n-4"
            class="boxItem col-span-12 w-full grid grid-cols-10 gap-2 bg-white rounded-xl drop-shadow-md p-3">

            <div
              class="itemHead col-span-12 bg-[#f3f4f8] px-3 py-1.5 rounded-xl flex items-center justify-between min-h-14">
              <h3 class="font-bold">Innovation Capability Index 2</h3>
              <div class="flex gap-4 items-center">
                <div class="viewBox flex bg-white px-3 py-2 rounded-xl gap-4 relative">
                  <div :class="[graphToggle4 ? 'right-1' : 'left-1']"
                    class="i4-toggle absolute bg-[#aedddb] h-5/6 top-1 rounded-xl z-0 transition ease-in-out"
                    :style="{ width: graphToggle4 ? 'calc(50% - 10px)' : '50%' }"></div>
                  <p @click="ici2GraphViewClick(false)" class="gv4-toggle w-1/2 text-nowrap z-10 hover:cursor-pointer">
                    Graph View</p>
                  <p @click="ici2GraphViewClick(true)" class="dv4-toggle w-1/2 text-nowrap z-10 hover:cursor-pointer">
                    Data View</p>
                </div>
                <img @click="itemn4(true), toggleRow2()" class="item4-toggle" style="cursor: pointer"
                  src="../assets/plus-icon.svg">
              </div>
            </div>

            <div v-if="graphToggle4" class="data-view col-span-12 grid grid-cols-12 gap-2 collapsed-segment">
              <!-- row 1 -->
              <div class="flex col-span-12 gap-2">
                <div class="flex-1 bg-[#f3f4f8] rounded-xl p-2 items-center justify-between left-col-w">
                  <div class="flex justify-center w-full">
                    <h4 class="text-start">Capabilities</h4>
                  </div>
                </div>

                <div class="flex-1 bg-[#fbfaff] rounded-xl p-2 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <h4 class="text-start">Sensing</h4>
                  </div>
                </div>

                <div class="flex-1 bg-[#f3f4f8] rounded-xl p-2 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <h4 class="text-start">Responding</h4>
                  </div>
                </div>

                <div class="flex-1 bg-[#fbfaff] rounded-xl p-2 justify-between">
                  <div class="flex justify-center w-full">
                    <h4 class="text-start">Learning</h4>
                  </div>
                </div>
              </div>

              <!-- row 2 -->
              <div class="flex col-span-12 gap-2">
                <div class="flex-1 bg-[#f3f4f8] rounded-xl p-2 items-center justify-between left-col-w">
                  <div class="flex justify-center w-full">
                    <h4 class="text-start">Mean</h4>
                  </div>
                </div>
                <div class="flex-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between align-content-center">
                  <div class="flex justify-center w-full">
                    <div class="flex border rating border-2 p-2 border-[#f4a14a] rounded-xl leading-4 justify-center">
                      {{ this.questionnaireResults.slr_SENSING }}
                    </div>
                  </div>
                </div>

                <div class="flex-1 bg-[#f3f4f8] rounded-xl p-1 items-center justify-between align-content-center">
                  <div class="flex justify-center w-full">
                    <div class="flex border rating border-2 p-2 border-[#f4a14a] rounded-xl leading-4 justify-center">
                      {{ this.questionnaireResults.srl_RESPONDING }}
                    </div>
                  </div>
                </div>

                <div class="flex-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between align-content-center">
                  <div class="flex justify-center w-full">
                    <div class="flex border rating border-2 p-2 border-[#f8d25b] rounded-xl leading-4 justify-center">
                      {{ this.questionnaireResults.srl_LEARNING }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- row 3 -->


              <div class="flex col-span-12 gap-2">
                <div class="colspan-3 bg-[#f3f4f8] rounded-xl p-2 items-center justify-between left-col-w">
                  <div class="flex justify-center w-full">
                    <h4 class="text-start">Mean Alignment</h4>
                  </div>
                </div>

                <div class="flex-1 bg-[#fbfaff] rounded-xl p-1 items-center justify-between align-content-center"
                  v-bind:class="{ 'my-auto': this.questionnaireResults.srl_CAPABILITIES, 'my-0': !this.questionnaireResults.srl_CAPABILITIES }">
                  <div class="flex justify-center w-full">
                    <div class="flex p-2 rounded-xl leading-4 w-full justify-center">{{
              this.questionnaireResults.srl_CAPABILITIES }}</div>
                  </div>
                </div>
              </div>

              <!-- row 4 -->

              <div class="grid col-span-12 grid-cols-10 gap-2 collapsed-segment pb-0">

                <div class="flex col-span-3 border-2 border-[#aedddb] rounded-xl p-1 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <h4 class="text-center">ICI 2</h4>
                  </div>
                </div>

                <div class="flex col-span-3 border-2 border-[#aedddb] rounded-xl p-1 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <div class="flex p-2 rateBg bg-[#f8d25b] rounded-xl leading-4 font-bold">
                      {{ questionnaireResults.business_AGILITY_2 }}
                    </div>
                  </div>
                </div>

                <div class="flex col-span-4 border-2 border-[#aedddb] rounded-xl p-1 items-center justify-between">
                  <div class="flex justify-center w-full">
                    <div
                      class="flex p-2 rateBg2 bg-[#2d7e3c] text-white rounded-xl leading-4 font-semibold text-center">
                      95.61 %
                      MATCH -
                    </div>
                  </div>
                </div>


              </div>

            </div>
            <div v-if="!graphToggle4" class="graph-view col-span-12 grid grid-cols-10 collapsed-segment">
              <GraphIci :small="true" :class="'h-64 pt-4'" :circles="circlesIci2" :hoveredIndex="hoveredIci2"
                :setHoveredIndex="(index: any)=> hoveredIci2 = index" />

              <div class="flex items-center col-span-5 pl-12 pr-24">
                <div class="flex items-center gap-4">
                  <div class="">
                    <div class=" shrink-0 grow-0  flex h-5 w-5  rounded-full border-2 border-black">
                      <p class="invisible">helolo</p>
                    </div>
                  </div>
                  <p class="uppercase text-lg leading-5">Position of capability if aligned</p>
                </div>
              </div>
            </div>
          </div>


        </div>

      </div>

      <!-- ############################################################################################ -->
      <!-- ############################################################################################ -->
      <!-- ########################             END DASHBOARD CONTENT          ######################## -->
      <!-- ############################################################################################ -->
      <!-- ############################################################################################ -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Company } from "@/data/Company";
import { Application } from "@/data/Application";
import IciButton from "@/components/IciButton.vue";
import { User } from "@/data/User";
import router from "@/router";
import { useRouter } from "vue-router";
import DashboardButton from "@/components/button/DashboardButton.vue";
import ResultButton from "@/components/button/ResultButton.vue";
import SettingsButton from "@/components/button/SettingsButton.vue";
import { AuthService } from "@/service/AuthService";
import { QuestionnaireResults } from "@/data/QuestionnaireResults";
import "@/tailwind.css";
import axios from "axios";
import BackToProjectButton from "@/components/button/BackToProjectButton.vue";
import DownloadReport from "@/components/button/DownloadReport.vue";

import Panel1 from '@/components/results/Panel1.vue'
import Panel2 from '@/components/results/Panel2.vue'
import Panel3 from '@/components/results/Panel3.vue'
import Panel4 from '@/components/results/Panel4.vue'
import GraphIci from '@/components/results/GraphIci.vue'



export default defineComponent({
  components: { DownloadReport, BackToProjectButton, SettingsButton, ResultButton, DashboardButton, IciButton, Panel1, Panel2, Panel3, Panel4, GraphIci },

  computed: {
    Company() {
      return Company
    },
    Application() {
      return Application
    }
  },
  data() {
    return {
      hover: false,
      buttonBackToAllClients: "<i class='pi pi-chevron-left' style='font-size: 1rem'></i>&nbsp;&nbsp;<label>$label</label>",
      buttonBackToProject: "<i class='pi pi-chevron-left' style='font-size: 1rem'></i>&nbsp;&nbsp;<label>$label</label>",
      buttonSaveContent: "<label>$label</label>&nbsp;&nbsp;<i class='pi pi-chevron-right' style='font-size: 1rem'></i>",
      selectedClient: this.getCompany(),
      questionnaireResults: {} as QuestionnaireResults,
      itemN1: false,
      itemN2: false,
      itemN3: false,
      itemN4: false,
      row1: false,
      row2: false,
      graphToggle3: true,
      graphToggle4: true,
      graph: null,
      graphFeatured: null,
      graph2: null,
      graph2Featured: null,
      ratingColorValues: [
        ["0", "#B80000", "#629857"],
        ["1", "#DE1524", "#A70F1B"],
        ["2", "#F07620", "#B45818"],
        ["3", "#F4A14A", "#BD7D38"],
        ["4", "#F8D25B", "#CCA64A"],
        ["5", "#D4D4D4", "#B4B4B4"],
        ["6", "#82CA74", "#629857"],
        ["7", "#379B23", "#2A7519"],
        ["8", "#2D7E3C", "#20692D"],
        ["9", "#144C1E", "#0F3715"]
      ],
      previousItem: null as HTMLElement | null,
      previousItemId: null as string | null,
      circlesIci1: [
        { cx: 600, cy: 600, r: 87.64, fill: '#82CA74', rotation: 0, translateY: -323.5, strokeTranslateY: -222 },
        { cx: 600, cy: 600, r: 73, fill: '#D4D4D4', rotation: 51.4286, translateY: -262.5, strokeTranslateY: -222 },
        { cx: 600, cy: 600, r: 85.6, fill: '#82CA74', rotation: 102.857, translateY: -315, strokeTranslateY: -222 },
        { cx: 600, cy: 600, r: 60.04, fill: '#F8D25B', rotation: 154.286, translateY: -208.5, strokeTranslateY: -222 },
        { cx: 600, cy: 600, r: 45.04, fill: '#F07620', rotation: 205.714, translateY: -146, strokeTranslateY: -222 },
        { cx: 600, cy: 600, r: 36.64, fill: '#F07620', rotation: 257.143, translateY: -111, strokeTranslateY: -222 },
        { cx: 600, cy: 600, r: 55, fill: '#F4A14A', rotation: 308.571, translateY: -187.5, strokeTranslateY: -222 }
      ],
      hoveredIci1: null,
      circlesIci2: [
        { cx: 600, cy: 276.5, r: 87.64, fill: '#82CA74', rotation: 0, translateY: 0, strokeTranslateY: 10 }, // Top Circle
        { cx: 400, cy: 823.5, r: 85.6, fill: '#82CA74', rotation: 0, translateY: 0, strokeTranslateY: 10 }, // Bottom Left Circle
        { cx: 800, cy: 823.5, r: 55, fill: '#F4A14A', rotation: 0, translateY: 0, strokeTranslateY: 10 } // Bottom Right Circle
      ],
      hoveredIci2: null,
    }

  },
  mounted() {
    this.getQuestionnaireResults();
    this.checkCurrentUrlAndRun();
    this.updateGraphs();

  },
  methods: {
    getBgClass(index: number) {
      return index % 2 === 0 ? 'bg-[#fbfaff]' : 'bg-[#f3f4f8]';
    },
    resetView() {
      this.itemN1 = false;
      this.itemN2 = false;
      this.itemN3 = false;
      this.itemN4 = false;
      this.row1 = false;
      this.row2 = false;
    },
    toggleRow1() {
      this.row1 = !this.row1;
      if (this.row2) {
        this.row2 = false;
      }
    },
    toggleRow2() {
      this.row2 = !this.row2;
      if (this.row1) {
        this.row1 = false;
      }
    },
    calculateColor(inputNumber: number, bg?: boolean) {
      let color = '';
      this.ratingColorValues.forEach(obj => {
        if (inputNumber >= parseFloat(obj[0])) {
          color = obj[1];
        }
      });
      // change border color
      return `border-color: ${color}; ${bg && `background-color: ${color}`}`;
    },
    calculateBorder(inputNumber: number) {
      let color = '';
      this.ratingColorValues.forEach(obj => {
        if (inputNumber >= parseFloat(obj[0])) {
          color = obj[1];
        }
      });
      return `width: ${inputNumber * 10}%; transition: width 1s ease 0s; background-color: ${color}`;
    },
    checkCurrentUrlAndRun: function () {
      const currentUrl = window.location.href;
      if (currentUrl.endsWith('results-dashboard')) {
        console.log("lol")
        const primeFlexLink = document.querySelector('link[rel="stylesheet"][href="https://unpkg.com/primeflex@latest/primeflex.css"]');
        if (primeFlexLink) {
          primeFlexLink.parentNode?.removeChild(primeFlexLink);
        } else {
          console.log('PrimeFlex stylesheet not found');
        }
      }
    },
    updateGraphs: function () {
      if (this.graph == null) {
        this.graph = this.generateNewGraph(
          document.getElementById("graph"),
          [
            [6.47, "Network Characteristics"],
            [5.25, "Organisational Governance"],
            [6.30, "Organisational Architecture"],
            [4.17, "Technical IT Infrastructure"],
            [2.92, "Human IT Infrastructure"],
            [2.22, "Organisational Culture"],
            [3.75, "Human Knowledge and Skills"],
          ],
          200,
          false,
          document.querySelector(".gv3-toggle")
        )
      }

      if (this.graphFeatured == null) {
        this.graphFeatured = this.generateNewGraph(
          document.getElementById("graphFeatured"),
          [
            [6.47, "Network Characteristics"],
            [5.25, "Organisational Governance"],
            [6.30, "Organisational Architecture"],
            [4.17, "Technical IT Infrastructure"],
            [2.92, "Human IT Infrastructure"],
            [2.22, "Organisational Culture"],
            [3.75, "Human Knowledge and Skills"],
          ],
          200,
          false,
          document.querySelectorAll(".item3-toggle")[0]
        )
      }

      if (this.graph2 == null) {
        this.graph2 = this.generateNewGraph(
          document.getElementById("graph2"),
          [
            [4, "Network Characteristics"],
            [4, "Organisational Governance"],
            [3, "Organisational Architecture"],
          ],
          500,
          true,
          document.querySelector(".gv4-toggle")
        )
      }

      if (this.graph2Featured == null) {
        this.graph2Featured = this.generateNewGraph(
          document.getElementById("graph2Featured"),
          [
            [4, "Network Characteristics"],
            [4, "Organisational Governance"],
            [3, "Organisational Architecture"],
          ],
          500,
          true,
          document.querySelectorAll(".item4-toggle")[0]
        )
      }
    },
    constructLabel: (content: String, label: string) => {
      return content.replace("$label", label);
    },
    getQuestionnaireResults: function () {
      let qid: number = Number(router.currentRoute.value.params.qid);
      if (!isNaN(qid)) {
        axios.get(`${Application.instance.apiEndPoint}/admin/algorithm/test/${qid}`)
          .then(response => {
            this.questionnaireResults = response.data;
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    getCompany: (): Company | null => {
      let id: Number = Number(router.currentRoute.value.params.id);
      if (id == 0) {
        return Company.build(0, "", 0, 0, 0, 0, "");
      } else {
        return User.get().getCompany(id);
      }
    },
    backToAllClients: function () {
      router.push("/");
    },
    backToProject: function () {
      router.push("dashboard");
    },
    gotoClientDashboard: function () {
      // @ts-ignore
      router.push("/client/" + Number(router.currentRoute.value.params.id) + "/dashboard");
    },
    gotoResults: function () {
      router.push("/results-dashboard");
    },
    gotoSetting: function () {
      router.push("/client/" + Number(router.currentRoute.value.params.id) + "/dashboard");
    },
    itemn1(state: boolean) {
      this.resetView();
      this.itemN1 = state;
    },
    itemn2(state: boolean) {
      this.resetView();
      this.itemN2 = state;
    },
    itemn3(state: boolean) {
      this.resetView();
      this.itemN3 = state;
    },
    itemn4(state: boolean) {
      this.resetView();
      this.itemN4 = state;
    },
    ici1GraphViewClick(state: boolean) {
      this.graphToggle3 = state;
      this.updateGraphs();
      let graphElement = document.getElementById("graph");
      if (graphElement != null) {
        const svgParent = graphElement.querySelector("svg");
        if (svgParent != null) {
          svgParent.style.height = "230px";
        }
      }
    },
    ici2GraphViewClick(state: boolean) {
      this.graphToggle4 = state;
      this.updateGraphs();
      let graphElement = document.getElementById("graph2");
      if (graphElement != null) {
        const svgParent = graphElement.querySelector("svg");
        if (svgParent != null) {
          svgParent.style.height = "230px";
        }
      }
    },
    getCircleColorRow: function (value: any) {
      let graphColorResult
      for (let i = 0; i < this.ratingColorValues.length; i++) {
        if (value >= this.ratingColorValues[i][0]) {
          graphColorResult = this.ratingColorValues[i]
        } else break
      }
      return graphColorResult
    },
    calculateGraphSize(rootGraphDiv: any, highestVal: number, biggestItem: any, svgParent: any) {
      let svgParentSize = rootGraphDiv.clientHeight
      let tempOffset = svgParentSize * (2 - (1 / 20)) + (highestVal < 6 ? (svgParentSize / 2) : 0)
      let circleDistance = (biggestItem.offset * 2 + biggestItem.radius * 2) / 1200
      let calculatedSize = tempOffset + circleDistance * (svgParentSize - tempOffset)

      svgParent.style.height = calculatedSize + "px"
      svgParent.querySelector(".reEntryHover").setAttribute("r", circleDistance * 120)
    },
    generateNewGraph: function (
      rootGraphDiv: any,
      graphData: any, // data array [[value, "string name"],[etc, "etc"]]
      absoluteSize: any,
      disableLines: any,
      clickEffect: any
    ) {
      if (rootGraphDiv != null) {
        const svgParent = rootGraphDiv.querySelector("svg");
        const maxGraphValue = 10
        const maxCircleOffset = 500 // pixels
        const maxCircleSize = 130
        const minCircleSize = 10

        const generatedGraphCircles: any[] = [] // [circle, stroke, offset, radius, rotation]
        let strokeOffset: any

        // toggle raster lines
        if (disableLines) {
          svgParent.querySelectorAll("line").forEach((item: { style: { display: string; }; }, index: any) => {
            item.style.display = "none"

          })
        }
        graphData.forEach((value: any, index: any) => {

          // filled circle color
          let colorResult = this.getCircleColorRow(value[0])

          // filled circle element
          const currentCircle = document.createElementNS('http://www.w3.org/2000/svg', "circle")
          currentCircle.setAttribute("cx", "600");
          currentCircle.setAttribute("cy", "600");
          currentCircle.setAttribute("r", "0");
          // @ts-ignore
          currentCircle.setAttribute("fill", colorResult[1]);
          // currentCircle.setAttributes({"cx": 600, "cy": 600, "r": 0, "fill": colorResult[1]})
          currentCircle.classList.add("filledCircle", "graphCircle" + index)
          svgParent.appendChild(currentCircle)

          // stroke circle element
          const currentStroke = document.createElementNS('http://www.w3.org/2000/svg', "circle")
          currentStroke.setAttribute("cx", "600");
          currentStroke.setAttribute("cy", "600");
          currentStroke.setAttribute("r", "0");
          // currentStroke.setAttributes({"cx": 600, "cy": 600, "r": 0})
          currentStroke.classList.add("strokeCircle", "graphStroke" + index)
          svgParent.appendChild(currentStroke)

          // center gray bubble on top
          svgParent.appendChild(svgParent.querySelector(".reEntryHover"))

          // individual element calculations
          const currentRotationCalc = 360 / graphData.length * index
          const currentOffsetCalc = maxCircleOffset / maxGraphValue * value[0]
          const currentRadiusCalc = minCircleSize + ((maxCircleSize - minCircleSize) / maxGraphValue * value[0])

          // add to generated list

          generatedGraphCircles.push({
            "name": value[1],
            "circle": currentCircle,
            "stroke": currentStroke,
            "value": value[0],
            "offset": currentOffsetCalc,
            "radius": currentRadiusCalc,
            "rotation": currentRotationCalc,
            // @ts-ignore
            "color": colorResult[1],
            // @ts-ignore
            "hoverColor": colorResult[2]
          })
        })

        // hover effect function
        generatedGraphCircles.forEach((item, index) => {
          // hover callbacks
          item.circle.addEventListener("mouseover", function () {
            const radialGradient = document.getElementById("graphHoverGradient")
            if (radialGradient != null) {
              const gradientFill = radialGradient.querySelector(".centerGraphHoverColor")
              const borderFill = radialGradient.querySelector(".borderGraphHoverColor")

              if (gradientFill != null) {
                gradientFill.setAttribute("stop-color", item.color)
              }
              if (borderFill != null) {
                borderFill.setAttribute("stop-color", item.hoverColor)
              }
            }

            // @ts-ignore
            this.setAttribute("fill", `url(#graphHoverGradient)`)
            // @ts-ignore
            svgParent.append(this)

            // making sure all z-indexes are in order
            svgParent.appendChild(svgParent.querySelector(".reEntryHover"))
          })
          item.circle.addEventListener("mouseout", function () {
            svgParent.appendChild(item.stroke)
            // @ts-ignore
            this.setAttribute("fill", item.color)
          })
        })

        let total = 0
        generatedGraphCircles.forEach(item => {
          total += item.value
        })
        strokeOffset = total / generatedGraphCircles.length

        let highestVal = 0
        let biggestItem: any
        generatedGraphCircles.forEach((item, index) => {
          if (item.value > highestVal) {
            highestVal = item.value
            biggestItem = item
          }
        })

        this.calculateGraphSize(rootGraphDiv, highestVal, biggestItem, svgParent);
        const resizeObserver = new ResizeObserver(entries => {
          for (let entry of entries) {
            if (entry.target === rootGraphDiv) {
              this.calculateGraphSize(rootGraphDiv, highestVal, biggestItem, svgParent)
            }
          }
        });
        // Start observing the target node
        resizeObserver.observe(rootGraphDiv);

        // render + animation
        generatedGraphCircles.forEach((item, index) => {
          setTimeout(function () {
            item.stroke.style.transform = "rotate(" + item.rotation + "deg)"
            // item.stroke.style.r = item.radius.toString()
            item.stroke.setAttribute("r", item.radius.toString());
            item.stroke.style.transform = "rotate(" + item.rotation + "deg) translateY(-" + (maxCircleOffset / maxGraphValue * strokeOffset) + "px)"

            setTimeout(function () {
              item.circle.style.transform = "rotate(" + item.rotation + "deg)"
              // item.circle.style.r = item.radius.toString()
              item.circle.setAttribute("r", item.radius.toString());
              item.circle.style.transform = "rotate(" + item.rotation + "deg) translateY(-" + item.offset + "px)"
            }, 50)
          }, index * 100)
        })
        return svgParent;
      }
    }
  },
  setup(props) {
    AuthService.validate();
    const instance = this;
  },
})


</script>

<style scoped>
@import '@/components/results/styles.css'
</style>
