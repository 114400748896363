<template>
  <div class="panel">
    <div class="content">
      <div class="form-block flex" style="flex-direction: column; gap: 40px">

        <div class="flex" style="align-items: center; gap: 32px; justify-content: space-between; width: 501px;">
          <div class="form-title" v-html="$tc('profileForm.title')" />
          <div v-if="AuthService.roles.length > 1" style="display: flex; gap: 16px; align-items: center">
            <div style="display:inline-block; font-weight: bold; text-align: left;">
              {{ $tc('profileForm.selectedRole') }}</div>
            <div style="display:inline-block;">
              <Dropdown v-model="role" :options="AuthService.roles"
                        @change="event => { this.$forceUpdate(); this.updateCurrentRole(); }" option-value="id"
                        optionLabel="name" placeholder="Select..." class="md:w-14rem level-ddl field-role" />
            </div>
          </div>
        </div>

        <div class="fields-block" style="display: flex; flex-direction: column; gap: 8px;">


          <div class="flex" style="gap: 12px;">

            <div class="flex" style="flex-direction: column; gap: 6px">
              <div class="label-first-name" v-html="$tc('profileForm.labelFirstName')" />
              <div class="input-first-name">
                <InputText type="text" v-model="User.get().profile.firstName" class="field-first-name" />
              </div>
            </div>

            <div class="flex" style="flex-direction: column; gap: 6px">
              <div class="label-last-name" v-html="$tc('profileForm.labelLastName')" />
              <div class="input-last-name">
                <InputText type="text" v-model="User.get().profile.lastName" class="field-last-name" />
              </div>
            </div>

          </div>

          <div class="label-email" v-html="$tc('profileForm.labelEmail')" />
          <div class="input-email">
            <InputText type="text" v-model="User.get().email" class="field-email" />
          </div>

          <div class="flex" style="gap: 12px;">
            <div class="flex" style="flex-direction: column; gap: 6px">
              <div class="label-job-title" v-html="$tc('profileForm.labelJobTitle')" />
              <div class="input-job-title">
                <InputText type="text" v-model="User.get().profile.jobTitle" class="field-job-title" />
              </div>
            </div>
            <div class="flex" style="flex-direction: column; gap: 6px">
              <div class="label-job-level" v-html="$tc('profileForm.labelJobLevel')" />
              <div class="input-job-level">
                <Dropdown v-model="User.get().profile.level" :options="Application.instance.levelItems"
                          @change="event => { this.$forceUpdate() }" option-value="id" optionLabel="name" placeholder="Select..."
                          class="level-ddl field-job-level" />
              </div>
            </div>
          </div>

          <div class="flex" style="gap: 12px;">
            <div class="flex" style="flex-direction: column; gap: 6px">
              <div class="label-phone" v-html="$tc('profileForm.labelPhone')" />
              <div class="input-phone">
                <InputText type="text" v-model="User.get().profile.phone" class="field-phone" />
              </div>
            </div>
            <div class="flex" style="flex-direction: column; gap: 6px">

              <div class="label-language" v-html="$tc('profileForm.labelLanguage')" />
              <div class="input-language">
                <Dropdown v-model="User.get().profile.language" :options="Application.instance.languages"
                          @change="event => { this.$forceUpdate(); }" option-value="code" optionLabel="name" placeholder="Select..."
                          class="level-ddl field-language" />
              </div>
            </div>
          </div>

        </div>

        <div class="button-submit">
          <IciButton id="submit" :content="constructLabel(buttonSaveContent, $t('profileForm.submit'))"
                     @buttonClick="submit()" btnStyle="width: fit-content; height: auto; padding: 18px 24px!important;"
                     :dark="false"></IciButton>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
// @ is an alias to /src
import router from "@/router";
import { Inject } from "vue-property-decorator";
import { User } from "@/data/User";
import { Application } from "@/data/Application";
import { AuthService } from "@/service/AuthService";
import { AuthRoleModel } from "@/data/AuthRoleModel";
import { Navigation } from "@/data/Navigation";
import IciButton from "@/components/IciButton.vue";

@Options({
  components: { IciButton },
  computed: {
    AuthService() {
      return AuthService
    },
    Application() {
      return Application
    },
    User() {
      return User
    }
  }
})
export default class ProfileView extends Vue {

  private role: number = AuthService.currentRole.id;
  private buttonSaveContent = "<label>$label</label><i class='pi pi-chevron-right' style='font-size: 1rem'></i>";

  mounted() {
    AuthService.validate();
    if (AuthService.getToken() != null) {
      Application.instance.getRefData();
      this.role = AuthService.currentRole.id;
    }
    Application.instance.setActiveView(Navigation.PROFILE_VIEW);
  }
  private submit = () => {
    User.get().profile.defaultRole = this.role;
    User.get().profile.submit();
    router.push('/')
  }

  private updateCurrentRole = () => {
    AuthService.selectRole(this.role);
  }

  private constructLabel(content: String, label: string) {
    return content.replace("$label", label);
  }
}
</script>
<style scoped>

.p-inputtext {
  padding: 9px !important;
  width: 100%;
  height: 46px;
}

.panel {
  font-family: "Public Sans";
  position: relative;
  padding: 170px 24px 24px 24px;
}

.content {
  font-family: "Public Sans";
  position: static;
  padding: 0;
}

.form-block {
  font-family: "Public Sans";
  position: static;
  border-radius: 6px;
  border-left-style: solid;
  border-left-width: 8px;
  border-left-color: #0D3D52;
  box-shadow: 5px 10px 8px #EEEEEE;
  padding: 20px 40px;
}

.form-title {
  font-family: "Public Sans";
  font-size: 32px;
  font-weight: bold;
  text-align: left;
  padding: 0;
}

.fields-block {
  font-family: "Public Sans";
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  padding: 0;
}

.label-first-name {
  font-family: "Public Sans";
  position: static;
  font-weight: bold;
}

.input-first-name {
  font-family: "Public Sans";
  position: static;
  font-weight: normal;
}

.field-first-name {
  font-family: "Public Sans";
  width: 244px !important;
}

.label-last-name {
  font-family: "Public Sans";
  position: static;
  font-weight: bold;
}

.input-last-name {
  font-family: "Public Sans";
  position: static;
  font-weight: normal;
}

.field-last-name {
  font-family: "Public Sans";
  width: 244px !important;

}

.label-email {
  font-family: "Public Sans";
  position: static;
  font-weight: bold;
}

.input-email {
  font-family: "Public Sans";
  position: static;
  font-weight: normal;
}

.field-email {
  font-family: "Public Sans";
  height: 46px;
  width: 500px !important;
}

.label-phone {
  font-family: "Public Sans";
  position: static;
  font-weight: bold;
}

.input-phone {
  font-family: "Public Sans";
  position: static;
  font-weight: normal;
}

.field-phone {
  font-family: "Public Sans";
  width: 237px !important;
}

.label-job-title {
  font-family: "Public Sans";
  position: static;
  font-weight: bold;
}

.input-job-title {
  font-family: "Public Sans";
  position: static;
  font-weight: normal;
  width: 237px;
}

.field-job-title {
  font-family: "Public Sans";
  height: 46px;
  width: 237px !important;
}

.label-job-level {
  font-family: "Public Sans";
  position: static;
  font-weight: bold;
}

.input-job-level {
  font-family: "Public Sans";
  position: static;
  font-weight: normal;
  width: 250px !important;
}

.field-job-level {
  width: 250px !important;
  height: 46px;
}

.button-submit {
  font-family: "Public Sans";
  position: static;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  width: 500px;
}

.field-role {
  font-family: "Public Sans";
  height: 46px;
  text-align: left;
}

.label-language {
  font-family: "Public Sans";
  position: static;
  font-weight: bold;
}

.input-language {
  font-family: "Public Sans";
  position: static;
  font-weight: normal;
}

.field-language {
  width: 250px !important;
  height: 46px;
}

.p-dropdown-label {
  text-align: left !important;
}

.p-button {
  border: none!important;
}

</style>
