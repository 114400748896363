<template>
  <div class="panel" style="gap: 8px">
    <div class="field">
      <Dropdown v-model="selectedTemplate"
                :options="templates"
                optionLabel="key"
                placeholder="Select Template ..."
                class="md:w-14rem level-ddl ddl-field"/>
    </div>
    <div class="label">Mail Template</div>
    <div>
      <Textarea v-model="selectedTemplate.template" rows="10" cols="150" ></Textarea>
    </div>
    <div class="label">Variables</div>
    <div>
      <Textarea v-model="selectedTemplate.variables" rows="10" cols="150" ></Textarea>
    </div>
    <div class="save-button">
      <IciButton id="submit"
                 content="Save"
                 @buttonClick="this.save"
                 btnStyle="width: 140px;"
                 :dark="true"></IciButton>
    </div>
</div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import axios from "axios";
import {Application} from "@/data/Application";
import {MailTemplate} from "@/data/MailTemplate";
import IciButton from "@/components/IciButton.vue";

@Options({
  components: {IciButton}
})
export default class AdminMailTemplatesView extends Vue {

  private selectedTemplate: MailTemplate = new MailTemplate();
  private templates: MailTemplate[] = [];

  mounted() {
    axios.get(Application.instance.apiEndPoint + '/admin/mail/template/list')
        .then(response => {
          console.log(response.data);
          this.templates = [];
          for(let template of response.data) {
            this.templates.push(
                MailTemplate.create(
                    template.key,
                    template.template,
                    template.variables)
            )
          }
          this.templates = response.data;
        })
        .catch(err => {
          console.log(err);
        });
  }
  private save() {
    axios.post(Application.instance.apiEndPoint + '/admin/mail/template', this.selectedTemplate)
        .then(response => {
          console.log(response.data);
        })
        .catch(err => {
          console.log(err);
        });
  }
}
</script>
<style scoped>
.panel {
  text-align: left;
  padding-left: 70px;
}
.label {
  font-family: "Public Sans";
  font-weight: bold;
}
.field {
  padding-top: 50px;
  font-family: "Public Sans";
  font-weight: normal;
}
.input {
  font-family: "Public Sans";
  width: 750px;
}
.ddl-field {
  font-family: "Public Sans";
  width: 545px;
  height: 40px;
}
.save-button  {
  padding-left: 0px;
}
</style>
