export class Content {

    public registerForm: RegisterForm = new RegisterForm();
    public loginForm: LoginForm = new LoginForm();
    public homeForm: HomeForm = new HomeForm();
    public profileForm: ProfileForm = new ProfileForm();
    public questionnaireForm: QuestionnaireForm = new QuestionnaireForm();
    public companyForm: CompanyForm = new CompanyForm();
    public ddlSelectPlaceHolder: String = "Select...";
}

export class CompanyForm {
    public fieldLabelClientName: string = "Name";
    public fieldLabelBusinessActivity: string = "Business Activity";
    public fieldLabelNumberOfEmployees: string = "Number of Employees";
    public fieldLabelGrossRevenue: string = "Gross Revenue";
    public fieldLabelExecutiveReport: string = "Executive Report";
    public fieldLabelYear: string = "Year";
    public fieldLabelDeadline: string = "Deadline";
    public fieldLabelStatus: string = "Status";
    public titleAddNewClient: string = "Add New Client";

    public buttonNewClient: string = "New Client";
    public buttonBackToAllClients: string = "Back to all clients";
    public tableHeaderClientName: string = "Client Name";
    public tableHeaderQuestionnaireDeadline: string = "Questionnaire Deadline";
    public tableHeaderProjectStatus: string = "Questionnaire Status";
    public tableHeaderEmail: string = "Email";
    public tableHeaderStatus: string = "Status";
    public tableHeaderProgress: string = "Progress";
    public tableHeaderProgressOverview: string = "Progress Overview";
    public tableHeaderActiveProject: string = "Active Project";
    public tableHeaderRespondentName: string = "Respondent Email";
    public tableHeaderRespondents: string = "Respondents";
    public titleDashboard: string = "Dashboard";
    public titleProjectOverview: string = "Questionnaire Overview";
    public buttonNewProject: string = "Add Project";
    public buttonBackToDashboard: string = "Back to dashboard";
}

export class RegisterForm {
    public tabHeader: string = "Register";
    public title: string = "Register";
    public intro: string = "<p>You have been requested to register for the<br>" +
                            "Innovation Capability Index questionnaire by your company.</p>" +
                            "<p>Please complete the following details to create your profile.</p>";
    public fieldLabelFirstName: string = "First name";
    public fieldLabelLastName: string = "Last name";
    public fieldLabelEmail: string = "Email";
    public fieldLabelDepartment: string = "Department";
    public fieldLabelPassword: string = "Password";
    public fieldLabelPasswordConfirm: string = "Confirm Password";
    public buttonLabelRegister: string = "Register";
}

export class LoginForm{
    public tabHeader: string = "Login";
    public title: string = "Login";
    public fieldLabelEmail: string = "Email";
    public fieldLabelPassword: string = "Password";
    public buttonLabelLogin: string = "Login";
}

export class HomeForm {
    public welcome: string = "Welcome";
    public appTitle: string = "Innovation Capability Index";
    public contentHeader: string = "<p><b>Welcome to the ICI questionnaire!</b></p>";
    public contentNoOpenQuestionnaire: string = "<p>Thank you for completing the ICI questionnaire. Your responses have been sent to your company for data analysis.</p>";
    public contentOpenQuestionnaire: string = "<p>There are <b>9 sections</b> of questions and it should take you <b>approximately 30 minutes</b> to\n" +
        "complete the whole questionnaire.</p>" +
        "<p>Please complete all questions before the given deadline. After the deadline has passed, the questionnaire" +
        "will be closed and you will no longer be able to submit your answers.</p>" +
        "<p>You may pause and return to your questionnaire at any moment - your progress will be saved. You can edit" +
        "your responses until the questionnaire is complete, however once submitted you will no longer be able to" +
        "edit your responses.</p>";
    public btnLabelStart: string = "Start";
    public btnLabelProceed: string = "Proceed";
    public titleProgress: string = "Progress";
    public titleDeadline: string = "Deadline";
    public titleMyResponses: string = "My Responses";
}

export class ProfileForm {
    public title: string = "Profile";
    public labelFirstName: string = "First name";
    public labelLastName: string = "Last name";
    public labelEmail: string = "Email";
    public labelPhone: string = "Phone";
    public labelJobTitle: string = "Job title";
    public labelJobLevel: string = "Job level";
    public labelLanguage: string = "Language";
}

export class QuestionnaireForm {
    public btnLabelNext: string = "Next";
    public btnLabelPrevious: string = "Previous question";
    public btnLabelSubmit: string = "Submit";

    public titleDemographics: string = "Demographics";
    public labelNotApplicable: string = "Not Applicable";
    public labelCompletelyDisagree: string = "Completely Disagree";
    public labelCompletelyAgree: string = "Completely Agree";

    public titleEnvironmentalFactors: string = "Environmental Factors";
    public titleEnvironmentalHostility: string = "Environmental Hostility";
    public titleEnvironmentalDynamism: string = "Environmental Dynamism";
    public titleEnvironmentalComplexity: string = "Environmental Complexity";

    public titleNetworkCharacteristics: string = "Network Characteristics";
    public titleRelationshipsBasedOnTrust: string = "Relationships based on trust";
    public titlePerformanceMetricsmeasurementAndBenchmarking: string = "Performance metrics, measurement and benchmarking";
    public titleProcessIntegrationAndCollaborativeWork: string = "Process integration and collaborative work";
    public titleKnowledgeSharing: string = "Knowledge sharing";
    public titleQuickConnectAndDisconnect: string = "Quick connect and disconnect";

    public titleOrganizationalGovernance: string = "Organizational Governance";
    public titleEmpowerment: string = "Empowerment";
    public titleRewards: string = "Rewards";
    public titlePerformanceMeasure: string = "Performance measure";
    public titleFlexibleBudgeting: string = "Flexible budgeting";

    public titleOrganizationalArchitecture: string = "Organizational Architecture";
    public titleProcessesStandardization: string = "Processes standardization";
    public titleModularity: string = "Modularity";
    public titleCustomizationOfProductsAndServices: string = "Customization of products and services";

    public titleTechnicalITInfrastructure: string = "Technical IT Infrastructure";
    public titleCompatibility: string = "Compatibility";
    public titleConnectivity: string = "Connectivity";
    public titleReusability: string = "Reusability";
    public titleDataSharing: string = "Data Sharing";

    public titleHumanITInfrastructure: string = "Human IT Infrastructure";
    public titleTechnologyManagement: string = "Technology management";
    public titleManagementSkills: string = "Management skills";
    public titleTechnicalSkills: string = "Technical skills";

    public titleOrganizationalCulture: string = "Organizational Culture";
    public titleLeadershipByCoaching: string = "Leadership by coaching";
    public titleFosteringACultureOfKnowledgeSharingAndLearning: string = "Fostering a culture of knowledge sharing and learning";
    public titleInteractionsAmongEmployees: string = "Interactions among employees";

    public titleHumanKnowledgeAndSkills: string = "Human Knowledge and Skills";
    public titleKnowledgeskillsVarietyAndRedeployability: string = "Knowledge (tacit), skills variety and redeployability";
    public titleSpeedOfAcquiringAndDevelopingNewSkills: string = "Speed of acquiring and developing new skills, or dynamic specialization";

    public titleSRLCapabilities: string = "SRL Capabilities";
    public titleSensingCapabilitiesMeasurement: string = "Sensing Capabilities Measurement";
    public titleRespondingCapabilitiesMeasurement: string = "Responding Capabilities Measurement";
    public titleLearningCapabilitiesMeasurement: string = "Learning Capabilities Measurement";

    public q1: string = "Your job title is";
    public q2: string = "Which company are you working for?";
    public q3: string = "At what level";
    public q4: string = "Your years experience in this job";
    public q5: string = "Your years with this organization";
    public q6: string = "Your years experience in this field";
    public q7: string = "Number of employees in your company";
    public q8: string = "Company gross revenue last fiscal year (in millions)";
    public q9: string = "To whom does the top information services executive report";

    public q10: string = "The survival of the company is currently threatened by scarce supply of labor.";
    public q11: string = "The survival of the company is currently threatened by scarce supply of materials.";
    public q12: string = "The survival of the company is currently threatened by tough price competition.";
    public q13: string = "The survival of the company is currently threatened by tough competition inproduct and/or service quality.";
    public q14: string = "The survival of the company is currently threatened by tough competition in product and/or service differentiation.";
    public q15: string = "Products and services in the company’s industry become obsolete in a long period. (R)";
    public q16: string = "The product/services technologies in the company’s industry change quickly.";
    public q17: string = "The company can predict what its competitors are going to do next.";
    public q18: string = "The company can hardly predict when its products/services demand changes. (R)";
    public q19: string = "In the company’s industry, there is considerable uniformity in customer buying habits. (R)";
    public q20: string = "In the company’s industry, there is considerable diversity in nature of competition.";
    public q21: string = "In the company’s industry, there is considerable uniformity in product lines. (R)";

    public q22: string = "Partners are cooperative towards the company.";
    public q23: string = "The company places little trust in many of its partners. (R)";
    public q24: string = "Harmony exists among the companies in the network.";
    public q25: string = "The companies in the network behave in a hostile manner towards each other. (R)";
    public q26: string = "Benchmarking is a common activity within the company.";
    public q27: string = "The company lacks any information about the performance of the competitors. (R)";
    public q28: string = "The company is fully aware of its market position.";
    public q29: string = "In our company, the movements and strategies of the competitors are closely monitored.";
    public q30: string = "The company’s partners work closely with the company in product/service development.";
    public q31: string = "The company works closely with its partners to improve each other’s processes.";
    public q32: string = "The company has never worked together with a partner to develop products or services. (R)";
    public q33: string = "Cooperation with competitors is strongly discouraged. (R)";
    public q34: string = "Partners never share information with the company. (R)";
    public q35: string = "Information is easily and often shared within the network.";
    public q36: string = "The company doesn't understand the processes of its partners. (R)";
    public q37: string = "When changing partners/suppliers, the company needs to invest in new common systems. (R)";
    public q38: string = "The structure of the network doesn’t allow the company to change partners/suppliers easily. (R)";
    public q39: string = "Interfaces among the companies in the network are plug-and-play.";
    public q40: string = "Managers promote initiatives of change from employees.";
    public q41: string = "Employees can express their ideas about how their job should be performed.";
    public q42: string = "All decision power in the company lies with the management. (R)";
    public q43: string = "Communication is two-way rather than only downward.";
    public q44: string = "Employees of all levels contribute to decisions.";
    public q45: string = "Employees are rewarded when they propose ways to change.";
    public q46: string = "Risk takers who fail are still rewarded for their initiative.";
    public q47: string = "Payments or bonuses are solely based on performance";
    public q48: string = "The measurement of performance has been the same for several years. (R)";
    public q49: string = "Employee task performance now is compared with task performance in the past. (R)";
    public q50: string = "Skills are more important than task performance in evaluation.";
    public q51: string = "Teamwork rather than only individual performance is recognized and rewarded.";
    public q52: string = "The budget of the company is fixed, no adaptations can be made during the year. (R)";
    public q53: string = "It is common in this company that adaptations are made in the budget during the year.";
    public q54: string = "Only the higher managers are allowed to make changes in the budget. (R)";
    public q55: string = "All the complex processes in the company are highly standardized. (R)";
    public q56: string = "None of the simple and repetitive processes in the company is standardized. (R)";
    public q57: string = "Only the trivial and repetitive processes are standardized.";
    public q58: string = "The production of a product is divided in elementary and trivial steps.";
    public q59: string = "The production steps are short.";
    public q60: string = "The products go through few complicated steps to be produced. (R)";
    public q61: string = "The company provides only highly standardized products. (R)";
    public q62: string = "The company always analyses the customer’s needs before producing a new product.";
    public q63: string = "The company has no contact with its customers. (R)";
    public q64: string = "All remote, branch, and mobile offices are connected to the central office of the company.";
    public q65: string = "There are several identifiable communications bottlenecks within the company. (R)";
    public q66: string = "New locations or acquisitions are quickly assimilated into the IT infrastructure of the company.";
    public q67: string = "Flexible electronic links exist between our company and external entities (e.g., vendor, customers).";
    public q68: string = "Remote, branch, and mobile offices do not have to perform any additional steps or procedures to access data from the home or central office.";
    public q69: string = "Software applications are easily transported and used across multiple platforms.";
    public q70: string = "Information is shared seamlessly across the company, regardless of the location.";
    public q71: string = "The user interfaces of the company provide transparent access to all platforms and applications.";
    public q72: string = "Legacy systems within the company restrict the development of new applications. (R)";
    public q73: string = "New parts of IT infrastructure can be added without major repercussion on the entire system.";
    public q74: string = "Old parts of IT infrastructure can be removed without major repercussion on the entire system.";
    public q75: string = "Different parts of the IT infrastructure of the company are highly dependent on each other. (R)";
    public q76: string = "The applications used in the company are designed to be reusable.";
    public q77: string = "Reusable software modules are not widely used in new systems development. (R)";
    public q78: string = "Pieces of program codes are regularly applied for some purpose beyond what they were originally intended for.";
    public q79: string = "It is generally easier to redesign software components from scratch than to adapt old ones into a new framework. (R)";
    public q80: string = "A common view of our company’s customer is available to everyone in the organization.";
    public q81: string = "The company easily adapts to various vendors’ database management systems protocols and standards.";
    public q82: string = "Data captured in one part of the company are immediately available to everyone in the company.";
    public q83: string = "Data processing (e.g., batch job, key entry time, etc.) restricts normal business operations or functions. (R)";
    public q84: string = "The company’s IT personnel are able to interpret business problems and develop appropriate technical solutions.";
    public q85: string = "In the company, IT reduces the cost of general management activities (e.g., planning, accounting, finance).";
    public q86: string = "The company continuously innovates and enhances IT application.";
    public q87: string = "The IT infrastructure in the company would not be difficult and expensive for rivals to duplicate. (R)";
    public q88: string = "The company’s level of investment in IT is very high.";
    public q89: string = "The company’s IT personnel work well in cross-functional teams addressing business problems.";
    public q90: string = "The company’s IT personnel work closely with clients and customers.";
    public q91: string = "Problem resolution between IT and business units is identified as a specific job task in the company.";
    public q92: string = "The company’s IT personnel are not very self-directed and proactive. (R)";
    public q93: string = "The company’s IT personnel are skilled in multiple programming languages.";
    public q94: string = "The company’s IT personnel are skilled in multiple types of databases (e.g., network, relational, object-oriented).";
    public q95: string = "The company’s IT personnel are skilled in multiple mainframe computer operating systems.";
    public q96: string = "The company’s IT personnel are skilled in network management and maintenance.";
    public q97: string = "The company’s IT personnel are skilled in hardware diagnosis and maintenance.";
    public q98: string = "Employees are trusted and empowered rather than being policed and audited.";
    public q99: string = "Management coaches and inspires rather than directing the employees who work with it.";
    public q100: string = "Company leaders encourage employees' individual entrepreneurship";
    public q101: string = "The company is constantly reinventing and re-engineering the company.";
    public q102: string = "The company is effective at meeting changing goals and objectives.";
    public q103: string = "The company can decide on appropriate courses of action in the face of change";
    public q104: string = "Manufacturing processes and methods of creating products are part of the infrastructure and easily accessible to all teams in the company.";
    public q105: string = "Employees from different departments are often in contact with each other.";
    public q106: string = "There are reliable communication systems to contact colleagues.";
    public q107: string = "The members of the company barely interact with each other. (R)";
    public q108: string = "In the company, cooperation is used to solve problems.";
    public q109: string = "Project teams often consist of employees from different departments.";
    public q110: string = "Within the company, it is difficult to move workers between different tasks. (R)";
    public q111: string = "Within the company, it is easy to reconfigure physical and human resources to meet changed customer demands.";
    public q112: string = "The tasks that employees perform in a typical work day are very similar.";
    public q113: string = "Most of the members of the organization are trained for one very specific task only (R)";
    public q114: string = "Increasing the education of employees is thought of as enhancing the company.";
    public q115: string = "Within the company, the employees benefit from a continuous training.";
    public q116: string = "The employees are quickly trained when the need for future skills is foreseen.";
    public q117: string = "The company periodically reviews the likely effect of changes in its business environment (e.g. regulations) on customers.";
    public q118: string = "The company almost never develops scenarios about possible future developments and ways to react. (R)";
    public q119: string = "The company uses and combines internal data sources to sense changing patterns or developments.";
    public q120: string = "The company meets with its customers in order to find out what their needs will be in the future.";
    public q121: string = "The company collects industry information from its customers by informal means (e.g., over lunch, at trade conventions).";
    public q122: string = "The company does not register requests and complaints from customers. (R)";
    public q123: string = "The company systematically measures customer satisfaction.";
    public q124: string = "The company meets with its partners in order to find out what their needs will be in the future.";
    public q125: string = "The company seldom collects industry information from its partners by informal means (e.g., over lunch, at trade conventions). (R)";
    public q126: string = "The company involves its partners as a source of innovative ideas for new products and services.";
    public q127: string = "Relationships between events and actions are stored for future usage.";
    public q128: string = "The company periodically uses different frameworks to evaluate its data.";
    public q129: string = "Employees within the company can experiment with new products, services or processes.";
    public q130: string = "The company rarely reviews its product development efforts to ensure that they are in line with what customers want. (R)";
    public q131: string = "Customers have procedures and tools to diagnose events and generate possible responses.";
    public q132: string = "Partners have procedures and tools in place to diagnose events and generate possible responses.";
    public q133: string = "The activities of the different departments in this business unit are well coordinated.";
    public q134: string = "The business processes carried out in the company can be updated quickly, when the need arises.";
    public q135: string = "The company cannot quickly develop new capabilities. (R)";
    public q136: string = "The company can quickly establish inter-organizational relationships.";
    public q137: string = "Costs for switching from one partner to another are relatively high. (R)";
    public q138: string = "The company has continuous insight in the impact of a specific response on business performance.";
    public q139: string = "The company systematically measures performance of the operational process.";
    public q140: string = "The company does not retain information concerning its customers (address details, products and services). (R)";
    public q141: string = "The company retains information concerning past events and the actions (solutions) in relation to its process and/or customers.";
    public q142: string = "The company does not use guidelines for responding to non- standard exceptions and disruptions. (R)";
    public q143: string = "Employees at various levels in the organization have the tools to quickly communicate with each other.";
    public q144: string = "When one department obtains important information about the company’s customers, it is circulated to other departments.";
    public q145: string = "Representatives from different departments within the company meet regularly to discuss market trends and developments and customers' needs.";
    public q146: string = "The company has informal means (lunches, activities) for sharing customer or related information.";
    public q147: string = "Employees are facilitated to share (tacit) knowledge among each other.";
    public q148: string = "Employees feedback on the company's product, service or delivery process is embedded in the process.";
    public q149: string = "Customer feedback on the company's product, service or delivery process is not embedded in the process. (R)";
}

