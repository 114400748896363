import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "panel" }
const _hoisted_2 = {
  key: 2,
  class: "content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RespondentView = _resolveComponent("RespondentView")!
  const _component_ConsultantView = _resolveComponent("ConsultantView")!
  const _component_AdminMailSettingsView = _resolveComponent("AdminMailSettingsView")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_AdminMailTemplatesView = _resolveComponent("AdminMailTemplatesView")!
  const _component_AdminAlgorithmSettings = _resolveComponent("AdminAlgorithmSettings")!
  const _component_AdminReferenceDataView = _resolveComponent("AdminReferenceDataView")!
  const _component_AdminUserActivityLogView = _resolveComponent("AdminUserActivityLogView")!
  const _component_AdminAddConsultant = _resolveComponent("AdminAddConsultant")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.AuthService.currentRole.id == _ctx.RoleType.RESPONDENT)
      ? (_openBlock(), _createBlock(_component_RespondentView, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.AuthService.currentRole.id == _ctx.RoleType.CONSULTANT)
      ? (_openBlock(), _createBlock(_component_ConsultantView, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.AuthService.currentRole.id == _ctx.RoleType.ADMIN)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_TabView, null, {
            default: _withCtx(() => [
              _createVNode(_component_TabPanel, { header: "Mail Settings" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AdminMailSettingsView)
                ]),
                _: 1
              }),
              _createVNode(_component_TabPanel, { header: "Mail Templates" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AdminMailTemplatesView)
                ]),
                _: 1
              }),
              _createVNode(_component_TabPanel, { header: "Algorithm Settings" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AdminAlgorithmSettings)
                ]),
                _: 1
              }),
              _createVNode(_component_TabPanel, { header: "Reference Data" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AdminReferenceDataView)
                ]),
                _: 1
              }),
              _createVNode(_component_TabPanel, { header: "User Activity Log" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AdminUserActivityLogView)
                ]),
                _: 1
              }),
              _createVNode(_component_TabPanel, { header: "Add consultant" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AdminAddConsultant)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}