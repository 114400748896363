<template>
  <div class="panel">
    <DataTable v-model:selection="selectRow" :value="userActivityRows" stripedRows   selectionMode="single" scrollHeight="420px" tableStyle="min-width: 50rem">
      <Column field="id" header="ID"></Column>
      <Column field="email" header="User"></Column>
      <Column field="operation" header="Operation"></Column>
      <Column field="dateFormatted" header="Timestamp"></Column>
      <Column field="error" header="Success">
        <template #body="slotProps">
          <span v-if="slotProps.data.error" class="pi pi-exclamation-triangle" style="font-size: 24px; font-weight: normal; color: #ff0000;"></span>
          <span v-if="!slotProps.data.error" class="pi pi-check" style="font-size: 24px; font-weight: normal; color: #00ff00;"></span>
        </template>
      </Column>
    </DataTable>
    <div>
      <Paginator @page="nextRow($event)" :rows="maxRows" :totalRecords="totalRows" :first="offset" template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" />
    </div>
    <div v-if="selectRow.id != 0" class="details">
      <div class="label">Operation</div>
      <div class="field">
        <InputText type="text" v-model="selectRow.operation" readonly class="input"/>
      </div>
      <div class="label">Date/Time</div>
      <div class="field">
        <InputText type="text" v-model="selectRow.dateFormatted" readonly class="input"/>
      </div>
      <div class="label">User</div>
      <div class="field">
        <InputText type="text" v-model="selectRow.email" readonly class="input"/>
      </div>
      <div v-if="selectRow.activity !='null'"  class="label">API Details</div>
      <div v-if="selectRow.activity !='null'"  class="field">
        <Textarea v-model="selectRow.details" rows="5" cols="150" ></Textarea>
      </div>
      <div v-if="selectRow.activity !='null'"  class="label">Activity</div>
      <div v-if="selectRow.activity !='null'"  class="field">
        <Textarea v-model="selectRow.activity" rows="5" cols="150" ></Textarea>
      </div>
      <div v-if="!selectRow.error && selectRow.result !='null'" class="label">Result</div>
      <div v-if="!selectRow.error && selectRow.result !='null'" class="field">
        <Textarea v-model="selectRow.result" rows="5" cols="150" ></Textarea>
      </div>
      <div v-if="selectRow.error && selectRow.exception !='null'" class="label">Exception</div>
      <div v-if="selectRow.error && selectRow.exception !='null'" class="field">
        <Textarea v-model="selectRow.exception" rows="5" cols="150" ></Textarea>
      </div>
      <div class="label">Request Details</div>
      <div class="field">
        <Textarea v-model="selectRow.request" rows="8" cols="150" ></Textarea>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Vue} from 'vue-class-component';
import axios from "axios";
import {Application} from "@/data/Application";
import {MailTemplate} from "@/data/MailTemplate";
import {UserActivityLog} from "@/data/UserActivityLog";
export default class AdminUserActivityLogView extends Vue {

  private userActivityRows : UserActivityLog[] =[];
  private selectRow: UserActivityLog = new UserActivityLog();
  private maxRows: Number = 6;
  private offset: Number = 0;
  private totalRows: Number = 0;

  mounted() {
    this.nextRow({first: this.offset});
  }

  private nextRow(e: any) {
    this.offset = e.first;
    //{page: 1, first: 20, rows: 20, pageCount: 19}
    axios.post(Application.instance.apiEndPoint + '/admin/user/activity/log', {maxRows: this.maxRows, offset: this.offset})
        .then(response => {
          console.log(response.data);
          this.offset = response.data.offset;
          this.totalRows = response.data.totalRows;
          this.userActivityRows = [];
          for(let row of response.data.logEntities) {
            this.userActivityRows.push(
                UserActivityLog.create(
                    row.id,
                    row.user,
                    row.email,
                    JSON.stringify(JSON.parse(row.details), undefined, 4),
                    row.operation,
                    JSON.stringify(JSON.parse(row.activity), undefined, 4),
                    JSON.stringify(JSON.parse(row.result), undefined, 4),
                    JSON.stringify(JSON.parse(row.exception), undefined, 4),
                    JSON.stringify(JSON.parse(row.request), undefined, 4),
                    new Date(row.timestamp)));
          }
        })
        .catch(err => {
          console.log(err);
        });
  }
}
</script>
<style scoped>
.panel {
  text-align: left;
  padding-left: 70px;
}
.details {
  padding-top:50px;
}
.label {
  font-family: "Public Sans";
  font-weight: bold;
}
.field {
  font-family: "Public Sans";
  font-weight: normal;
}
.input {
  font-family: "Public Sans";
  width: 750px;
}
</style>
