<template>
  <div class="device-not-available">
    <div class="message-container">
      <i class="pi pi-exclamation-circle warning-icon" style="color:#00AAB4;"></i>
      <h1>Due to the complex variety of data and graphs, this screen is designed to be viewed fullscreen on Desktop.</h1>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent, onMounted, onUnmounted } from 'vue';
import Button from 'primevue/button';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'DeviceNotAvailable',
  components: {
    Button
  },
  setup() {
    const router = useRouter();

    const checkScreenWidth = () => {
      if (window.innerWidth >= 1200) {
        router.push('/');
      }
    };

    onMounted(() => {
      window.addEventListener('resize', checkScreenWidth);
      // Check initially in case the component mounts when width is already > 1200px
      checkScreenWidth();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', checkScreenWidth);
    });

    const returnToDashboard = () => {
      router.push('/');
    };

    return {
      returnToDashboard
    };
  }
});
</script>


<style scoped>
.device-not-available {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.message-container {
  text-align: center;
  max-width: 600px;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.warning-icon {
  font-size: 4rem;
  color: #03A9F4;
  margin-bottom: 1rem;
}

h1 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 2rem;
}

.p-button-primary {
  background-color: #03A9F4;
  border-color: #03A9F4;
}

.p-button-primary:hover {
  background-color: #0288D1;
  border-color: #0288D1;
}
</style>