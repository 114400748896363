export class QuestionnaireState {

    public static CREATED: number = 1;
    public static INVITED: number = 2;
    public static FAILED_TO_SENT_INVITATION: number = 3;
    public static INVITATION_ACCEPTED: number = 4;
    public static REGISTERED: number = 5;
    public static QUESTIONNAIRE_STARTED: number = 6;
    public static QUESTIONNAIRE_SUBMITTED: number = 7;
    public static ACTIVE: number = 8;
    public static DRAFT: number = 9;
    public static EXPIRED: number = 10;
    public static CLOSED: number = 11;
    public static UNKNOWN: number = 12;


    public static stateAsString = (state: number):String => {
        let result = "";
        switch (state) {
            case 1:
                return "CREATED";
            case 2:
                return "INVITED";
            case 3:
                return "FAILED TO SENT INVITATION";
            case 4:
                return "INVITATION ACCEPTED";
            case 5:
                return "REGISTERED";
            case 6:
                return "QUESTIONNAIRE STARTED";
            case 7:
                return "QUESTIONNAIRE SUBMITTED";
            case 8:
                return "ACTIVE";
            case 9:
                return "DRAFT";
            case 10:
                return "EXPIRED";
            case 11:
                return "CLOSED";
            default:
                return "n/a";
        }
    }
}
