<template>
  <div class="panel">
    <RespondentView v-if="AuthService.currentRole.id == RoleType.RESPONDENT"></RespondentView>
    <ConsultantView v-if="AuthService.currentRole.id == RoleType.CONSULTANT"></ConsultantView>

    <div v-if="AuthService.currentRole.id == RoleType.ADMIN" class="content">

      <TabView>
        <TabPanel header="Mail Settings">
          <AdminMailSettingsView></AdminMailSettingsView>
        </TabPanel>
        <TabPanel header="Mail Templates">
          <AdminMailTemplatesView></AdminMailTemplatesView>
        </TabPanel>
        <TabPanel header="Algorithm Settings">
          <AdminAlgorithmSettings></AdminAlgorithmSettings>
        </TabPanel>
        <TabPanel header="Reference Data">
          <AdminReferenceDataView></AdminReferenceDataView>
        </TabPanel>
        <TabPanel header="User Activity Log">
          <AdminUserActivityLogView></AdminUserActivityLogView>
        </TabPanel>
        <TabPanel header="Add consultant">
          <AdminAddConsultant></AdminAddConsultant>
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
// @ is an alias to /src
import router from "@/router";
import {Inject} from "vue-property-decorator";
import {User} from "@/data/User";
import {Application} from "@/data/Application";
import {QuestionnaireState} from "@/data/QuestionnaireState";
import {AuthService} from "../service/AuthService";
import {RoleType} from "@/data/RoleType";
import RespondentView from "@/views/RespondentView.vue";
import ConsultantView from "@/views/ConsultantView.vue";
import {Navigation} from "@/data/Navigation";
import AdminMailSettingsView from "@/views/AdminMailSettingsView.vue";
import AdminMailTemplatesView from "@/views/AdminMailTemplatesView.vue";
import AdminAlgorithmSettings from "@/views/AdminAlgorithmSettingsView.vue";
import AdminReferenceDataView from "@/views/AdminReferenceDataView.vue";
import AdminUserActivityLogView from "@/views/AdminUserActivityLogView.vue";
import AdminAddConsultant from "@/views/AdminAddConsultant.vue";

@Options({
  components: {
    AdminUserActivityLogView,
    AdminReferenceDataView,
    AdminAlgorithmSettings, AdminMailTemplatesView, AdminMailSettingsView, ConsultantView, RespondentView,AdminAddConsultant},
  computed: {
    RoleType() {
      return RoleType
    },
    AuthService() {
      return AuthService
    },
    QuestionnaireState() {
      return QuestionnaireState
    },
    Application() {
      return Application
    },
    User() {
      return User
    }
  }
})
export default class HomeView extends Vue {

  mounted() {
    AuthService.validate();
    Application.instance.setActiveView(Navigation.HOME_VIEW);
    setTimeout(() => {
      this.$forceUpdate()
    }, 10);
    // this.$i18n.locale = User.get().profile.language;

    this.$i18n.locale = User.get().profile.language;
  }

  private startQuestionnaire = () => {
    User.get().invitation.startQuestionnaire();
    router.push('/questionnaire')
  }
  private proceedQuestionnaire = () => {
    router.push('/questionnaire')
  }
}
</script>
<style>
.panel {
  position: relative;
}

.content {
  position: static;
  top: 0;
  left: 0px;
  bottom: 0;
  right: 0;
}

.welcome-title {
  font-size: 50px;
  font-weight: bold;
  text-align: left;
}

.intro-block {
  position: absolute;
  top: 200px;
  left: 10px;
  right: 104px;
  border-radius: 6px;
  height: 400px;
  border-left-style: solid;
  border-left-width: 8px;
  border-left-color: #0D3D52;
  box-shadow: 5px 10px 8px #EEEEEE;
}

.intro-block-content {
  font-size: 16px; font-weight: normal; text-align: left;padding-top: 20px; padding-left: 50px;
}
.block-size {
  width: 530px;
}

.app-title {
  font-size: 32px;
  font-weight: bold;
  text-align: left;
  padding-top: 20px;
  padding-left: 50px;
}
.button-block {
  position: absolute; bottom: 20px; right: 50px;
}
.questionnaire-state-block {
  position: absolute; top:20px; left:500px; width: 600px; height:140px; border-radius: 6px; box-shadow: 5px 10px 8px #EEEEEE;
}
.status-title {
  position: absolute; top: 30px; font-size: 16px; font-weight: bold;
}
.progress {
  left: 100px;
}
.deadline {
  left: 250px;
}
.my-response {
  left: 360px;
}
.status-value {
  position: absolute; top: 70px; font-size: 16px; font-weight: bold;
}
.p-tabview {
  width: 90% !important;
}
.p-tabview-nav {
  justify-content: left !important;
}
</style>
